<template>
	<div class="appWhite d-flex flex-column rounded-lg mt-4 pa-4">

		<!--Event-->
		<app-text color="primary" size="normal-bold">{{ cardData.eventData?.eventName }}</app-text>

		<v-divider class="my-2"/>

		<!--Organisation-->
		<div class="d-flex align-center">
			<app-text size="small-bold">{{ cardData.organisationData?.organisationName }}</app-text>
		</div>

		<!--Car Park | Total Spaces-->
		<div class="d-flex align-center mt-2">

			<!--Car Park-->
			<app-text v-for="item in cardData?.parkingData" :key="item.parkingSpaceData?.entityId" size="small">
				{{ item.carParkData?.carParkName }}
				<span class="greyD--text mr-1">|</span>
			</app-text>

			<!--Total Spaces-->

			<div class="d-flex align-center justify-center">

				<!--Line Totals-->
				<div v-for="item in cardData?.parkingData" :key="item.parkingSpaceData?.entityId"
					 class="d-flex align-center">
					<app-text size="small">
						{{ getNumberOfSpacesRequired(item.parkingSpaceData).regularSpaces }}
					</app-text>
					<v-divider class="greyD mx-2" vertical/>
					<app-text class="d-flex align-center" size="small">
						<app-icon color="blue" icon="disabled" size="16"/>
						{{ getNumberOfSpacesRequired(item.parkingSpaceData).disabledSpaces }}
					</app-text>
					<v-divider class="greyD mx-2" vertical/>
				</div>

				<!--Sum of Totals-->
				<app-text size="small-bold">
					{{ getTotalNumberOfSpacesRequired(cardData.parkingData) }}
				</app-text>

			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "EventParkingMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {

		/**
		 * Get Number Of Spaces Required
		 *
		 * Calculate the number of spaces required for the given item (regular and disabled).
		 *
		 * @param item - the item to calculate the number of spaces required for
		 * @returns {{disabledSpaces: (*|number), totalSpaces: (*|number), regularSpaces: (*|number)}}
		 */
		getNumberOfSpacesRequired(item) {
			let regularSpaces = 0
			let disabledSpaces = 0
			let totalSpaces = 0

			regularSpaces = item.parkingSpaceNumberOfRegularSmallVehiclesRequired + item.parkingSpaceNumberOfRegularMediumVehiclesRequired + item.parkingSpaceNumberOfRegularLargeVehiclesRequired
			disabledSpaces = item.parkingSpaceNumberOfDisabledSmallVehiclesRequired + item.parkingSpaceNumberOfDisabledMediumVehiclesRequired + item.parkingSpaceNumberOfDisabledLargeVehiclesRequired
			totalSpaces = regularSpaces + disabledSpaces

			return {regularSpaces, disabledSpaces, totalSpaces}
		},

		/**
		 * Get Total Number Of Spaces Required
		 *
		 * Calculate the total number of spaces required for the given spaces data (regular and disabled).
		 *
		 * @param spacesData - the spaces data to calculate the number of spaces required for
		 * @returns {number} - the total number of spaces required
		 */
		getTotalNumberOfSpacesRequired(spacesData) {
			let regularSpaces = 0
			let disabledSpaces = 0

			spacesData.forEach(item => {
				const parkingSpaceData = item.parkingSpaceData
				regularSpaces += parkingSpaceData.parkingSpaceNumberOfRegularSmallVehiclesRequired + parkingSpaceData.parkingSpaceNumberOfRegularMediumVehiclesRequired + parkingSpaceData.parkingSpaceNumberOfRegularLargeVehiclesRequired
				disabledSpaces += parkingSpaceData.parkingSpaceNumberOfDisabledSmallVehiclesRequired + parkingSpaceData.parkingSpaceNumberOfDisabledMediumVehiclesRequired + parkingSpaceData.parkingSpaceNumberOfDisabledLargeVehiclesRequired
			})

			return regularSpaces + disabledSpaces
		},

	},

}
</script>

<style scoped>

</style>
