<template>
	<div>

		<!--Header-->
		<page-title icon="accreditation"
					info="Manage your Accreditation Users."
					title="My Accreditation"/>

		<v-divider class="mt-2"/>

		<!--Getting Started Info-->
		<div v-if="!fullEventData?.accreditationEventData?.entityId" class="d-flex align-center justify-center"
			 :style="$vuetify.breakpoint.width < 600 ? 'height: 50vh' : 'height: 70vh'">
			<page-start-information title="Getting Started" :points="['Configure this event', 'Set it to Live']"/>
		</div>

		<!--Passes Figures-->
		<div v-if="$vuetify.breakpoint.width >= 600" class="mt-4" style="display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: 16px">

			<!--Requested-->
			<div class="d-flex flex-column align-center cursorPointer black rounded-lg pa-4">
				<app-text color="white" size="large-bold">{{ numberOfPassesRequested }}</app-text>
				<app-text color="white" size="small">Requested</app-text>
			</div>

			<!--Approved-->
			<div class="d-flex flex-column align-center cursorPointer green rounded-lg pa-4">
				<app-text color="white" size="large-bold">{{ numberOfPassesApproved }}</app-text>
				<app-text color="white" size="small">Approved</app-text>
			</div>

			<!--Pending-->
			<div class="d-flex flex-column align-center cursorPointer orange rounded-lg pa-4">
				<app-text color="white" size="large-bold">{{ numberOfPassesPending }}</app-text>
				<app-text color="white" size="small">Pending</app-text>
			</div>

			<!--Rejected-->
			<div class="d-flex flex-column align-center cursorPointer red rounded-lg pa-4">
				<app-text color="white" size="large-bold">{{ numberOfPassesRejected }}</app-text>
				<app-text color="white" size="small">Rejected</app-text>
			</div>

			<!--Reserved-->
			<div class="d-flex flex-column align-center cursorPointer blue rounded-lg pa-4">
				<app-text color="white" size="large-bold">{{ numberOfPassesReserved }}</app-text>
				<app-text color="white" size="small">Reserved</app-text>
			</div>

		</div>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="User Name"
							style="width: 100%"
							v-model.trim="searchByUserName"/>

			<!--Add Button-->
			<!--<app-btn v-if="MIX_getCurrentUser().userLevel === 'Staff-Admin'"-->
			<!--		 @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>-->

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600 && fullEventData?.accreditationEventData?.entityId"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.accreditationPassStatus="{item}">
				<app-icon v-if="item.passData.accreditationPassStatus === 'Approved'"
						  color="green" icon="success" size="32" :title="item.passData.accreditationPassStatus"/>
				<app-icon v-if="item.passData.accreditationPassStatus === 'Pending'"
						  color="orange" icon="pending" size="32" :title="item.passData.accreditationPassStatus"/>
				<app-icon v-if="item.passData.accreditationPassStatus === 'Rejected'"
						  color="red" icon="cancel" size="32" :title="item.passData.accreditationPassStatus"/>
				<app-icon v-if="item.passData.accreditationPassStatus === 'Reserved'"
						  color="blue" icon="reserved" size="32" :title="item.passData.accreditationPassStatus"/>
			</template>

			<!--UserName-->
			<template v-slot:item.userName="{item}">
				<app-text size="small">{{ item.userData.userName }}</app-text>
			</template>

			<!--UserPosition-->
			<template v-slot:item.userPosition="{item}">
				<app-text size="small">{{ item.userData.userPosition }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="handleUserSelection(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Accreditation Users to view
			</app-text>

			<!--Cards-->
			<my-accreditation-users-mobile-card v-for="(item, index) in computedTableData" :key="index"
												@click.native="handleUserSelection(item)"
												class="mt-4"
												:card-data="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Statuses-->
				<page-break-title title="Statuses"/>
				<div class="mt-4"
					 style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
					<app-btn @click.native="handleFilterStatusSelection('Pending')"
							 :block="true"
							 color="orange"
							 label="Pending"
							 :outlined="!filterByStatuses.includes('Pending')"/>
					<app-btn @click.native="handleFilterStatusSelection('Approved')"
							 :block="true"
							 color="green"
							 label="Approved"
							 :outlined="!filterByStatuses.includes('Approved')"/>
					<app-btn @click.native="handleFilterStatusSelection('Rejected')"
							 :block="true"
							 color="red"
							 label="Rejected"
							 :outlined="!filterByStatuses.includes('Rejected')"/>
					<app-btn @click.native="handleFilterStatusSelection('Reserved')"
							 :block="true"
							 color="blue"
							 label="Reserved"
							 :outlined="!filterByStatuses.includes('Reserved')"/>
					<app-btn @click.native="handleFilterStatusSelection('Not Applied')"
							 :block="true"
							 color="grey"
							 label="Not Applied"
							 :outlined="!filterByStatuses.includes('Not Applied')"/>
				</div>

				<!--Is Printed-->
				<page-break-title class="mt-8" title="Print Status"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="['Printed', 'Not Printed']"
									label="Print Status"
									:multiple="true"
									:small-chips="true"
									v-model="filterByPrintStatus"/>
				</div>

				<!--Position-->
				<page-break-title class="mt-8" title="Position"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Position"
									v-model.trim="filterByPosition"/>
				</div>

			</div>
		</filter-panel>

	</div>
</template>

<script>

import MyAccreditationUsersMobileCard
	from "@/views/accreditation/organisation/myAccreditationUsers/myAccreditationUsersMobileCard/MyAccreditationUsersMobileCard.vue";

export default {

	name: "MyAccreditationUsers",

	components: {MyAccreditationUsersMobileCard},

	props: ['fullEventData'],

	data: () => ({
		isFiltersPanelVisible: false,
		filterByPosition: '',
		filterByPrintStatus: [],
		filterByStatuses: [],
		isLoading: false,
		isReadOnly: false,
		moreActionsMenuOption: [
			{name: 'Manage Passes', icon: 'pass'},
		],
		numberOfPassesApproved: 0,
		numberOfPassesPending: 0,
		numberOfPassesRejected: 0,
		numberOfPassesRequested: 0,
		numberOfPassesReserved: 0,
		searchByUserName: '',
		searchByOrganisation: '',
		searchByPosition: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'left', sortable: false, hidden: true},
			{text: 'Status', value: 'accreditationPassStatus', align: 'center', sortable: false, width: '96px'},
			{text: 'Name', value: 'userName', align: 'start', sortable: false},
			{text: 'Position', value: 'userPosition', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'right', sortable: false, width: '48px'},
		],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByPosition ? 1 : 0,
				t.filterByPrintStatus.length,
				t.filterByStatuses.length
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.$props.fullEventData?.passesData

			// Reset the figures
			t.numberOfPassesRequested = 0
			t.numberOfPassesPending = 0
			t.numberOfPassesApproved = 0
			t.numberOfPassesRejected = 0
			t.numberOfPassesReserved = 0

			// Search by UserName
			if (t.searchByUserName) {
				const SEARCH_CRITERIA = t.searchByUserName.toUpperCase()
				tableData = tableData.filter(item => {
					const USER_NAME = item.userData.userName.toUpperCase()
					return USER_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Status
			if (t.filterByStatuses.length) tableData = tableData.filter(item => t.filterByStatuses.includes(item.passData?.accreditationPassStatus))

			// Filter by Print Status
			if (t.filterByPrintStatus.length) tableData = tableData.filter(item => t.filterByPrintStatus.includes(item.passData?.accreditationPassPrinted ? 'Printed' : 'Not Printed'))

			// Filter by Position
			if (t.filterByPosition) {
				const SEARCH_BY_POSITION = t.filterByPosition?.toUpperCase()
				tableData = tableData.filter(item => {
					const USER_POSITION = item.userData?.userPosition?.toUpperCase() || ''
					return USER_POSITION.includes(SEARCH_BY_POSITION)
				})
			}

			// Increment the Status figures
			tableData.forEach(item => {
				if (['Pending', 'Approved', 'Rejected', 'Reserved'].includes(item.passData?.accreditationPassStatus)) t.numberOfPassesRequested++
				if (item.passData?.accreditationPassStatus === 'Pending') t.numberOfPassesPending++
				if (item.passData?.accreditationPassStatus === 'Approved') t.numberOfPassesApproved++
				if (item.passData?.accreditationPassStatus === 'Rejected') t.numberOfPassesRejected++
				if (item.passData?.accreditationPassStatus === 'Reserved') t.numberOfPassesReserved++
			})

			// Sort by UserName
			tableData = tableData.slice().sort((a, b) => a.userData?.userName.localeCompare(b.userData?.userName));

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByPosition = ''
			t.filterByPrintStatus = []
			t.filterByStatuses = []
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			// if (selection === 'Event Settings') t.$emit('openEventSettings', t.$props.fullEventData)
			if (selection === 'Manage Passes') t.handleUserSelection({})
		},

		/**
		 * Handle Filter Status Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */
		handleFilterStatusSelection(status) {
			const t = this

			if (t.filterByStatuses.includes(status)) t.filterByStatuses = t.filterByStatuses.filter(s => s !== status)
			else t.filterByStatuses.push(status)
		},

		handleUserSelection(item) {
			const t = this

			t.selectedItem = item

			t.$emit('selectedUser', item)
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

}
</script>

<style scoped>

</style>
