<template>
	<div class="appGrey rounded-lg pa-4">

		<!--Icon | Title-->
		<div class="d-flex align-center">

			<!--Icon-->
			<app-icon class="mr-4" color="grey9" icon="error" size="32"/>

			<!--Title - User Only-->
			<app-text color="primary" size="normal-bold">Need to Report an Issue?</app-text>

		</div>

		<v-divider class="greyD my-4"/>

		<!--Instructional Text - User Only-->
		<app-text>
			If you need to report an issue with this Task, please select a reason from the list below.
		</app-text>

		<!--Issue Input-->
		<app-form-field form-type="autoComplete"
						class="mt-4"
						:error="errors.swappTasksTaskSupervisorIssue"
						:error-message="errors.swappTasksTaskSupervisorIssueErrorMessage"
						:items="swappTasksSupervisorIssuesOptions"
						label="Select an Issue"
						v-model="form.swappTasksTaskSupervisorIssue"/>

		<v-divider class="greyD my-4"/>

		<!--Action Buttons - User Only-->
		<div class="d-flex justify-space-between">

			<!--Close-->
			<app-btn @click.native="closeDialog"
					 :block="true" class="pr-2" color="grey" label="Close" style="width: 100%;"/>

			<!--Save-->
			<app-btn @click.native="handleSaveButton"
					 :block="true" class="pl-2" color="green" label="Save" style="width: 100%;"/>

		</div>

	</div>
</template>

<script>

export default {

	name: "SwappTasksSupervisorIssuesDialog",

	props: ['taskData'],

	data: () => ({
		errors: {
			swappTasksTaskSupervisorIssue: false,
			swappTasksTaskSupervisorIssueErrorMessage: '',
		},
		form: {
			swappTasksTaskSupervisorIssue: '',
		},
	}),

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		closeDialog() {
			this.$emit('closeDialog')
		},

		handleSaveButton() {
			const t = this

			// Validate the form before proceeding
			if (!t.validateForm()) return

			t.form.swappTasksTaskSupervisorIssueDateTime = new Date().getTime()

			t.updateItem()
		},

		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('swappTasksTask', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Swapp Tasks Task: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this Task, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Issue Saved'
			t.closeDialog()
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Issue
			if (!t.form.swappTasksTaskSupervisorIssue) {
				t.errors.swappTasksTaskSupervisorIssue = true
				t.errors.swappTasksTaskSupervisorIssueErrorMessage = 'Please select an issue'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	mounted() {
		const t = this

		// Set the form data
		t.form = t.$props.taskData
	},

}
</script>

<style scoped>

</style>
