<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Tabs-->
		<v-tabs class="rounded-lg" v-model="tabs">

			<v-tab href="#organisations">
				<app-text size="small">Organisations</app-text>
			</v-tab>

		</v-tabs>

		<!--Tabs Content-->
		<v-tabs-items v-model="tabs">

			<v-tab-item class="appGrey pt-4" value="organisations">


				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  class="rounded-lg mt-4"
							  :headers="computedHeaders"
							  :items="computedOrganisationsData"
							  style="background-color: #f8f8f8">

					<!--ID-->
					<template v-slot:item.entityId="{item}">
						<app-text size="small">{{ item.entityId }}</app-text>
					</template>

					<!--Organisation Name-->
					<template v-slot:item.organisationName="{item}">
						<app-text class="my-2">{{ item.organisationName }}</app-text>
					</template>

					<!--Spaces-->
					<template v-slot:item.spaces="{item}">
						<app-form-field form-type="textInput"
										:clearable="false"
										style="width: 64px"
										type="number"
										v-model.number="form.parkingConfigOrgNumberOfAllocatedSpaces"/>
					</template>

					<!--Car Park-->
					<template v-slot:item.carPark="{item}">
						<app-form-field form-type="autoComplete"
										class="my-4"
										:clearable="false"
										:items="carParksData"
										item-text="carParkName"
										v-model.number="form.parkingConfigOrgCarParkId"/>
					</template>

					<!--Actions-->
					<template v-slot:item.action="{item}">
						<app-icon @click.native="openRightPanel(item)"
								  class="cursorPointer" color="primary" icon="arrowForward"/>
					</template>

				</v-data-table>

			</v-tab-item>

		</v-tabs-items>

	</div>
</template>

<script>

export default {

	name: "ParkingConfiguration",

	data: () => ({
		form: {
			parkingConfigOrgCarParkId: '',
			parkingConfigOrgNumberOfAllocatedSpaces: 0,
		},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'left', sortable: false, hidden: true},
			{text: 'Organisation Name', value: 'organisationName', align: 'left', sortable: false, hidden: false},
			{text: 'Allocated', value: 'spaces', align: 'left', sortable: false, hidden: false, width: 96},
			{text: 'Car Park', value: 'carPark', align: 'left', sortable: false, hidden: false, width: 384},
			{text: '', value: 'action', align: 'right', sortable: false, width: '48px'},
		],
		isLoading: true,
		tabs: '',

		// Data
		carParksData: [],
		organisationsData: [],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hidden)

			return headers
		},

		computedOrganisationsData() {
			const t = this
			let listData = t.organisationsData

			// Sort the data by name
			listData = listData.sort((a, b) => a.organisationName > b.organisationName ? 1 : -1)

			return listData
		}
	},

	methods: {

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadCarParksData(),
				t.loadOrganisationsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Car Parks Data
		 *
		 * Load all the Car Parks data.
		 *
		 * @returns {Promise<void>}
		 */
		async loadCarParksData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('carPark')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Car Parks data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
				return
			}

			// Assign the data
			t.carParksData = RESPONSE.data
		},

		/**
		 * Load Organisations Data
		 *
		 * Load all the Organisations data.
		 *
		 * @returns {Promise<void>}
		 */
		async loadOrganisationsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('organisation')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Organisations data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
				return
			}

			// Assign the data
			t.organisationsData = RESPONSE.data
		},

		openRightPanel() {
		},

	},

	mounted() {
		console.log('mounted')
		const t = this

		t.loadData()
	},

	beforeUpdate() {
		console.log('beforeUpdate')
	},

	updated() {
		console.log('updated')
	},

	beforeDestroy() {
		console.log('beforeDestroy')
	},

}
</script>

<style scoped>
.tableRow:hover {
	background-color: #EEEEEE;
}
</style>
