<template>
	<div style="width: 100%">

		<!--Title-->
		<app-text :color="titleColor || 'grey9'" size="medium-bold">{{ title }}</app-text>

		<!--Description-->
		<app-text color="grey9" size="small">{{ description }}</app-text>

		<v-divider class="mt-2 mb-4"/>

	</div>
</template>

<script>
export default {

	name: "FormSectionTitle",

	props: ['description', 'title', 'titleColor'],

}
</script>

<style scoped>

</style>
