<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--Event Image-->
		<div style="width: 100%">

			<!--Event Image-->
			<div class="appWhite d-flex justify-center rounded-lg mr-4 pa-2"
				 style="height: 272px; width: 100%">

				<!--Temporary Image-->
				<v-img v-if="tempProfilePicture"
					   :src="tempProfilePicture"
					   class="rounded-lg"
					   contain/>

				<!--Profile Picture-->
				<v-img v-if="!tempProfilePicture && form.eventImage"
					   :src="MIX_getImagePath('eventBackground', fullEventData.fileData?.fileName, fullEventData.fileData?.fileToken)"
					   class="rounded-lg"
					   contain/>

				<!--Default Image-->
				<app-icon v-if="!form.eventImage" icon="missingImage" size="256"/>

				<!--Image Upload button-->
				<image-upload v-if="!isReadOnly"
							  @emitImageUpload="emittedImageUpload"
							  folder="eventBackground"
							  :id-for-image="form.entityId"
							  image-for="eventBackground"
							  style="position: absolute; left: 24px"/>

			</div>
			<!--Image Error Message-->
			<app-text v-if="errors.eventImage" color="red" size="small">{{ errors.eventImageErrorMessage }}</app-text>

		</div>

		<!--Event-->
		<form-section-title class="mt-4" title="Event"/>
		<v-row no-gutters>

			<!--Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.eventName"
								:error-message="errors.eventNameErrorMessage"
								label="Event Name"
								v-model.trim="form.eventName"/>
			</v-col>

			<!--Date-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
				<date-picker @emitDate="handleDateSelection($event, 'eventDate')"
							 :clearable="false"
							 :date="form.eventDate"
							 :disabled="isReadOnly"
							 :error="errors.eventDate"
							 :error-message="errors.eventDateErrorMessage"
							 label="Date"
							 :max-date="[10, 'years', 'future']"/>
			</v-col>

			<!--Start Time-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 px-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
				<time-picker @emitTime="handleTimeSelection($event, 'eventStartTime')"
							 :disabled="isReadOnly"
							 :error="errors.eventStartTime"
							 :error-message="errors.eventStartTimeErrorMessage"
							 label="Start Time"
							 :time="form.eventStartTime"/>
			</v-col>

			<!--Finish Time-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
				<time-picker @emitTime="handleTimeSelection($event, 'eventFinishTime')"
							 :disabled="isReadOnly"
							 :error="errors.eventFinishTime"
							 :error-message="errors.eventFinishTimeErrorMessage"
							 label="Finish Time"
							 :time="form.eventFinishTime"/>
			</v-col>

			<!--Venue-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.eventVenue"
								:error-message="errors.eventVenueErrorMessage"
								:items="sitesData"
								item-text="siteName"
								label="Venue"
								v-model="form.eventVenue"/>
			</v-col>

			<!--Type-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:error="errors.eventType"
								:error-message="errors.eventTypeErrorMessage"
								:items="eventTypeOptions"
								label="Type"
								v-model="form.eventType"/>
			</v-col>

			<!--Group-->
			<!--<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"-->
			<!--	   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">-->
			<!--	<app-form-field form-type="select"-->
			<!--					:disabled="isReadOnly"-->
			<!--					:items="eventGroupOptions"-->
			<!--					label="Group"-->
			<!--					v-model="form.eventGroup"/>-->
			<!--</v-col>-->

			<!--Attendance-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.eventAttendance"
								:error-message="errors.eventAttendanceErrorMessage"
								label="Attendance"
								type="number"
								v-model.number.trim="form.eventAttendance"/>
			</v-col>

			<!--Status-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:items="!['Live', 'Archived'].includes(form.eventStatus) ? ['Draft', 'Pending'] : ['Live', 'Archived']"
								label="Status"
								v-model="form.eventStatus"/>
			</v-col>

		</v-row>

		<!--Stewarding-->
		<form-section-title class="mt-4" title="Stewarding"/>
		<v-row no-gutters>

			<!--Steward Start Time-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'pr-2' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 4 : 4">
				<time-picker @emitTime="handleTimeSelection($event, 'eventStewardsStartTime')"
							 :disabled="isReadOnly"
							 :error="errors.eventStewardsStartTime"
							 :error-message="errors.eventStewardsStartTimeErrorMessage"
							 label="Steward Start Time"
							 :time="form.eventStewardsStartTime"/>
			</v-col>

			<!--Steward Finish Time-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'px-2' : 'px-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 4 : 4">
				<time-picker @emitTime="handleTimeSelection($event, 'eventStewardsFinishTime')"
							 :disabled="isReadOnly"
							 :error="errors.eventStewardsFinishTime"
							 :error-message="errors.eventStewardsFinishTimeErrorMessage"
							 label="Steward Finish Time"
							 :time="form.eventStewardsFinishTime"/>
			</v-col>

			<!--Steward Shift Length-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'pl-2' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 4 : 4">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Steward Shift Length"
								v-model="computedStewardShiftLength"/>
			</v-col>

			<!--Supervisor/Deputy Start Time-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 4 : 4">
				<time-picker @emitTime="handleTimeSelection($event, 'eventSupervisorsStartTime')"
							 :disabled="isReadOnly"
							 :error="errors.eventSupervisorsStartTime"
							 :error-message="errors.eventSupervisorsStartTimeErrorMessage"
							 label="Supervisor/Deputy Start Time"
							 :time="form.eventSupervisorsStartTime"/>
			</v-col>

			<!--Supervisor/Deputy Finish Time-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4 px-2' : 'mt-4 px-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 4 : 4">
				<time-picker @emitTime="handleTimeSelection($event, 'eventSupervisorsFinishTime')"
							 :disabled="isReadOnly"
							 :error="errors.eventSupervisorsFinishTime"
							 :error-message="errors.eventSupervisorsFinishTimeErrorMessage"
							 label="Supervisor/Deputy Finish Time"
							 :time="form.eventSupervisorsFinishTime"/>
			</v-col>

			<!--Supervisor/Deputy Shift Length-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 4 : 4">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Supervisor/Deputy Shift Length"
								v-model="computedSupervisorShiftLength"/>
			</v-col>

			<!--Steward Instruction-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.eventStaffInstructions"
								:error-message="errors.eventStaffInstructionsErrorMessage"
								label="Steward Instructions"
								v-model.trim="form.eventStaffInstructions"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
				<app-btn v-if="!isReadOnly"
						 @click.native="saveItem"
						 color="green"
						 icon="save"
						 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "EventForm",

	props: ['formData', 'fullEventData', 'isReadOnly', 'sitesData'],

	data: () => ({
		errors: {
			eventAttendance: false,
			eventAttendanceErrorMessage: '',
			eventName: false,
			eventNameErrorMessage: '',
			eventDate: false,
			eventDateErrorMessage: '',
			eventStartTime: false,
			eventStartTimeErrorMessage: '',
			eventFinishTime: false,
			eventFinishTimeErrorMessage: '',
			eventVenue: false,
			eventVenueErrorMessage: '',
			eventType: false,
			eventTypeErrorMessage: '',
			eventImage: false,
			eventImageErrorMessage: '',
			eventStewardsStartTime: false,
			eventStewardsStartTimeErrorMessage: '',
			eventStewardsFinishTime: false,
			eventStewardsFinishTimeErrorMessage: '',
			eventSupervisorsStartTime: false,
			eventSupervisorsStartTimeErrorMessage: '',
			eventSupervisorsFinishTime: false,
			eventSupervisorsFinishTimeErrorMessage: '',
			eventStaffInstructions: false,
			eventStaffInstructionsErrorMessage: '',
		},
		form: {
			entityId: '',
			eventAttendance: null,
			eventDate: null,
			eventFinishTime: '',
			eventGroup: '',
			eventImage: '',
			eventIsPublished: false,
			eventName: '',
			eventStaffInstructions: '',
			eventStartTime: '',
			eventStatus: 'Draft',
			eventStewardsFinishTime: '',
			eventStewardsShiftLength: 0,
			eventStewardsStartTime: '',
			eventSupervisorsFinishTime: '',
			eventSupervisorsShiftLength: 0,
			eventSupervisorsStartTime: '',
			eventType: '',
			eventVenue: '',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		},
		tempProfilePicture: '',
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			if (FORM_DATA?.entityId) t.form = FORM_DATA || {}
		},

		/**
		 * Computed Steward Shift Length
		 *
		 * Calculate the shift length from the start and finish times.
		 * Render the returned display time, and save the returned decimal time.
		 *
		 * @returns {string} the formatted display time
		 */
		computedStewardShiftLength() {
			const t = this
			let startTime = t.form.eventStewardsStartTime
			let finishTime = t.form.eventStewardsFinishTime

			// Get the calculated time
			const calculatedTime = t.MIX_formatTimeToHHMM(startTime, finishTime)

			// Save the decimal time to use later
			t.form.eventStewardsShiftLength = calculatedTime.decimalTime

			return isNaN(calculatedTime.decimalTime) ? 0 : calculatedTime.displayTime
		},

		/**
		 * Computed Supervisor Shift Length
		 *
		 * Calculate the shift length from the start and finish times.
		 * Render the returned display time, and save the returned decimal time.
		 *
		 * @returns {string} the formatted display time
		 */
		computedSupervisorShiftLength() {
			const t = this
			let startTime = t.form.eventSupervisorsStartTime
			let finishTime = t.form.eventSupervisorsFinishTime

			// Get the calculated time
			const calculatedTime = t.MIX_formatTimeToHHMM(startTime, finishTime)

			// Save the decimal time to use later
			t.form.eventSupervisorsShiftLength = calculatedTime.decimalTime

			return isNaN(calculatedTime.decimalTime) ? 0 : calculatedTime.displayTime
		}

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Emitted Image Upload
		 *
		 * Set the image to the form.
		 *
		 * @param payload {object} - the payload from the emitted event
		 */
		async emittedImageUpload(payload) {
			const t = this

			// Set a temporary profile picture, until the form is saved
			t.tempProfilePicture = payload.downloadURL

			const {FOLDER_NAME, IMAGE_NAME, TOKEN} = t.MIX_extractImageDataFromUrl(payload.downloadURL)

			// Save the image data to the DB (Files collection), and add the returned image ID to the form
			t.form.eventImage = await t.saveImageData(
				t.form.entityId,
				FOLDER_NAME,
				IMAGE_NAME,
				payload.image.size,
				TOKEN,
				payload.image.type
			)
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDateSelection(date, field) {
			const t = this

			// Set the date to the current form field
			t.form[field] = Number(date)
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 *
		 * @param time the time string from the picker
		 * @param field the form field to set
		 */
		handleTimeSelection(time, field) {
			const t = this

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Save Image Data
		 *
		 * Save the image data to the DB (Files collection).
		 *
		 * @param fileDocLink {string} - The file's document link
		 * @param fileFolder {string} - The storage folder
		 * @param fileName {string} - The file name
		 * @param fileSize {number} - The file size
		 * @param fileToken {string} - The file token
		 * @param fileType {string} - The file type
		 * @returns {Promise<*>} - The image ID
		 */
		async saveImageData(fileDocLink, fileFolder, fileName, fileSize, fileToken, fileType) {
			const t = this

			const IMAGE_DATA = {
				fileDocLink,
				fileFolder,
				fileName,
				fileSize,
				fileToken,
				fileType,
				createdDateTime: 0,
				createdUserId: '',
				createdUserName: '',
				modifiedDateTime: 0,
				modifiedUserId: '',
				modifiedUserName: '',
				isDeleted: false,
				deletedDateTime: 0,
				deletedUserId: '',
				deletedUserName: '',
			}

			const RESPONSE = await t.MIX_redis_create('file', IMAGE_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving File Data to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
				return
			}

			return RESPONSE.data?.entityId
		},

		/**
		 * Save Item
		 *
		 * Save/Update the form to the DB, after it has passed validation.
		 */
		async saveItem() {
			const t = this
			let response

			if (!t.validateForm()) return

			// Create
			if (!t.form.entityId) {

				response = await t.MIX_redis_create('event', t.form)

				// Handle any errors
				if (response.hasErrors) {
					console.error('Error creating Event: ', response.error)
					return
				}

				t.$sharedState.successMessage = 'Created Event'
			}

			// Update
			if (t.form.entityId) {

				response = await t.MIX_redis_update('event', t.form.entityId, t.form)

				// Handle any errors
				if (response.hasErrors) {
					console.error('Error updating Event: ', response.error)
					return
				}

				t.$sharedState.successMessage = 'Updated Event'
			}

			t.$emit('emitReloadData')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Attendance
			if (t.form.eventAttendance <= 0 || !Number.isInteger(t.form.eventAttendance)) {
				t.errors.eventAttendance = true
				t.errors.eventAttendanceErrorMessage = 'Must be a whole number more than 0'
			}

			// Event Image
			if (!t.form.eventImage) {
				t.errors.eventImage = true
				t.errors.eventImageErrorMessage = 'Image is required'
			}

			// Event Name
			if (!t.form.eventName) {
				t.errors.eventName = true
				t.errors.eventNameErrorMessage = 'Name is required'
			}

			// Date
			if (!t.form.eventDate) {
				t.errors.eventDate = true
				t.errors.eventDateErrorMessage = 'Date is required'
			}

			// Start Time
			if (!t.form.eventStartTime) {
				t.errors.eventStartTime = true
				t.errors.eventStartTimeErrorMessage = 'Start Time is required'
			}

			// Finish Time
			if (!t.form.eventFinishTime) {
				t.errors.eventFinishTime = true
				t.errors.eventFinishTimeErrorMessage = 'Finish Time is required'
			}

			// Start and Finish Time - Start cannot be after Finish
			if (t.form.eventStartTime > t.form.eventFinishTime) {
				t.errors.eventStartTime = true
				t.errors.eventStartTimeErrorMessage = 'Start Time cannot be after Finish Time'

				t.errors.eventFinishTime = true
				t.errors.eventFinishTimeErrorMessage = 'Finish Time cannot be before Start Time'
			}

			// Venue
			if (!t.form.eventVenue) {
				t.errors.eventVenue = true
				t.errors.eventVenueErrorMessage = 'Venue is required'
			}

			// Type
			if (!t.form.eventType) {
				t.errors.eventType = true
				t.errors.eventTypeErrorMessage = 'Type is required'
			}

			// Steward Start Time
			if (!t.form.eventStewardsStartTime) {
				t.errors.eventStewardsStartTime = true
				t.errors.eventStewardsStartTimeErrorMessage = 'Steward Start Time is required'
			}

			// Steward Finish Time
			if (!t.form.eventStewardsFinishTime) {
				t.errors.eventStewardsFinishTime = true
				t.errors.eventStewardsFinishTimeErrorMessage = 'Steward Finish Time is required'
			}

			// Steward Start and Finish Time - Start cannot be after Finish
			if (t.form.eventStewardsStartTime > t.form.eventStewardsFinishTime) {
				t.errors.eventStewardsStartTime = true
				t.errors.eventStewardsStartTimeErrorMessage = 'Start Time cannot be after Finish Time'

				t.errors.eventStewardsFinishTime = true
				t.errors.eventStewardsFinishTimeErrorMessage = 'Finish Time cannot be before Start Time'
			}

			// Supervisor/Deputy Start Time
			if (!t.form.eventSupervisorsStartTime) {
				t.errors.eventSupervisorsStartTime = true
				t.errors.eventSupervisorsStartTimeErrorMessage = 'Supervisor/Deputy Start Time is required'
			}

			// Supervisor/Deputy Finish Time
			if (!t.form.eventSupervisorsFinishTime) {
				t.errors.eventSupervisorsFinishTime = true
				t.errors.eventSupervisorsFinishTimeErrorMessage = 'Supervisor/Deputy Finish Time is required'
			}

			// Supervisor/Deputy Start and Finish Time - Start cannot be after Finish
			if (t.form.eventSupervisorsStartTime > t.form.eventSupervisorsFinishTime) {
				t.errors.eventSupervisorsStartTime = true
				t.errors.eventSupervisorsStartTimeErrorMessage = 'Start Time cannot be after Finish Time'

				t.errors.eventSupervisorsFinishTime = true
				t.errors.eventSupervisorsFinishTimeErrorMessage = 'Finish Time cannot be before Start Time'
			}

			// Steward Instructions
			if (!t.form.eventStaffInstructions) {
				t.errors.eventStaffInstructions = true
				t.errors.eventStaffInstructionsErrorMessage = 'Instructions are required'
			}

			return !Object.values(t.errors).includes(true)
		}

	}

}
</script>

<style scoped>

</style>
