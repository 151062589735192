<template>
	<div class="d-flex justify-center" style="width: 100%">
		<div class="d-flex flex-column ma-4" style="min-width: 256px; width: 100%; max-width: 512px">

			<!--Logo-->
			<div class="d-flex justify-center">
				<v-img :src="require('@/assets/images/company-logo.svg')"
					   style="min-width: 256px; width: 100%; max-width: 256px"/>
			</div>

			<app-text class="text-center mt-8" color="grey9" size="medium" style="letter-spacing: 8px">
				Stadium SWAPP
			</app-text>

			<v-divider class="mt-8" style="width: 100%"/>

			<!--Form Title-->
			<app-text class="text-left mt-4" color="primary" size="medium">Reset Password</app-text>

			<app-text class="text-left mt-4">
				If you have forgotten your password, please enter the email address you log in with, and we will email
				you a link to reset your password.
			</app-text>

			<!--Password Criteria-->
			<v-expansion-panels flat>
				<v-expansion-panel class="transparent">

					<!--Header-->
					<v-expansion-panel-header class="px-0">
						<app-text>Passwords must meet the following criteria:</app-text>
					</v-expansion-panel-header>

					<!--Content-->
					<v-expansion-panel-content>
						<ul>
							<li>
								<app-text>Be at least 8 characters long</app-text>
							</li>
							<li>
								<app-text>Contain at least 1 upper case character</app-text>
							</li>
							<li>
								<app-text>Contain at least 1 lower case character</app-text>
							</li>
							<li>
								<app-text>Contain at least 1 number</app-text>
							</li>
							<li>
								<app-text>
									Contain at least 1 special character from
									<span class="font-weight-bold">? # @ ! £ $ % &</span>
								</app-text>
							</li>
						</ul>
					</v-expansion-panel-content>

				</v-expansion-panel>
			</v-expansion-panels>

			<!--Email-->
			<app-form-field form-type="textInput"
							class="mt-4"
							:error="errors.userEmail"
							:error-message="errors.userEmailErrorMessage"
							label="Email"
							placeholder="E.g... joeblogs@email.com"
							type="email"
							v-model.trim="form.userEmail"/>

			<!--Action Buttons-->
			<div class="d-flex justify-space-between mt-4">

				<!--Back to Sign In-->
				<app-btn @click.native="MIX_go('/signIn')"
						 class="pr-2"
						 color="grey"
						 icon="arrowBack"/>

				<!--Next Button-->
				<app-btn @click.native="handleResetPasswordButton"
						 :block="true"
						 class="pl-2"
						 color="primary"
						 label="Reset"
						 style="width: 100%"/>

			</div>

		</div>
	</div>
</template>

<script>
export default {

	name: "ResetPassword",

	data: () => ({
		currentView: 'Register',
		errors: {
			userEmail: false,
			userEmailErrorMessage: '',
		},
		form: {
			userEmail: '',
		},
	}),

	methods: {


		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Reset Password Button
		 *
		 * If the form has passed validation, call to reset.
		 */
		async handleResetPasswordButton() {
			const t = this

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			const RESPONSE = await t.MIX_auth_resetPassword(t.form.userEmail)

			if (RESPONSE.hasErrors) {
				console.error('Error Resetting Password: ', RESPONSE.error)
				return
			}

			t.MIX_go('signIn')
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Email
			const USER_EMAIL = t.form.userEmail
			if (!USER_EMAIL) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email is required'
			}
			// Must be a (simple) valid email address
			else if (!t.MIX_isEmailValid(USER_EMAIL)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email is not valid'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
