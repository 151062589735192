<template>
	<div class="d-flex align-center">

		<!--Icon-->
		<app-icon color="grey9" icon="info" :size="$vuetify.breakpoint.width < 600 ? 48 : 72"/>

		<v-divider class="mx-2" vertical/>

		<!--Title | Description-->
		<div>

			<!--Title-->
			<app-text color="primary" size="normal-bold">{{ title }}</app-text>

			<v-divider class="my-2"/>

			<!--Description-->
			<app-text v-for="item in points" class="mb-1">- {{ item }}</app-text>

		</div>
	</div>
</template>

<script>

export default {

	name: "PageStartInformation",

	props: ['points', 'title'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
