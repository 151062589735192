<template>
	<div>

		<!--Task Form-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<swapp-tasks-task-form v-if="isTaskFormVisible"
								   @closeTaskForm="toggleTaskFormVisibility"
								   @emitReloadData="emittedReloadData"
								   parent-component="Library"
								   :form-data="selectedItem"
								   :users-data="usersData"/>
		</transition>

		<!--Page Content-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="!isTaskFormVisible">

				<!--Action Bar-->
				<div class="d-flex align-center">

					<!--Search-->
					<div @focusin="onSearchFocus" @focusout="onSearchBlur" style="width: 100%">
						<app-form-field form-type="textInput"
										append-icon="icons8-search"
										class="mr-4"
										:clearable="true"
										label="Task Name"
										v-model.trim="searchByTaskName"/>
					</div>

					<!--Add Button-->
					<app-btn v-if="!isSearchFocused"
							 @click.native="toggleTaskFormVisibility"
							 class="mr-4"
							 icon="add"
							 label="Add"/>

					<!--Filter Button - with a numbered badge-->
					<!--<div v-if="!isSearchFocused" style="position:relative;">-->
					<!--	<app-btn @click.native="toggleFiltersVisibility"-->
					<!--			 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>-->
					<!--	<span v-if="computedNumberOfActiveFilters > 0" class="badge">-->
					<!--		{{ computedNumberOfActiveFilters }}</span>-->
					<!--</div>-->

					<!--More Actions Menu-->
					<more-actions-menu v-if="!isSearchFocused" @emitMenuSelection="handleEmittedMenuSelection"
									   :menuList="moreActionsMenuOption"/>

				</div>

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  class="appWhite rounded-lg mt-4"
							  :headers="computedHeaders"
							  :items="computedTableData">

					<!--ID-->
					<template v-slot:item.entityId="{item}">
						<app-text size="small">{{ item.entityId }}</app-text>
					</template>

					<!--Task Name-->
					<template v-slot:item.swappTasksTaskName="{item}">
						<app-text size="small">{{ item.swappTasksTaskName }}</app-text>
					</template>

					<!--Action Button-->
					<template v-slot:item.action="{item}">
						<app-icon @click.native="handleOpenTaskForm(item)"
								  class="cursorPointer" color="primary" icon="arrowForward"/>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600">

					<div v-for="item in computedTableData"
						 @click="handleOpenTaskForm(item)"
						 class="appWhite rounded-lg mt-4 pa-4">

						<app-text color="primary">{{ item.swappTasksTaskName }}</app-text>

					</div>

				</div>


			</div>
		</transition>

		<!--Filters Panel -------------------------------------------------------------------------------------- -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">


			</div>
		</filter-panel>

	</div>
</template>

<script>

import SwappTasksTaskForm from "@/views/swappTasks/admin/swappTasksTaskForm/SwappTasksTaskForm.vue";

export default {

	name: "SwappTasksLibrary",

	components: {SwappTasksTaskForm},

	props: ['swappTasksLibraryTasksData', 'usersData'],

	data: () => ({
		isFiltersPanelVisible: false,
		isTaskFormVisible: false,
		isSearchFocused: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'},
		],
		searchByTaskName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hidden: true},
			{text: 'Task Name', value: 'swappTasksTaskName', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.$props.swappTasksLibraryTasksData

			// Search by Task Name
			if (t.searchByTaskName) {
				const SEARCH_CRITERIA = t.searchByTaskName.toUpperCase()
				tableData = tableData.filter(task => task.swappTasksTaskName.toUpperCase().includes(SEARCH_CRITERIA))
			}

			tableData = tableData.sort((a, b) => a.swappTasksTaskName > b.swappTasksTaskName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		emittedReloadData() {
			const t = this

			t.toggleTaskFormVisibility()
			t.$emit('reloadData')
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') {
			}
		},

		handleOpenTaskForm(item) {
			const t = this

			t.selectedItem = item
			t.toggleTaskFormVisibility()
		},

		/**
		 * On Search Blur
		 *
		 * When the search field is blurred, set the isSearchFocused flag to false.
		 */
		onSearchBlur() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = false
		},

		/**
		 * On Search Focus
		 *
		 * When the search field is focused, set the isSearchFocused flag to true.
		 * This is used to expand the search when on smaller devices.
		 */
		onSearchFocus() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

		toggleTaskFormVisibility() {
			const t = this

			t.isTaskFormVisible = !t.isTaskFormVisible
		},

	},

}
</script>

<style scoped>

</style>
