<template>

	<page-loading-animation v-if="isLoading" is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<swapp-tasks-admin v-if="componentView === 'Admin'"
						   :qr-code-param-props="qrCodeParam"
						   :swapp-tasks-list-data="swappTasksListData"/>

		<swapp-tasks-supervisor v-if="componentView === 'Supervisor'"
							 :qr-code-param-props="qrCodeParam"
							 :swapp-tasks-list-data="swappTasksListData"/>

		<swapp-tasks-user v-if="componentView === 'User'"
						  :qr-code-param-props="qrCodeParam"
						  :swapp-tasks-list-data="swappTasksListData"/>

	</div>

</template>

<script>
import SwappTasksAdmin from "@/views/swappTasks/admin/SwappTasksAdmin.vue";
import SwappTasksUser from "@/views/swappTasks/user/SwappTasksUser.vue";
import SwappTasksSupervisor from "@/views/swappTasks/supervisor/SwappTasksSupervisor.vue";

export default {

	name: "SwappTasks",

	components: {SwappTasksSupervisor, SwappTasksUser, SwappTasksAdmin},

	data: () => ({
		componentView: '',
		isLoading: true,
		qrCodeParam: '',
		swappTasksListData: {},
	}),

	methods: {

		/**
		 * Check QR Code Params
		 *
		 * When scanning a QR code, the result will be a URL with a param.
		 * Check if there is a QR Code param in the URL.
		 *  - If there is (opened through scanning), load the list from the DB.
		 *  - If there is not (opened through navigation), set the param to false so the page can load as normal.
		 *
		 * @returns {Promise<void>}
		 */
		async checkQrCodeParams() {
			const t = this

			t.qrCodeParam = t.$route.params.code

			// If there is a QR Code param, load the list from the DB
			if (t.qrCodeParam) await t.loadListByQrCodeParam(t.qrCodeParam)

			// If there is no QR Code param, set the param to false so the page can load as normal
			else t.qrCodeParam = false
		},

		/**
		 * Load List By QR Code Param
		 *
		 * Load the List Data from the DB by the QR Code param.
		 *
		 * @param qrCodeParam {String} - The QR Code param
		 * @returns {Promise<void>}
		 */
		async loadListByQrCodeParam(qrCodeParam) {
			const t = this

			const RESPONSE = await t.MIX_redis_getSwappTasksListWhere({
				whereKey: 'swappTasksListQrId',
				whereValue: qrCodeParam
			})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Swapp Tasks List: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading this List, please try again.'
				return
			}

			// If there is no data, return to show the No Tasks UI
			if (!RESPONSE.data.length) return

			// If there is data, set the data
			t.swappTasksListData = RESPONSE?.data[0]
		},

	},

	async mounted() {
		const t = this
		t.isLoading = true

		const CURRENT_USER_DATA = t.MIX_getCurrentUser()

		await t.checkQrCodeParams()

		if (['Staff-Admin', 'Staff-Manager'].includes(CURRENT_USER_DATA.userLevel)) t.componentView = 'Admin'
		if (['Staff-Supervisor'].includes(CURRENT_USER_DATA.userLevel)) t.componentView = 'Supervisor'
		if (['Staff-User'].includes(CURRENT_USER_DATA.userLevel)) t.componentView = 'User'

		t.isLoading = false
	},

}
</script>

<style scoped>

</style>
