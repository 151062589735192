<template>
	<v-bottom-sheet hide-overlay
					persistent
					width="320"
					v-model="updateExists">

		<div class="d-flex align-center appGrey rounded-lg pa-4">

			<app-btn @click.native="refreshApp"
					 class="pulse"
					 label="Update Available"/>

			<v-spacer/>

			<close-icon @click.native="updateExists = false"/>

		</div>

	</v-bottom-sheet>
</template>

<script>
export default {
	name: "AppUpdateBanner"
}
</script>

<style scoped>

</style>
