<template>
	<div>

		<events-admin v-if="MIX_getCurrentUser().userType === 'Staff'"/>

		<events-steward v-if="MIX_getCurrentUser().userType === 'Steward'"/>

	</div>
</template>

<script>
import EventsAdmin from "@/views/events/admin/EventsAdmin.vue";
import EventsSteward from "@/views/events/steward/EventsSteward.vue";

export default {

	name: "Events",

	components: {EventsSteward, EventsAdmin},

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
