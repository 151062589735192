<template>
	<div class="appWhite d-flex align-center rounded-lg mt-4 pa-4">

		<!--Status-->
		<app-icon v-if="event.eventStatus === 'Draft'" color="red" icon="draft" size="32"/>
		<app-icon v-if="event.eventStatus === 'Pending'" color="orange" icon="pending" size="32"/>
		<app-icon v-if="event.eventStatus === 'Live'" color="green" icon="success" size="32"/>
		<app-icon v-if="event.eventStatus === 'Archived'" color="grey6" icon="archived" size="32"/>

		<!--Event | Date-->
		<div class="ml-4">

			<!--Event-->
			<app-text color="primary" size="normal-bold">{{ event.eventName }}</app-text>

			<!--Date-->
			<app-text class="mt-1" color="grey9" size="small-bold">{{ MIX_formatDate(event.eventDate, 'long') }}</app-text>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventMobileCard",

	props: ['event'],

}
</script>

<style scoped>

</style>
