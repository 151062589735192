<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Event Name"
							style="width: 100%"
							v-model.trim="searchByEventName"/>

			<!--Add Button-->
			<app-btn @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.reportData.entityId }}</app-text>
			</template>

			<!--Date-->
			<template v-slot:item.debriefReportDate="{item}">
				<app-text size="small">{{ MIX_formatDate(item.reportData.debriefReportDate, 'short') }}</app-text>
			</template>

			<!--Event-->
			<template v-slot:item.eventName="{item}">
				<app-text size="small">{{ item.eventData.eventName }}</app-text>
			</template>

			<!--Team-->
			<template v-slot:item.teamName="{item}">
				<app-text v-if="item?.teamData?.teamName" size="small">{{ item?.teamData?.teamName }}</app-text>
				<app-text v-else color="greyD" size="small">N/A</app-text>
			</template>

			<!--Reporter-->
			<template v-slot:item.createdUserName="{item}">
				<app-text size="small">{{ item.reportData.createdUserName }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No Reports Message-->
			<app-text v-if="!computedTableData.length" class="text-center mt-4" color="grey9">
				You have no Reports to view
			</app-text>

			<debrief-mobile-card v-for="item in computedTableData" :key="item.id"
								 @click.native="openRightPanel(item)"
								 class="mt-4"
								 :fullReportData="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Team-->
				<page-break-title title="Team"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Team"
									v-model.trim="filterByTeam"/>
				</div>

				<!--Reporter-->
				<page-break-title class="mt-8" title="Reporter"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Reporter"
									v-model.trim="filterByReporter"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
				 v-model="isRightPanelVisible">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.observationTitle || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close" size="32"/>
				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">
					<v-tab href="#debrief">
						<app-text size="small">Debrief</app-text>
					</v-tab>
				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Debrief-->
					<v-tab-item value="debrief">
						<debrief-form @emitReloadPage="closeRightPanel"
									  class="pa-4"
									  :fullReportData="selectedItem"
									  :isReadOnly="isReadOnly"
									  :teamsData="teamsData"
									  :eventsData="eventsData"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>
import DebriefMobileCard from "@/views/reporting/debriefs/debriefMobileCard/DebriefMobileCard";
import DebriefForm from "@/views/reporting/debriefs/debriefForm/DebriefForm";

export default {

	name: "DebriefsPage",

	components: {
		DebriefForm,
		DebriefMobileCard,
	},

	data: () => ({
		filterByReporter: '',
		filterByTeam: '',
		isFiltersPanelVisible: false,
		isRightPanelVisible: false,
		isLoading: true,
		isReadOnly: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchByEventName: '',
		searchTeam: '',
		searchReporter: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'start', sortable: false, hidden: true},
			{text: 'Date', value: 'debriefReportDate', align: 'start', sortable: false, width: '128px'},
			{text: 'Event', value: 'eventName', align: 'start', sortable: false},
			{text: 'Team', value: 'teamName', align: 'start', sortable: false},
			{text: 'Reporter', value: 'createdUserName', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		debriefReportsData: [],
		eventsData: [],
		teamsData: [],
	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				entityId: 'ID',
				debriefReportEvent: 'Event',
				debriefReportTeam: 'Team',
				debriefReportDate: 'Date',
				debriefReportDefects: 'Defects',
				debriefReportEquipmentIssues: 'Equipment Issues',
				debriefReportFirstAid: 'First Aid',
				debriefReportPersonalIssues: 'Personal Issues',
				debriefReportComments: 'Comments',
			}

			// Add the data
			t.computedTableData.forEach(entry => {

				const DATA_OBJECT = {
					entityId: entry?.reportData?.entityId,
					debriefReportEvent: entry?.eventData?.eventName,
					debriefReportTeam: entry?.teamData?.teamName,
					debriefReportDate: t.MIX_formatDate(entry?.reportData?.debriefReportDate, 'numeric'),
					debriefReportDefects: entry?.reportData?.debriefReportDefects,
					debriefReportEquipmentIssues: entry?.reportData?.debriefReportEquipmentIssues,
					debriefReportFirstAid: entry?.reportData?.debriefReportFirstAid,
					debriefReportPersonalIssues: entry?.reportData?.debriefReportPersonalIssues,
					debriefReportComments: entry?.reportData?.debriefReportComments,
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByTeam ? 1 : 0,
				t.filterByReporter ? 1 : 0,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let reportsData = t.debriefReportsData

			// Staff Admin can see all, everyone else can only see their own
			if (CURRENT_USER_DATA.userLevel !== 'Staff-Admin') reportsData = reportsData.filter(rd => rd.reportData.createdUserId === CURRENT_USER_DATA.entityId)

			// Search by Event
			if (t.searchByEventName) {
				const SEARCH_CRITERIA = t.searchByEventName.toUpperCase()
				reportsData = reportsData.filter(rd => {
					const EVENT_NAME = rd.eventData?.eventName?.toUpperCase()
					return EVENT_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Team
			if (t.filterByTeam) {
				const SEARCH_CRITERIA = t.filterByTeam.toUpperCase()
				reportsData = reportsData.filter(rd => {
					const TEAM_NAME = rd.teamData?.teamName?.toUpperCase()
					return TEAM_NAME?.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Reporter
			if (t.filterByReporter) {
				const SEARCH_CRITERIA = t.filterByReporter.toUpperCase()
				reportsData = reportsData.filter(rd => {
					const REPORTER_NAME = rd.reportData?.createdUserName?.toUpperCase()
					return REPORTER_NAME?.includes(SEARCH_CRITERIA)
				})
			}

			// Sort by Date
			reportsData = reportsData.sort((a, b) => a.debriefReportDate > b.debriefReportDate ? 1 : -1)

			return reportsData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByTeam = ''
			t.filterByReporter = ''
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
			t.loadData()
		},

		/**
		 * Delete Item
		 *
		 * Delete the selected item (only marks as deleted).
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('debriefReport', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Debrief Report: ', RESPONSE.error)
				return
			}

			await t.loadData()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Debriefs', t.computedExportCSV.data)
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadDebriefReportsData(),
				t.loadEventsData(),
				t.loadTeamsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Debrief Reports Data
		 *
		 * Load Debrief Reports data from the database.
		 * Loads the full reporting data from the DB:
		 *  - Report data
		 *  - Event data
		 *  - Team data
		 *
		 * @returns {Promise<void>}
		 */
		async loadDebriefReportsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('debriefReport')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Debrief Reports: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.debriefReportsData = RESPONSE.data
		},

		/**
		 * Load Events Data
		 *
		 * Load Events data from the database.
		 * Load all the Live Events and filter out anything that starts after today.
		 * This is so a future Event cannot be selected, but incomplete recent Event reports can.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventsWhere({whereKey: 'eventStatus', whereValue: 'Live'})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Events: ', RESPONSE.error)
				return
			}

			// Only use Events that start before tomorrow (23:59)
			const newDate = new Date()
			const endOfToday = newDate.setUTCHours(23, 59, 59, 999)
			t.eventsData = RESPONSE.data?.filter(event => event.eventDate < new Date(endOfToday).getTime())
		},

		/**
		 * Load Teams Data
		 *
		 * Load Teams data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadTeamsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('team')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Teams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.teamsData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			t.isReadOnly = !!item?.reportData?.entityId

			t.selectedItem = item

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async created() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
