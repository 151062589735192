<template>
	<div class="appWhite d-flex align-center rounded-lg mt-4 pa-4">

		<div style="width: 100%">

			<!--Title-->
			<app-text color="primary" size="normal-bold">{{ fullReportData.reportData.accidentReportTitle }}</app-text>

			<!--Type-->
			<app-text class="mt-1" color="grey9" size="small">{{ fullReportData.reportData.accidentReportType }}</app-text>

			<!--Date-->
			<app-text class="mt-1" color="grey9" size="small-bold">
				{{ MIX_formatDate(fullReportData.reportData.accidentReportDate, 'short') }} @ {{ fullReportData.reportData.accidentReportTime }}
			</app-text>

		</div>

	</div>
</template>

<script>
export default {

	name: "AccidentMobileCard",

	props: ['fullReportData'],

}
</script>

<style scoped>

</style>
