<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--Access Message-->
		<app-text v-if="form.observationStatus" class="mb-4" color="red" size="small">
			This Observation has been submitted and cannot be edited.
		</app-text>

		<!--Form-->
		<v-row no-gutters>

			<!--Title-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:counter="!computedIsReadOnly"
								:disabled="computedIsReadOnly"
								:error="errors.observationTitle"
								:error-message="errors.observationTitleErrorMessage"
								label="Title"
								max-length="32"
								v-model.trim="form.observationTitle"/>
			</v-col>

			<!--Type-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Type"
								v-model.trim="form.observationType"/>
			</v-col>

			<!--Priority-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="computedIsReadOnly"
								:error="errors.observationPriority"
								:error-message="errors.observationPriorityErrorMessage"
								:items="observationPriorityOptions"
								label="Priority"
								v-model="form.observationPriority"/>
			</v-col>

			<!--Date Occurred-->
			<v-col :class="$vuetify.breakpoint.width < 400 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 400 ? 12 : 6">
				<date-picker @emitDate="handleDateSelection($event, 'observationDateOccurred')"
							 :clearable="false"
							 :date="form.observationDateOccurred"
							 :disabled="computedIsReadOnly"
							 :error="errors.observationDateOccurred"
							 :error-message="errors.observationDateOccurredErrorMessage"
							 label="Date Occurred"
							 :min-date="[7, 'days', 'past']"/>
			</v-col>

			<!--Time Occurred-->
			<v-col :class="$vuetify.breakpoint.width < 400 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 400 ? 12 : 6"
				   :title="!form.observationDateOccurred && 'Select a Date first'">
				<time-picker @emitTime="handleTimeSelection($event, 'observationTimeOccurred')"
							 :clearable="false"
							 :disabled="!form.observationDateOccurred || (form.observationDateOccurred && computedIsReadOnly)"
							 :error="errors.observationTimeOccurred"
							 :error-message="errors.observationTimeOccurredErrorMessage"
							 label="Time Occurred"
							 :max="new Date().getHours() + ':' + new Date().getMinutes()"
							 :selectedDate="form.observationDateOccurred"
							 :time="form.observationTimeOccurred"/>
			</v-col>

			<!--Site-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:disabled="computedIsReadOnly"
								:error="errors.observationSite"
								:error-message="errors.observationSiteErrorMessage"
								:items="sitesData"
								item-text="siteName"
								label="Site"
								v-model="form.observationSite"/>
			</v-col>

			<!--Location-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="computedIsReadOnly"
								:error="errors.observationLocation"
								:error-message="errors.observationLocationErrorMessage"
								label="Location"
								placeholder="E.g. East stand, second floor"
								v-model.trim="form.observationLocation"/>
			</v-col>

			<!--Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="computedIsReadOnly"
								:error="errors.observationReportingDescription"
								:error-message="errors.observationReportingDescriptionErrorMessage"
								label="Description"
								:placeholder="`What happened?\nWhat caused it?\nPlease give as much details as you can`"
								v-model.trim="form.observationReportingDescription"/>
			</v-col>

			<!--Steps Taken-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="computedIsReadOnly"
								label="Steps Taken (optional)"
								placeholder="What, if any, temporary steps have you taken?"
								v-model.trim="form.observationReportingStepsTaken"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn v-if="!computedIsReadOnly"
					 @click.native="handleSaveButton"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "ObservationReportingForm",

	props: ['formData', 'isReadOnly', 'sitesData'],

	data: () => ({
		errors: {
			observationType: false,
			observationTypeErrorMessage: '',
			observationTitle: false,
			observationTitleErrorMessage: '',
			observationReportingDescription: false,
			observationReportingDescriptionErrorMessage: '',
			observationSite: false,
			observationSiteErrorMessage: '',
			observationLocation: false,
			observationLocationErrorMessage: '',
			observationDateOccurred: false,
			observationDateOccurredErrorMessage: '',
			observationTimeOccurred: false,
			observationTimeOccurredErrorMessage: '',
			observationPriority: false,
			observationPriorityErrorMessage: '',
		},
		form: {
			entityId: '',
			observationTitle: '',
			observationType: 'Observation',
			observationPriority: '',
			observationDateOccurred: 0,
			observationTimeOccurred: '',
			observationSite: '',
			observationLocation: '',
			observationReportingDescription: '',
			observationReportingStepsTaken: '',
			observationStatus: '',
			observationRespondingUser: '',
			observationRespondingDescription: '',

			createdUserId: '',
			createdUserName: '',
			createdDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			modifiedDateTime: 0,
			isDeleted: false,
			deletedUserId: '',
			deletedUserName: '',
			deletedDateTime: 0,
		}
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

		/**
		 * Computed Is Read Only
		 *
		 * If the form has a status (has been submitted), it should be read only.
		 * If the form hasn't been submitted, it should be editable.
		 *
		 * @returns {boolean} isReadOnly
		 */
		computedIsReadOnly() {
			const t = this
			const OBSERVATION_DATA = t.form

			let isReadOnly = false

			if (OBSERVATION_DATA.observationStatus || t.$props.isReadOnly) isReadOnly = true

			return isReadOnly
		}

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the emitted payload as $event data from the input field, and the field name,
		 * and sets the date to the specified form field.
		 *
		 * @param date the date to set
		 * @param field the form field to set
		 */
		handleDateSelection(date, field) {
			const t = this

			// Set the date to the current form field
			t.form[field] = Number(date)
		},

		/**
		 * Handle Time Selection
		 *
		 * Take the time from the picker and store it.
		 *
		 * @param time the time string from the picker
		 * @param field the form field to set
		 */
		handleTimeSelection(time, field) {
			const t = this

			// Set the time to the current form field
			t.form[field] = time
		},

		/**
		 * Handle Save Button
		 */
		handleSaveButton() {
			const t = this

			if (!t.validateForm()) return

			t.saveItem()
		},

		/**
		 * Save Item
		 *
		 * Save/Update the form to the DB.
		 */
		async saveItem() {
			const t = this
			const FORM_DATA = t.form

			FORM_DATA.observationStatus = 'Pending'

			const RESPONSE = await t.MIX_redis_create('observation', FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Observation: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Created Report'

			t.$emit('emitReloadData')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Title
			if (!t.form.observationTitle) {
				t.errors.observationTitle = true
				t.errors.observationTitleErrorMessage = 'Title is required'
			}

			// Type
			if (!t.form.observationType) {
				t.errors.observationType = true
				t.errors.observationTypeErrorMessage = 'Type is required'
			}

			// Priority
			if (!t.form.observationPriority) {
				t.errors.observationPriority = true
				t.errors.observationPriorityErrorMessage = 'Priority is required'
			}

			// Date Occurred
			if (!t.form.observationDateOccurred) {
				t.errors.observationDateOccurred = true
				t.errors.observationDateOccurredErrorMessage = 'Date is required'
			}

			// Time Occurred
			if (!t.form.observationTimeOccurred) {
				t.errors.observationTimeOccurred = true
				t.errors.observationTimeOccurredErrorMessage = 'Time is required'
			}

			// Site
			if (!t.form.observationSite) {
				t.errors.observationSite = true
				t.errors.observationSiteErrorMessage = 'Site is required'
			}

			// Location
			if (!t.form.observationLocation) {
				t.errors.observationLocation = true
				t.errors.observationLocationErrorMessage = 'Location is required'
			}

			// Description
			if (!t.form.observationReportingDescription) {
				t.errors.observationReportingDescription = true
				t.errors.observationReportingDescriptionErrorMessage = 'Description is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	}

}
</script>

<style scoped>

</style>
