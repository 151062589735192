<!--<template>-->
<!--	<div class="white" style="min-height: 100vh">-->

<!--		<v-toolbar class="noPrint" flat color="appGrey">-->
<!--			<app-text size="medium-bold">Print Preview</app-text>-->
<!--			<v-spacer/>-->
<!--			<app-btn @click.native="printPass('printableArea')" height="40" label="Print"/>-->
<!--			<close-icon @click.native="closeDialog"/>-->
<!--		</v-toolbar>-->

<!--		&lt;!&ndash;Pass -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;-->
<!--		<div v-for="item in passesData" id="printableArea" class="printPass-container white">-->

<!--			&lt;!&ndash;Event Image&ndash;&gt;-->
<!--			<div style="height: 5cm; border: 1px solid grey; max-width: 80mm">-->
<!--				<v-img :src="require('@/assets/images/mock-event-background-image.jpg')"-->
<!--					   contain-->
<!--					   position="top"/>-->
<!--			</div>-->

<!--			&lt;!&ndash;User Details&ndash;&gt;-->
<!--			<div id="userDetails-container" class="d-flex align-center mt-2 pa-2" style="border: 1px solid grey"> -->

<!--				<div>-->

<!--					&lt;!&ndash;User Name&ndash;&gt;-->
<!--					<app-text id="userName" :style="{'font-size': calculateFontSize(item.userData.userName)}">-->
<!--						{{ item.userData.userName }}-->
<!--					</app-text>-->

<!--					&lt;!&ndash;Organisation Name&ndash;&gt;-->
<!--					<app-text size="normal-bold">{{ item.organisationData.organisationName }}</app-text>-->

<!--					&lt;!&ndash;User Position&ndash;&gt;-->
<!--					<app-text>{{ item.userData.userPosition }}</app-text>-->

<!--				</div>-->

<!--				<v-spacer/>-->

<!--				<div class="centerMiddle rounded-lg"-->
<!--					 :style="`aspect-ration: 1/1; border: 1px solid lightgrey; height: ${imageHeightWidth} + 'mm'; width: ${imageHeightWidth} + 'mm'`">-->
<!--					<app-icon color="grey9" icon="user" size="96"/>-->
<!--				</div>-->

<!--			</div>-->

<!--			&lt;!&ndash;Zones&ndash;&gt;-->
<!--			<div class="primary" style="display: grid; grid-template-columns: repeat(3, 1fr)">-->

<!--				<div class="centerMiddle zone">-->
<!--					<app-text>1</app-text>-->
<!--				</div>-->

<!--				<div class="centerMiddle zone">-->
<!--					<app-text>2</app-text>-->
<!--				</div>-->

<!--				<div class="centerMiddle zone">-->
<!--					<app-text>3</app-text>-->
<!--				</div>-->

<!--				<div class="centerMiddle zone">-->
<!--					<app-text>4</app-text>-->
<!--				</div>-->

<!--				<div class="centerMiddle zone">-->
<!--					<app-text>5</app-text>-->
<!--				</div>-->

<!--				<div class="centerMiddle image">-->
<!--					<v-img :src="require('@/assets/images/company-logo-white.svg')" width="2cm"/>-->
<!--				</div>-->

<!--			</div>-->

<!--		</div>-->
<!--	</div>-->
<!--</template>-->

<!--<script>-->

<!--export default {-->

<!--	name: "AccreditationPrintPass",-->

<!--	props: ['passesData'],-->

<!--	data: () => ({-->
<!--		imageHeightWidth: 32,-->
<!--	}),-->

<!--	methods: {-->

<!--		calculateFontSize(text) {-->
<!--			const CONTAINER_WIDTH = 80 - this.imageHeightWidth-->
<!--			let fontSize = (CONTAINER_WIDTH / text.length) * 1.7-->

<!--			if (fontSize > 30) fontSize = 30-->

<!--			return fontSize + 'mm'-->
<!--		},-->

<!--		closeDialog() {-->
<!--			this.$emit('closeDialog')-->
<!--		},-->

<!--		printPass(printableArea) {-->

<!--			const printContents = document.getElementById(printableArea).innerHTML-->

<!--			const windowPrint = window.open('', '_blank')-->

<!--			windowPrint.document.close()-->
<!--			windowPrint.document.write(printContents)-->

<!--			window.print()-->
<!--		},-->

<!--		// printPass(printableArea) {-->
<!--		// 	const printContents = document.getElementById(printableArea).innerHTML;-->
<!--		// 	const styles = document.querySelectorAll('style, link[rel="stylesheet"]');-->
<!--		// 	const windowPrint = window.open('', '_blank');-->
<!--		//-->
<!--		// 	styles.forEach(style => {-->
<!--		// 		windowPrint.document.head.appendChild(style.cloneNode(true));-->
<!--		// 	});-->
<!--		//-->
<!--		// 	windowPrint.document.body.innerHTML = printContents;-->
<!--		// 	windowPrint.focus();-->
<!--		// 	windowPrint.print();-->
<!--		// }-->


<!--	},-->

<!--}-->
<!--</script>-->

<!--<style>-->
<!--@media print {-->

<!--	@page {-->
<!--		size: 80mm 140mm;-->
<!--		margin: 0;-->
<!--	}-->

<!--	.noPrint {-->
<!--		display: none;-->
<!--	}-->
<!--}-->

<!--.printPass-container {-->
<!--	display: grid;-->
<!--	grid-template-rows: auto auto auto;-->
<!--	width: 80mm;-->
<!--	height: 140mm;-->
<!--}-->

<!--.centerMiddle {-->
<!--	display: flex;-->
<!--	align-items: center;-->
<!--	justify-content: center;-->
<!--}-->

<!--.zone {-->
<!--	aspect-ratio: 1/1;-->
<!--	background: white;-->
<!--	border: 2mm solid var(&#45;&#45;v-primary-base);-->
<!--	border-radius: 4mm;-->
<!--}-->

<!--.image {-->
<!--	aspect-ratio: 1/1;-->
<!--	border: 2mm solid var(&#45;&#45;v-primary-base);-->
<!--	border-radius: 4mm;-->
<!--}-->

<!--#userDetails-container {-->
<!--	width: 80mm;-->
<!--}-->
<!--</style>-->


<template>
	<div>

		<component is="style">
			@media print {

			@page {
			margin: 0;

			size: 200mm 297mm;
			}

			.noPrint {
			display: none;
			}

			}
		</component>
		<v-card class="oppp-card" flat id="page">

			<!--Toolbar-->
			<v-toolbar class="noPrint px-3" color="appGrey">

				<app-text color="primary" size="medium-bold">Pass Print Preview</app-text>

				<v-spacer/>

				<app-btn @click.native="printPass('printableArea')"
						 color="primary"
						 height="40"
						 icon="print"
						 icon-color="white"
						 label="Print"
						 label-color="white"/>

				<close-icon class="ml-4" @click.native="closeDialog"/>

			</v-toolbar>

			<!--Body-->
			<component is="style">
				.oppp-pass-container-outer {
				/*display: flex;*/
				/*align-items: center;*/
				/*justify-content: center;*/
				}
			</component>
			<div class="oppp-pass-container-outer" id="printableArea">

				<component is="style">
					.oppp-pass-container {
					background: white;
					<!--border: 1px solid lightgrey;-->
					margin: -2mm 0;
					<!--padding: 4mm;-->
					height: 297mm;
					width: 200mm;
					}
				</component>
				<div v-for="item in passesData" class="oppp-pass-container">

					<!--Event Background-->
					<component is="style">
						.oppp-pass-eventBackground-container {
						border-radius: 2mm;
						}
					</component>
					<img :src="require('@/assets/images/mock-event-background-image.jpg')"
						 alt="event background"
						 class="oppp-pass-eventBackground-container"
						 height="512px"
						 width="100%"/>

					<!--User Details-->
					<component is="style">
						.oppp-pass-userDetails-container {
						display: grid;
						grid-template-columns: 1fr 62mm;
						margin: 4mm 0;
						height: 62mm;
						}
					</component>
					<div class="oppp-pass-userDetails-container">

						<!--User Text-->
						<component is="style">
							.oppp-pass-userText-container {
							display: grid;
							grid-template-rows: 1fr auto auto;
							margin-right: 4mm;
							}
						</component>
						<div class="oppp-pass-userText-container">

							<component is="style">
								.oppp-user-text {
								font-family: Avenir, Helvetica, Arial, sans-serif;
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;
								color: #333333;
								font-size: 32pt;
								line-height: 48pt;
								}
							</component>

							<!--Contact Name-->
							<app-text class="oppp-user-text"
									  :style="{'font-size': calculateFontSize(item.userData.userName), lineHeight: '104px'}">
								{{ item.userData.userName }}
							</app-text>

							<!--Organisation Name-->
							<app-text class="oppp-user-text">{{ item.organisationData.organisationName }}</app-text>

							<!--Position-->
							<app-text class="oppp-user-text" style="color: #999999">{{ item.userData.userPosition }}
							</app-text>

						</div>

						<!--User Profile Picture-->
						<component is="style">
							.oppp-pass-profilePicture {
							border-radius: 2mm;
							aspect-ratio: 1;
							object-fit: cover;
							width: 62mm;
							}
						</component>
						<img :src="item.userData.userProfilePicture"
							 alt="profile picture"
							 class="oppp-pass-profilePicture"/>

					</div>

					<!--Zones-->
					<component is="style">
						.oppp-pass-zones-container {
						display: grid;
						grid-template-rows: repeat(2, 1fr);
						grid-template-columns: repeat(3, 1fr);
						background: #CC0E23;
						grid-gap: 4mm;
						border: 1mm solid #CC0E23;
						border-radius: 2mm;
						padding: 2mm;
						}
					</component>
					<div class="oppp-pass-zones-container">

						<component is="style">
							.oppp-pass-zones-child-container {
							display: flex;
							align-items: center;
							justify-content: center;
							background: white;
							border-radius: 2mm;
							height: 39mm;
							}
						</component>
						<component is="style">
							.oppp-zone-numbers {
							font-family: Avenir, Helvetica, Arial, sans-serif;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							color: #CC0E23;
							font-size: 6rem;
							font-weight: bold;
							}
						</component>
						<div class="oppp-pass-zones-child-container">
							<app-text class="oppp-zone-numbers">1</app-text>
						</div>
						<div class="oppp-pass-zones-child-container">
							<app-text class="oppp-zone-numbers">2</app-text>
						</div>
						<div class="oppp-pass-zones-child-container">
							<app-text class="oppp-zone-numbers">3</app-text>
						</div>
						<div class="oppp-pass-zones-child-container">
							<app-text class="oppp-zone-numbers">4</app-text>
						</div>
						<div class="oppp-pass-zones-child-container">
							<app-text class="oppp-zone-numbers">5</app-text>
						</div>

						<component is="style">
							.oppp-pass-zones-image-container {
							display: flex;
							align-items: center;
							justify-content: center;
							}
							.oppp-pass-zones-image {
							width: 112px;
							}
						</component>
						<div class="oppp-pass-zones-image-container">
							<img :src="require('@/assets/images/company-logo-white.svg')"
								 alt="company logo"
								 class="oppp-pass-zones-image"/>
						</div>

					</div>

				</div>

			</div>

		</v-card>

	</div>
</template>

<script>
export default {

	name: "organisationPassPrintPreview",

	props: ['passesData'],

	methods: {

		closeDialog() {
			const t = this

			t.$emit('closeDialog', t.$props.passesData)
		},

		printPass(printableArea) {

			const DIV_CONTENTS = document.getElementById(printableArea).innerHTML
			const WIN = window.open('', '', 'height=600, width=960')
			WIN.document.write(DIV_CONTENTS)

			setTimeout(() => {
				WIN.document.close()
				WIN.print()
			}, 1000)

		},

		calculateFontSize(text) {
			const widthOfContainer = 800
			let fontSize = widthOfContainer / text.length

			if (fontSize > 104) fontSize = 104

			return fontSize + 'px'
		},

	},

}
</script>

<style scoped>
@media print {

	@page {
		margin: 0;

		size: 200mm 297mm;
	}

	.noPrint {
		display: none;
	}

}
</style>
