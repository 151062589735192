<template>
	<div class="appGrey rounded-lg pa-4">

		<!--Icon | Title-->
		<div class="d-flex align-center">

			<!--Icon-->
			<app-icon class="mr-4" color="grey9" icon="comment" size="32"/>

			<!--Title - Users Only-->
			<app-text v-if="['Staff-User'].includes(MIX_getCurrentUser().userLevel)"
					  color="primary" size="normal-bold">Need to Leave a Comment?
			</app-text>

			<!--Title - Admin and Supervisors Only-->
			<app-text v-if="!['Staff-User'].includes(MIX_getCurrentUser().userLevel)"
					  color="primary" size="normal-bold">Comment Left
			</app-text>

		</div>

		<v-divider class="greyD my-4"/>

		<!--Comment Input - Users Only-->
		<app-form-field v-if="['Staff-User'].includes(MIX_getCurrentUser().userLevel)"
						form-type="textArea"
						:error="errors.swappTasksTaskUserComment"
						:error-message="errors.swappTasksTaskUserCommentErrorMessage"
						label="What would like to say?"
						placeholder="Please leave as much detail as you can"
						rows="8"
						v-model="form.swappTasksTaskUserComment"/>

		<!--Comment Text - Admin and Supervisors Only-->
		<app-text v-if="!['Staff-User'].includes(MIX_getCurrentUser().userLevel) && form.swappTasksTaskUserComment">
			{{ form.swappTasksTaskUserComment }}
		</app-text>
		<app-text v-if="!['Staff-User'].includes(MIX_getCurrentUser().userLevel) && !form.swappTasksTaskUserComment"
				  class="text-center" color="grey9">
			No comment left
		</app-text>

		<v-divider class="greyD my-4"/>

		<!--Action Buttons-->
		<div class="d-flex justify-space-between">

			<!--Close-->
			<app-btn @click.native="handleCancelButton"
					 :block="true" class="pr-2" color="grey" label="Close" style="width: 100%"/>

			<!--Save-->
			<app-btn v-if="['Staff-User'].includes(MIX_getCurrentUser().userLevel)"
					 @click.native="handleSaveButton"
					 :block="true" class="pl-2" color="green" label="Save" style="width: 100%"/>

		</div>

	</div>
</template>

<script>

export default {

	name: "SwappTasksCommentsDialog",

	props: ['taskData'],

	data: () => ({
		errors: {
			swappTasksTaskUserComment: false,
			swappTasksTaskUserCommentErrorMessage: '',
		},
		form: {
			swappTasksTaskUserComment: '',
		},
	}),

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Dialog
		 *
		 * Emit back to the parent to close the dialog.
		 */
		closeDialog() {
			this.$emit('closeDialog')
		},

		/**
		 * Handle Cancel Button
		 *
		 * Clear the form and call to close the dialog.
		 */
		handleCancelButton() {
			const t = this

			// Clear the form
			t.form.swappTasksTaskUserComment = ''

			t.closeDialog()
		},

		/**
		 * Handle Save Button
		 *
		 * Handle the save button being clicked.
		 * Validate the form and then call to update the item.
		 */
		async handleSaveButton() {
			const t = this

			// Validate the form before proceeding
			if (!t.validateForm()) return

			// t.form.swappTasksTaskUserComment = t.form.swappTasksTaskUserComment
			t.form.swappTasksTaskUserCommentDateTime = new Date().getTime()

			await t.updateItem()
		},

		/**
		 * Update Item
		 *
		 * Update the item in the database.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('swappTasksTask', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Swapp Tasks Task: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this Task, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Comment Saved'
			t.closeDialog()
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Comment
			if (!t.form.swappTasksTaskUserComment) {
				t.errors.swappTasksTaskUserComment = true
				t.errors.swappTasksTaskUserCommentErrorMessage = 'Please enter a comment'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	mounted() {
		const t = this
		// t.form.swappTasksTaskUserComment = t.$props.taskData.swappTasksTaskUserComment
		t.form = t.$props.taskData
	},

}
</script>

<style scoped>

</style>
