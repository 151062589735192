import { render, staticRenderFns } from "./PreEventDashboard.vue?vue&type=template&id=547dfb00&scoped=true&"
import script from "./PreEventDashboard.vue?vue&type=script&lang=js&"
export * from "./PreEventDashboard.vue?vue&type=script&lang=js&"
import style0 from "./PreEventDashboard.vue?vue&type=style&index=0&id=547dfb00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "547dfb00",
  null
  
)

export default component.exports