<template>
	<div class="appWhite d-flex align-center rounded-lg mt-4 pa-4">

		<!--Status-->
		<div class="mr-4">

			<app-icon v-if="report.observationStatus === 'Pending'" color="red" icon="draft" size="32"/>
			<app-icon v-if="report.observationStatus === 'In Progress'" color="orange" icon="pending" size="32"/>
			<app-icon v-if="report.observationStatus === 'On Hold'" color="red" icon="onHold" size="32"/>
			<app-icon v-if="report.observationStatus === 'Resolved'" color="green" icon="success" size="32"/>

		</div>

		<div style="width: 100%">

			<!--UserName-->
			<app-text color="primary" size="normal-bold">{{ report.observationTitle }}</app-text>

			<!--Type-->
			<app-text class="mt-1" color="grey9" size="small">{{ report.observationType }}</app-text>

			<!--Date of Observation-->
			<app-text class="mt-1" color="grey9" size="small-bold">
				{{ MIX_formatDate(report.observationDateOccurred, 'short') }} @ {{ report.observationTimeOccurred }}
			</app-text>

		</div>

	</div>
</template>

<script>
export default {

	name: "ObservationMobileCard",

	props: ['report'],

}
</script>

<style scoped>

</style>
