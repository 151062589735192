<template>
	<div class="d-flex justify-center" style="width: 100%">

		<div class="d-flex flex-column ma-4" style="min-width: 256px; width: 100%; max-width: 512px">

			<!--Logo-->
			<div class="d-flex justify-center">
				<v-img :src="require('@/assets/images/company-logo.svg')"
					   style="min-width: 256px; width: 100%; max-width: 256px"/>
			</div>

			<app-text class="text-center mt-8" color="grey" size="medium-bold" style="letter-spacing: 4px">
				Welcome to SWAPP
			</app-text>
			<app-text class="text-center mt-4" color="grey">
				The Smart Workplace App
			</app-text>

			<v-divider class="mt-8" style="width: 100%"/>

			<!--Form Title-->
			<app-text class="text-left mt-4" color="primary" size="medium">Sign In</app-text>

			<!--Email-->
			<app-form-field form-type="textInput"
							class="mt-4"
							:error="errors.userEmail"
							:error-message="errors.userEmailErrorMessage"
							label="Email"
							type="email"
							v-model.trim="form.userEmail"/>

			<!--Password-->
			<v-text-field @click:append="isPasswordVisible = !isPasswordVisible"
						  :append-icon="isPasswordVisible ? 'icons8-eye' : 'icons8-closed-eye'"
						  background-color="appWhite"
						  class="rounded-lg labelColor mt-4"
						  :error="errors.userPassword"
						  :error-messages="errors.userPasswordErrorMessage"
						  flat
						  hide-details="auto"
						  label="Password"
						  outlined
						  placeholder="Password"
						  :type="isPasswordVisible ? 'text' : 'password'"
						  v-model.trim="form.userPassword"/>

			<!--Sign In Button-->
			<app-btn @click.native="handleSignInButton"
					 :block="true"
					 class="mt-4"
					 color="primary"
					 label="Sign In"
					 label-color="white"
					 style="width: 100%"/>

			<!--Register Link-->
			<app-text @click.native="MIX_go('/register')" class="text-center mt-4">Don't have an account?
				<span class="cursorPointer primary--text">Register</span>
			</app-text>

			<!--Reset Password Link-->
			<app-text @click.native="MIX_go('/resetPassword')" class="text-center mt-4">Forgot your password?
				<span class="cursorPointer primary--text">Reset</span>
			</app-text>

			<!--Version Number-->
			<app-text class="text-center mt-4">v{{ appVersion }}</app-text>

		</div>

	</div>
</template>

<script>
export default {

	name: "SignIn",

	data: () => ({
		errors: {
			userEmail: false,
			userEmailErrorMessage: '',
			userPassword: false,
			userPasswordErrorMessage: '',
		},
		form: {
			userEmail: '',
			userPassword: '',
		},
		isPasswordVisible: false,
	}),

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Handle Sign In Button
		 */
		handleSignInButton() {
			const t = this

			if (!t.validateForm()) return

			t.signIn()
		},

		/**
		 * Load Current User Data
		 *
		 * Load User data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadCurrentUserData(userEmail) {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getUsersWhere({whereKey: 'userEmail', whereValue: userEmail})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting User data: ', RESPONSE.error)
				return
			}

			// Assign the data
			t.MIX_addToLocalStorage('currentUserData', RESPONSE.data[0])
			t.MIX_go('/')
		},

		async signIn() {
			const t = this

			const RESPONSE = await t.MIX_auth_signInUser(t.form.userEmail, t.form.userPassword)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error Signing In: ', RESPONSE.error)
				return
			}

			await t.loadCurrentUserData(t.form.userEmail)
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Email
			const USER_EMAIL = t.form.userEmail
			if (!USER_EMAIL) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email is required'
			}
			// Must be a (simple) valid email address
			else if (!t.MIX_isEmailValid(USER_EMAIL)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email is not valid'
			}

			// Password
			const USER_PASSWORD = t.form.userPassword
			if (!USER_PASSWORD) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password is required'
			} else if (!t.MIX_isPasswordValid(USER_PASSWORD)) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password not in the expected format'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
    border-color: transparent;
}
.labelColor >>> .v-label{
    color: var(--v-primary-base) !important;
    opacity: 0.5;
}
</style>
