<template>
	<div class="appWhite d-flex flex-column align-center justify-center rounded-lg ml-4 pa-4"
		 style="height: 80px; width: 120px">

		<!--Label-->
		<app-text :color="cardColor" size="xlarge-bold">{{ cardValue }}</app-text>

		<!--Value-->
		<app-text :color="cardColor" size="small">{{ cardLabel }}</app-text>

	</div>
</template>

<script>
export default {

	name: "PreEventTotalCard",

	props: ['cardLabel', 'cardValue', 'cardColor'],

}
</script>

<style scoped>

</style>
