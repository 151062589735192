<template>
	<div>

		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<!--QR Data-->
			<div class="d-flex align-center">
				<app-text class="mr-2" color="grey9" size="small">QR Code ID:</app-text>
				<app-text v-if="qrCodeParam || form.swappTasksListQrId" color="grey9" size="small-bold">
					{{ qrCodeParam || form.swappTasksListQrId }}
				</app-text>
				<app-text v-else class="flash" color="red" size="small">Not Linked</app-text>
			</div>

			<!--List Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.swappTasksListName"
								:error-message="errors.swappTasksListNameErrorMessage"
								label="List Name"
								v-model.trim="form.swappTasksListName"/>
			</v-col>

			<!--List Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="autoComplete"
								:disabled="isReadOnly"
								:error="errors.swappTasksListSite"
								:error-message="errors.swappTasksListSiteErrorMessage"
								:items="sitesData"
								item-text="siteName"
								label="Site"
								:return-object="true"
								v-model.trim="displaySiteObject"/>
			</v-col>

			<!--Area-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.swappTasksListArea"
								:error-message="errors.swappTasksListAreaErrorMessage"
								label="Area"
								v-model.trim="form.swappTasksListArea"/>
			</v-col>

			<!--Location-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.swappTasksListLocation"
								:error-message="errors.swappTasksListLocationErrorMessage"
								label="Location"
								v-model.trim="form.swappTasksListLocation"/>
			</v-col>

			<!--Role-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="autoComplete"
								:disabled="isReadOnly"
								:error="errors.swappTasksListRole"
								:error-message="errors.swappTasksListRoleErrorMessage"
								:items="['Cleaner', 'Bar Staff', 'Kitchen Staff']"
								label="Role"
								v-model.trim="form.swappTasksListRole"/>
			</v-col>

			<!--Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								label="Description (optional)"
								v-model.trim="form.swappTasksListDescription"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn v-if="!isReadOnly"
					 @click.native="handleSaveItem"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "SwappTasksListForm",

	props: ['formData', 'isReadOnly', 'qrCodeParam', 'sitesData'],

	data: () => ({
		errors: {
			swappTasksListArea: false,
			swappTasksListAreaErrorMessage: '',
			swappTasksListLocation: false,
			swappTasksListLocationErrorMessage: '',
			swappTasksListName: false,
			swappTasksListNameErrorMessage: '',
			swappTasksListRole: false,
			swappTasksListRoleErrorMessage: '',
			swappTasksListSite: false,
			swappTasksListSiteErrorMessage: '',
		},
		form: {
			swappTasksListArea: '',
			swappTasksListDescription: '',
			swappTasksListIsFromLibrary: false,
			swappTasksListIsFromTemplate: true,
			swappTasksListLocation: '',
			swappTasksListName: '',
			swappTasksListQrId: '',
			swappTasksListRole: '',
			swappTasksListSite: '',
			swappTasksListStatus: 'Incomplete',
			swappTasksListTasksNotes: '',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		},
		displaySiteObject: {}, // Object representing the stored ID
	}),

	computed: {

		computedInit() {
			const t = this
			const FORM_DATA = {...t.$props.formData}
			const QR_CODE_PARAM = t.$props.qrCodeParam
			const SITES_DATA = t.$props.sitesData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA
				t.displaySiteObject = SITES_DATA.find(site => site.entityId === t.form.swappTasksListSite)
			} else {
				t.form.swappTasksListQrId = QR_CODE_PARAM
			}
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create Item
		 *
		 * Create the form and save to the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('swappTasksList', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Swapp Task List: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this List, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Created List'
			t.$emit('reloadData', RESPONSE.data)
		},

		/**
		 * Handle Save Item
		 *
		 * Handle whether to create or update the item to the DB, after it has passed validation.
		 */
		handleSaveItem() {
			const t = this

			// Only save if the form is valid
			if (!t.validateForm()) return

			// Store the Site ID from the object
			t.form.swappTasksListSite = t.displaySiteObject?.entityId

			// Create
			if (!t.form.entityId) t.createItem()

			// Update
			if (t.form.entityId) t.updateItem()
		},

		/**
		 * Update Item
		 *
		 * Update the form and save to the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('swappTasksList', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating List: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated List'
			// t.$emit('emitReloadPage')
			t.$emit('emitReloadData', RESPONSE.data)
		},

		/**
		 * Validate Form
		 *
		 * Validate the form and return true or false if it is valid, or not.
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// List Name
			if (!t.form.swappTasksListName) {
				t.errors.swappTasksListName = true
				t.errors.swappTasksListNameErrorMessage = 'Please enter a List Name'
			}

			// Site
			if (!t.displaySiteObject?.entityId) {
				t.errors.swappTasksListSite = true
				t.errors.swappTasksListSiteErrorMessage = 'Please select a Site'
			}

			// Area
			if (!t.form.swappTasksListArea) {
				t.errors.swappTasksListArea = true
				t.errors.swappTasksListAreaErrorMessage = 'Please enter an Area'
			}

			// Location
			if (!t.form.swappTasksListLocation) {
				t.errors.swappTasksListLocation = true
				t.errors.swappTasksListLocationErrorMessage = 'Please enter a Location'
			}

			// Role
			if (!t.form.swappTasksListRole) {
				t.errors.swappTasksListRole = true
				t.errors.swappTasksListRoleErrorMessage = 'Please select a Role'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
