<template>
	<div>

		<!--Header-->
		<page-title :divider="true"
					icon="accreditation"
					info="Manage your Accreditation Events."
					title="My Accreditation"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Event Name"
							style="width: 100%"
							v-model.trim="searchByEventName"/>

			<!--Add Button-->
			<!--<app-btn v-if="MIX_getCurrentUser().userLevel === 'Staff-Admin'"-->
			<!--		 @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>-->

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.entityId }}</app-text>
			</template>

			<!--Status-->
			<template v-slot:item.eventStatus="{item}">
				<app-icon v-if="item.accreditationEventData?.accreditationEventStatus === 'Live'"
						  color="green" icon="success" size="32" title="Live"/>
				<app-icon
					v-if="!item.accreditationEventData?.entityId || item.accreditationEventData?.accreditationEventStatus === 'Pending'"
					color="orange" icon="pending" size="32" title="Pending"/>
			</template>

			<!--Event Name-->
			<template v-slot:item.eventName="{item}">
				<app-text size="small">{{ item.eventData.eventName }}</app-text>
			</template>

			<!--Event Date-->
			<template v-slot:item.eventDate="{item}">
				<app-text size="small">{{ MIX_formatDate(item.eventData.eventDate, 'long') }}</app-text>
			</template>

			<!--Event Type-->
			<template v-slot:item.eventType="{item}">
				<app-text size="small">{{ item.eventData.eventType }}</app-text>
			</template>

			<!--Event Group-->
			<template v-slot:item.eventGroup="{item}">
				<app-text size="small">{{ item.eventData.eventGroup }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="handleEventSelection(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No data message-->
			<app-text v-if="!computedTableData.length"
					  class="text-center mt-4" color="grey9">You have no Notices to view
			</app-text>

			<!--Cards-->
			<my-accreditation-events-mobile-card v-for="(item, index) in computedTableData" :key="index"
												 @click.native="handleEventSelection(item)"
												 class="mt-4"
												 :card-data="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Statuses-->
				<page-break-title title="Statuses"/>
				<div class="mt-4"
					 style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
					<app-btn @click.native="handleFilterStatusSelection('Pending')"
							 :block="true"
							 color="orange"
							 label="Pending"
							 :outlined="!filterByStatuses.includes('Pending')"/>
					<app-btn @click.native="handleFilterStatusSelection('Live')"
							 :block="true"
							 color="green"
							 label="Live"
							 :outlined="!filterByStatuses.includes('Live')"/>
				</div>

				<!--Type-->
				<page-break-title class="mt-8" title="Type"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="eventTypeOptions"
									label="Types"
									:multiple="true"
									:small-chips="true"
									v-model="filterByTypes"/>
				</div>

			</div>
		</filter-panel>

	</div>
</template>

<script>

import MyAccreditationEventsMobileCard
	from "@/views/accreditation/organisation/myAccreditationEvents/myAccreditationEventsMobileCard/MyAccreditationEventsMobileCard.vue";

export default {

	name: "MyAccreditationEvents",

	components: {MyAccreditationEventsMobileCard},

	props: ['fullEventsData'],

	data: () => ({
		isFiltersPanelVisible: false,
		filterByStatuses: [],
		filterByTypes: [],

		isLoading: false,
		isReadOnly: false,
		moreActionsMenuOption: [
			{name: 'Templates', icon: ''},
			// {name: 'Export', icon: 'export'}
		],
		searchByEventName: '',
		searchByEventType: '',
		searchByEventGroup: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'id', align: 'left', sortable: false, hidden: true},
			{text: 'Status', value: 'eventStatus', align: 'center', sortable: false, width: '48px'},
			{text: 'Event', value: 'eventName', align: 'start', sortable: false},
			{text: 'Date & Start Time', value: 'eventDate', align: 'start', sortable: false},
			{text: 'Type', value: 'eventType', align: 'start', sortable: false},
			{text: 'Group', value: 'eventGroup', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'right', sortable: false, width: '48px'},
		],
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByStatuses.length,
				t.filterByTypes.length,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.$props.fullEventsData

			// Only show Live Accreditation Events
			tableData = tableData.filter(item => item.accreditationEventData?.accreditationEventStatus === 'Live')

			// Search by Event Name
			if (t.searchByEventName) {
				const SEARCH_CRITERIA = t.searchByEventName.toUpperCase()
				tableData = tableData.filter(item => {
					const EVENT_NAME = item.eventData.eventName.toUpperCase()
					return EVENT_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Status
			if (t.filterByStatuses.length) tableData = tableData.filter(item => t.filterByStatuses.includes(item.accreditationEventData?.accreditationEventStatus))

			// Filter by Type
			if (t.filterByTypes.length) tableData = tableData.filter(item => t.filterByTypes.includes(item.eventData.eventType))

			// Sort by Event Date
			tableData = tableData.sort((a, b) => a.eventData.eventDate > b.eventData.eventDate ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByStatuses = []
			t.filterByTypes = []
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'New') t.openRightPanel()
		},

		handleEventSelection(item) {
			const t = this

			t.selectedItem = item

			t.$emit('selectedEvent', item)
		},

		/**
		 * Handle Filter Status Selection
		 *
		 * Add or remove the selected status from the filterStatuses array.
		 *
		 * @param status {string} the status to add or remove
		 */
		handleFilterStatusSelection(status) {
			const t = this

			if (t.filterByStatuses.includes(status)) t.filterByStatuses = t.filterByStatuses.filter(s => s !== status)
			else t.filterByStatuses.push(status)
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

}
</script>

<style scoped>

</style>
