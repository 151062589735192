<template>
<div class="appGrey pa-4">

	<app-text>{{eventData.eventStaffInstructions}}</app-text>

	<v-divider class="mt-4"/>

	<app-btn @click.native="$emit('emitCloseEventAvailabilityCardStaffInstructionsDialog')"
		:block="true"
		class="mt-4"
		label="Close"/>

</div>
</template>

<script>
export default {

	name: "EventAvailabilityCardStaffInstructionsDialog",

	props: ['eventData'],

}
</script>

<style scoped>

</style>
