<template>
	<div class="appWhite d-flex align-center pa-4">

		<!--Card Details-->
		<div class="d-flex flex-column mr-4" style="width: 100%">

			<app-text color="primary" size="normal-bold">{{ cardData.swappTasksListName }}</app-text>

			<v-divider class="greyD my-2"/>

			<div class="d-flex">
				<app-text size="small">{{ cardData.swappTasksListLocation }}</app-text>
				<app-text class="mx-4" color="grey9" size="small">|</app-text>
				<app-text size="small">5 Tasks</app-text>
			</div>

		</div>

		<!--QR Code-->
		<app-icon color="primary" icon="qrCode" size="48"/>

	</div>
</template>

<script>

export default {

	name: "SwappTasksListMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
