/**
 * Shared State
 *
 * This is a simple object to share state around the app.
 * This is intended passing simple data around, like error codes etc.
 * This is not intended for use with large data or frequency use.
 *
 * To use for error messaging, just add to it:
 *  - t.$sharedState.errorMessage = 'auth/error1' and the ErrorMessage component will render.
 */
export default {
	errorMessage: '',
	successMessage: '',
}
