<template>
	<div>

		<!--Team View-->
		<div v-if="pageView === 'eventUser'">

			<!--Filterable Figures | Menu-->
			<div class="d-flex justify-space-between">

				<!--Back Button-->
				<app-btn @click.native="$emit('emitEventView', 'Status Selection')"
						  class="mr-4" color="appWhite" icon="arrowBack" icon-color="primary"/>

				<!--Filterable Figures-->
				<div class="appWhite text-center d-flex justify-space-around rounded-lg mr-4 pa-4"
					 style="width: 100%">

					<!--Signed In-->
					<app-text @click.native="filterByStatus = 'In'">
						<span class="font-weight-bold green--text">{{ getStatusFigures().NUMBER_IN }}</span> In
					</app-text>

					<v-divider class="mx-1" vertical/>

					<!--Arrived-->
					<app-text @click.native="filterByStatus = 'Arrived'">
						<span class="font-weight-bold orange--text">{{ getStatusFigures().NUMBER_ARRIVED }}</span>
						Arrived
					</app-text>

					<v-divider class="mx-1" vertical/>

					<!--Due-->
					<app-text @click.native="filterByStatus = 'Due'">
						<span class="font-weight-bold red--text">{{ getStatusFigures().NUMBER_DUE }}</span> Due
					</app-text>

				</div>

				<!--More Actions Menu-->
				<div>
				<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>
				</div>

			</div>

			<!--No Users Message-->
			<app-text v-if="!computedUsersFullEventData.length" class="text-center mt-4">
				There are no users to show
			</app-text>

			<!--Event Cards-->
			<v-expansion-panels v-for="item in computedUsersFullEventData" :key="item.eventUserData.entityId"
								class="rounded-lg mt-4" flat
								:style="`border-left: 16px solid ${getStatusColor()}`">
				<v-expansion-panel class="appWhite" flat>

					<!--Header-->
					<v-expansion-panel-header class="pa-0" hide-actions>
						<div class="rounded-l-lg pa-4">

							<!--Username-->
							<app-text size="normal-bold">{{ item.userData.userName }}</app-text>
							<app-text class="mt-2" color="grey" size="small">{{ item.userData.userInternalIdNumber }}
							</app-text>

							<!--Status-->
							<app-text class="mt-4" :color="getStatusColor()">{{ filterByStatus }}</app-text>

						</div>
					</v-expansion-panel-header>

					<!--Content-->
					<v-expansion-panel-content>

						<!--User Details-->
						<div>

							<!--Section Title-->
							<div class="d-flex align-center">
								<v-divider/>
								<app-text class="mx-2" color="grey" size="small">User Details</app-text>
								<v-divider/>
							</div>

							<!--Telephone-->
							<div class="d-flex align-start mt-2">
								<app-icon color="primary" icon="phone" size="16"/>
								<app-text class="ml-2">{{ item.userData.userTelephone }}</app-text>
							</div>

							<!--Email-->
							<div class="d-flex align-start mt-2">
								<app-icon color="primary" icon="email" size="16"/>
								<app-text class="ml-2">{{ item.userData.userEmail }}</app-text>
							</div>

						</div>

						<!--Shift Details-->
						<div class="mt-4">

							<!--Section Title-->
							<div class="d-flex align-center">
								<v-divider/>
								<app-text class="mx-2" color="grey" size="small">Shift Details</app-text>
								<v-divider/>
							</div>

							<!--Shift Start-->
							<div class="d-flex justify-space-between mt-2">
								<app-text>Shift Start</app-text>
								<app-text v-if="item.userData.userLevel === 'Steward-User'"
										  size="normal-bold">
									{{ item.eventTeamData.eventTeamStewardsStartTime }}
								</app-text>
								<app-text
										v-if="['Steward-Supervisor', 'Steward-Deputy'].includes(item.userData.userLevel)"
										size="normal-bold">
									{{ item.eventTeamData.eventTeamSupervisorsStartTime }}
								</app-text>
							</div>

							<!--Shift Finish-->
							<div class="d-flex justify-space-between mt-2">
								<app-text>Shift Finish</app-text>
								<app-text v-if="item.userData.userLevel === 'Steward-User'"
										  size="normal-bold">
									{{ item.eventTeamData.eventTeamStewardsFinishTime }}
								</app-text>
								<app-text
										v-if="['Steward-Supervisor', 'Steward-Deputy'].includes(item.userData.userLevel)"
										size="normal-bold">
									{{ item.eventTeamData.eventTeamSupervisorsFinishTime }}
								</app-text>
							</div>

							<!--Shift Length-->
							<div class="d-flex justify-space-between mt-2">
								<app-text>Shift Length</app-text>
								<app-text v-if="item.userData.userLevel === 'Steward-User'"
										  size="normal-bold">
									{{ MIX_formatTimeToHHMM(item.eventTeamData.eventTeamStewardsStartTime, item.eventTeamData.eventTeamStewardsFinishTime).displayTime
									}}
								</app-text>
								<app-text
										v-if="['Steward-Supervisor', 'Steward-Deputy'].includes(item.userData.userLevel)"
										size="normal-bold">
									{{ MIX_formatTimeToHHMM(item.eventTeamData.eventTeamSupervisorsStartTime, item.eventTeamData.eventTeamSupervisorsFinishTime).displayTime
									}}
								</app-text>
							</div>

						</div>

						<!--Event Details-->
						<div class="mt-4">

							<!--Section Title-->
							<div class="d-flex align-center">
								<v-divider/>
								<app-text class="mx-2" color="grey" size="small">Event Details</app-text>
								<v-divider/>
							</div>

							<!--No Show-->
							<div v-if="item.eventUserData.eventUserIsNoShow" class="d-flex justify-space-between mt-2">
								<app-text color="red">No Show</app-text>
							</div>

							<!--Late Arrival-->
							<div v-if="item.eventUserData.eventUserLateArrivalTime !== '0'"
								 class="d-flex justify-space-between mt-2">
								<app-text color="red">Late Arrival</app-text>
								<app-text color="red" size="normal-bold">
									{{ item.eventUserData.eventUserLateArrivalTime }}
								</app-text>
							</div>

							<!--SWAPPed In-->
							<div class="d-flex justify-space-between mt-2">
								<app-text color="orange">SWAPPed In</app-text>
								<app-text color="orange" size="normal-bold">
									{{ getUserSwappStatusAndTime(item).SWAPP_TIME }}
								</app-text>
							</div>

							<!--Signed In-->
							<div class="d-flex justify-space-between mt-2">
								<app-text color="green">Signed In</app-text>
								<app-text color="green" size="normal-bold">
									{{
										item.eventUserData.eventUserSignInTime === '0'
											? 'No'
											: item.eventUserData.eventUserSignInTime
									}}
								</app-text>
							</div>

							<!--Left Early-->
							<div v-if="item.eventUserData.eventUserEarlyFinishTime !== '0'"
								 class="d-flex justify-space-between mt-2">
								<app-text color="red">Left Early</app-text>
								<app-text color="red" size="normal-bold">
									{{ item.eventUserData.eventUserEarlyFinishTime }}
								</app-text>
							</div>

						</div>

						<!--Extra Event Details-->
						<div class="mt-4">

							<!--Section Title-->
							<div class="d-flex align-center">
								<v-divider/>
								<app-text class="mx-2" color="grey" size="small">Extra Event Details</app-text>
								<v-divider/>
							</div>

							<!--Extension-->
							<div class="d-flex justify-space-between mt-2">
								<app-text>Extension</app-text>
								<app-text v-if="!item.eventExtensionData?.entityId">None</app-text>
								<app-text v-else size="normal-bold">
									{{ item.eventExtensionData?.eventExtensionStartTime }} -
									{{ item.eventExtensionData?.eventExtensionFinishTime }}
								</app-text>
							</div>

							<!--Transfer-->
							<div class="d-flex justify-space-between mt-2">
								<app-text>Transfer</app-text>
								<app-text v-if="!item.eventTransferData?.entityId">None</app-text>
								<app-text v-else size="normal-bold">
									{{ item.eventTransferData?.eventTransferStartTime }} -
									{{ item.eventTransferData?.eventTransferFinishTime }}
								</app-text>
							</div>

						</div>

						<!--Action Buttons-->
						<div class="mt-4">

							<v-divider/>

							<!--SWAPP In-->
							<app-btn @click.native="handleSwappInManually(item, 'User')"
									 :block="true"
									 class="mt-4"
									 color="orange"
									 label="SWAPP In (Manually)"
									 label-color="white"/>

							<!--Sign In-->
							<app-btn @click.native="handleSignIn(item, 'User')"
									 :block="true"
									 class="mt-4"
									 color="green"
									 label="Sign In"
									 label-color="white"/>

							<!--No Show-->
							<app-btn @click.native="handleNoShow(item, 'User')"
									 :block="true"
									 class="mt-4"
									 color="red"
									 label="No Show"
									 label-color="white"/>

							<!--Late Arrival-->
							<app-btn @click.native="handleLateArrival(item, 'User')"
									 :block="true"
									 class="mt-4"
									 color="red"
									 label="Late Arrival"
									 label-color="white"/>

							<!--Early Finish-->
							<app-btn @click.native="handleEarlyFinish(item, 'User')"
									 :block="true"
									 class="mt-4"
									 color="red"
									 label="Early Finish"
									 label-color="white"/>

							<!--Extend Shift-->
							<app-btn @click.an.native="handleShiftExtension(item, 'User')"
									 :block="true"
									 class="mt-4"
									 color="grey"
									 label="Extend Shift"
									 label-color="white"/>

							<!--Transfer Away-->
							<app-btn @click.native="handleShiftTransfer(item, 'User')"
									 :block="true"
									 class="mt-4"
									 color="grey"
									 label="Transfer Away"
									 label-color="white"/>

						</div>

					</v-expansion-panel-content>

				</v-expansion-panel>
			</v-expansion-panels>

		</div>

		<!--Extensions View-->
		<div v-if="pageView === 'eventExtension'">

			<!--Title | Menu-->
			<div class="d-flex align-start justify-space-between">

				<!--Title-->
				<page-title info="View and manage shift extensions"
							title="Extensions"/>

				<!--More Actions Menu-->
				<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

			</div>

			<v-divider class="mt-4"/>

			<!--No Extensions message-->
			<app-text v-if="!computedExtensionsData.length" class="text-center mt-4">
				There are no Extensions to show
			</app-text>

			<!--Cards-->
			<v-expansion-panels v-for="item in computedExtensionsData" :key="item.eventExtensionData.entityId"
								class="rounded-lg mt-4" flat
								:style="`border-left: 16px solid ${getStatusColor(item)}`">
				<v-expansion-panel class="appWhite" flat>

					<!--Header-->
					<v-expansion-panel-header class="pa-0" hide-actions>
						<div class="rounded-l-lg pa-4">

							<!--Username-->
							<app-text size="normal-bold">{{ item.userData.userName }}</app-text>
							<app-text class="mt-2" color="grey" size="small">{{ item.userData.userInternalIdNumber }}
							</app-text>

							<!--Start & Finish Times-->
							<app-text class="mt-4">
								{{ item.eventExtensionData?.eventExtensionStartTime }}
								-
								{{ item.eventExtensionData?.eventExtensionFinishTime }}
							</app-text>

						</div>
					</v-expansion-panel-header>

					<!--Content-->
					<v-expansion-panel-content>

						<!--User Details-->
						<div>

							<!--Section Title-->
							<div class="d-flex align-center">
								<v-divider/>
								<app-text class="mx-2" color="grey" size="small">User Details</app-text>
								<v-divider/>
							</div>

							<!--Telephone-->
							<div class="d-flex align-start mt-2">
								<app-icon color="primary" icon="phone" size="16"/>
								<app-text class="ml-2">{{ item.userData.userTelephone }}</app-text>
							</div>

							<!--Email-->
							<div class="d-flex align-start mt-2">
								<app-icon color="primary" icon="email" size="16"/>
								<app-text class="ml-2">{{ item.userData.userEmail }}</app-text>
							</div>

						</div>

						<!--Shift Details-->
						<div class="mt-4">

							<!--Section Title-->
							<div class="d-flex align-center">
								<v-divider/>
								<app-text class="mx-2" color="grey" size="small">Extension Details</app-text>
								<v-divider/>
							</div>

							<!--Extension Start-->
							<div class="d-flex justify-space-between mt-2">
								<app-text>Extension Start</app-text>
								<app-text size="normal-bold">
									{{ item.eventExtensionData?.eventExtensionStartTime }}
								</app-text>
							</div>

							<!--Extension Finish-->
							<div class="d-flex justify-space-between mt-2">
								<app-text>Extension Finish</app-text>
								<app-text size="normal-bold">
									{{ item.eventExtensionData?.eventExtensionFinishTime }}
								</app-text>
							</div>

							<!--Shift Length-->
							<div class="d-flex justify-space-between mt-2">
								<app-text>Shift Length</app-text>
								<app-text size="normal-bold">
									{{
										MIX_formatTimeToHHMM(item.eventExtensionData?.eventExtensionStartTime, item.eventExtensionData?.eventExtensionFinishTime).displayTime
									}}
								</app-text>
							</div>

						</div>

						<!--Event Details-->
						<div class="mt-4">

							<!--Section Title-->
							<div class="d-flex align-center">
								<v-divider/>
								<app-text class="mx-2" color="grey" size="small">Event Details</app-text>
								<v-divider/>
							</div>

							<!--No Show-->
							<div v-if="item.eventExtensionData.eventExtensionIsNoShow"
								 class="d-flex justify-space-between mt-2">
								<app-text color="red">No Show</app-text>
							</div>

							<!--Late Arrival-->
							<div v-if="item.eventExtensionData.eventExtensionLateArrivalTime !== '0'"
								 class="d-flex justify-space-between mt-2">
								<app-text color="red">Late Arrival</app-text>
								<app-text color="red" size="normal-bold">
									{{ item.eventExtensionData.eventExtensionLateArrivalTime }}
								</app-text>
							</div>

							<!--SWAPPed In-->
							<div class="d-flex justify-space-between mt-2">
								<app-text color="orange">SWAPPed In</app-text>
								<app-text color="orange" size="normal-bold">
									{{ getUserSwappStatusAndTime(item).SWAPP_TIME }}
								</app-text>
							</div>

							<!--Signed In-->
							<div class="d-flex justify-space-between mt-2">
								<app-text color="green">Signed In</app-text>
								<app-text color="green" size="normal-bold">
									{{ item.eventExtensionData.eventExtensionSignInTime === '0' ? 'No' : item.eventExtensionData.eventExtensionSignInTime
									}}
								</app-text>
							</div>

							<!--Left Early-->
							<div v-if="item.eventExtensionData.eventExtensionEarlyFinishTime !== '0'"
								 class="d-flex justify-space-between mt-2">
								<app-text color="red">Left Early</app-text>
								<app-text color="red" size="normal-bold">
									{{ item.eventExtensionData.eventExtensionEarlyFinishTime }}
								</app-text>
							</div>

						</div>

						<!--Action Buttons-->
						<div class="mt-4">

							<v-divider/>

							<!--SWAPP In-->
							<app-btn @click.native="handleSwappInManually(item, 'Extension')"
									 :block="true"
									 class="mt-4"
									 color="orange"
									 label="SWAPP In (Manually)"/>

							<!--Sign In-->
							<app-btn @click.native="handleSignIn(item, 'Extension')"
									 :block="true"
									 class="mt-4"
									 color="green"
									 label="Sign In"/>

							<!--No Show-->
							<app-btn @click.native="handleNoShow(item, 'Extension')"
									 :block="true"
									 class="mt-4"
									 color="red"
									 label="No Show"/>

							<!--Late Arrival-->
							<app-btn @click.native="handleLateArrival(item, 'Extension')"
									 :block="true"
									 class="mt-4"
									 color="red"
									 label="Late Arrival"/>

							<!--Early Finish-->
							<app-btn @click.native="handleEarlyFinish(item, 'Extension')"
									 :block="true"
									 class="mt-4"
									 color="red"
									 label="Early Finish"/>

						</div>

					</v-expansion-panel-content>

				</v-expansion-panel>
			</v-expansion-panels>

		</div>

		<!--Transfers View-->
		<div v-if="pageView === 'eventTransfer'">

			<!--Title | Menu-->
			<div class="d-flex align-start justify-space-between">

				<!--Title-->
				<page-title info="View and manage user transfers"
							title="Transfers"/>

				<!--More Actions Menu-->
				<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

			</div>

			<!--Tabs-->
			<v-tabs class="rounded-lg ml-n4 mt-4" grow v-model="transferTabs" style="width: 100vw">
				<v-tab href="#transferIn">
					<app-text size="small">In</app-text>
				</v-tab>
				<v-tab href="#transferOut">
					<app-text size="small">Out</app-text>
				</v-tab>
			</v-tabs>

			<!--Tabs - Cards-->
			<v-tabs-items class="" v-model="transferTabs" style="overflow: visible">

				<!--Transfers In-->
				<v-tab-item class="appGrey" value="transferIn">

					<!--No Transfers message-->
					<app-text v-if="!computedTransfersData.TRANSFERS_IN.length" class="text-center mt-4">
						You have no incoming Transfers
					</app-text>

					<!--Cards-->
					<v-expansion-panels v-for="item in computedTransfersData.TRANSFERS_IN"
										:key="item.eventTransferData.entityId"
										class="rounded-lg mt-4" flat
										:style="`border-left: 16px solid ${getStatusColor(item)}`">
						<v-expansion-panel class="appWhite" flat>

							<!--Header-->
							<v-expansion-panel-header class="pa-0" hide-actions>
								<div class="rounded-l-lg pa-4">

									<!--Username-->
									<app-text size="normal-bold">{{ item.userData.userName }}</app-text>
									<app-text class="mt-2" color="grey" size="small">
										{{ item.userData.userInternalIdNumber }}
									</app-text>

									<!--Team Name-->
									<app-text class="mt-4">
										{{ getTeamNameById(item.eventTransferData.eventTransferTeamFrom) }}
									</app-text>

									<!--Start & Finish Times-->
									<app-text class="mt-4">
										{{ item.eventTransferData?.eventTransferStartTime }}
										-
										{{ item.eventTransferData?.eventTransferFinishTime }}
									</app-text>

								</div>
							</v-expansion-panel-header>

							<!--Content-->
							<v-expansion-panel-content>

								<!--User Details-->
								<div>

									<!--Section Title-->
									<div class="d-flex align-center">
										<v-divider/>
										<app-text class="mx-2" color="grey" size="small">User Details</app-text>
										<v-divider/>
									</div>

									<!--Telephone-->
									<div class="d-flex align-start mt-2">
										<app-icon color="primary" icon="phone" size="16"/>
										<app-text class="ml-2">{{ item.userData.userTelephone }}</app-text>
									</div>

									<!--Email-->
									<div class="d-flex align-start mt-2">
										<app-icon color="primary" icon="email" size="16"/>
										<app-text class="ml-2">{{ item.userData.userEmail }}</app-text>
									</div>

								</div>

								<!--Shift Details-->
								<div class="mt-4">

									<!--Section Title-->
									<div class="d-flex align-center">
										<v-divider/>
										<app-text class="mx-2" color="grey" size="small">Extension Details</app-text>
										<v-divider/>
									</div>

									<!--Transfer Start-->
									<div class="d-flex justify-space-between mt-2">
										<app-text>Extension Start</app-text>
										<app-text size="normal-bold">
											{{ item.eventTransferData?.eventTransferStartTime }}
										</app-text>
									</div>

									<!--Extension Finish-->
									<div class="d-flex justify-space-between mt-2">
										<app-text>Extension Finish</app-text>
										<app-text size="normal-bold">
											{{ item.eventTransferData?.eventTransferFinishTime }}
										</app-text>
									</div>

									<!--Shift Length-->
									<div class="d-flex justify-space-between mt-2">
										<app-text>Shift Length</app-text>
										<app-text size="normal-bold">
											{{
												MIX_formatTimeToHHMM(item.eventTransferData?.eventTransferStartTime, item.eventTransferData?.eventTransferFinishTime).displayTime
											}}
										</app-text>
									</div>

								</div>

								<!--Event Details-->
								<div class="mt-4">

									<!--Section Title-->
									<div class="d-flex align-center">
										<v-divider/>
										<app-text class="mx-2" color="grey" size="small">Event Details</app-text>
										<v-divider/>
									</div>

									<!--No Show-->
									<div v-if="item.eventTransferData.eventTransferIsNoShow"
										 class="d-flex justify-space-between mt-2">
										<app-text color="red">No Show</app-text>
									</div>

									<!--Late Arrival-->
									<div v-if="item.eventTransferData.eventTransferLateArrivalTime !== '0'"
										 class="d-flex justify-space-between mt-2">
										<app-text color="red">Late Arrival</app-text>
										<app-text color="red" size="normal-bold">
											{{ item.eventTransferData.eventTransferLateArrivalTime }}
										</app-text>
									</div>

									<!--SWAPPed In-->
									<div class="d-flex justify-space-between mt-2">
										<app-text color="orange">SWAPPed In</app-text>
										<app-text color="orange" size="normal-bold">
											{{ getUserSwappStatusAndTime(item).SWAPP_TIME }}
										</app-text>
									</div>

									<!--Signed In-->
									<div class="d-flex justify-space-between mt-2">
										<app-text color="green">Signed In</app-text>
										<app-text color="green" size="normal-bold">
											{{ item.eventTransferData.eventTransferSignInTime === '0' ? 'No' : item.eventTransferData.eventTransferSignInTime
											}}
										</app-text>
									</div>

									<!--Left Early-->
									<div v-if="item.eventTransferData.eventTransferEarlyFinishTime !== '0'"
										 class="d-flex justify-space-between mt-2">
										<app-text color="red">Left Early</app-text>
										<app-text color="red" size="normal-bold">
											{{ item.eventTransferData.eventTransferEarlyFinishTime }}
										</app-text>
									</div>

								</div>

								<!--Action Buttons-->
								<div class="mt-4">

									<v-divider/>

									<!--SWAPP In-->
									<app-btn @click.native="handleSwappInManually(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="orange"
											 label="SWAPP In (Manually)"
											 label-color="white"/>

									<!--Sign In-->
									<app-btn @click.native="handleSignIn(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="green"
											 label="Sign In"
											 label-color="white"/>

									<!--No Show-->
									<app-btn @click.native="handleNoShow(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="red"
											 label="No Show"
											 label-color="white"/>

									<!--Late Arrival-->
									<app-btn @click.native="handleLateArrival(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="red"
											 label="Late Arrival"
											 label-color="white"/>

									<!--Early Finish-->
									<app-btn @click.native="handleEarlyFinish(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="red"
											 label="Early Finish"
											 label-color="white"/>

								</div>

							</v-expansion-panel-content>

						</v-expansion-panel>
					</v-expansion-panels>

				</v-tab-item>

				<!--Transfers Out-->
				<v-tab-item class="appGrey" value="transferOut">

					<!--No Transfers message-->
					<app-text v-if="!computedTransfersData.TRANSFERS_OUT.length" class="text-center mt-4">
						You have no outgoing Transfers
					</app-text>

					<!--Cards-->
					<v-expansion-panels v-for="item in computedTransfersData.TRANSFERS_OUT"
										:key="item.eventTransferData.entityId"
										class="rounded-lg mt-4" flat
										:style="`border-left: 16px solid ${getStatusColor(item)}`">
						<v-expansion-panel class="appWhite" flat>

							<!--Header-->
							<v-expansion-panel-header class="pa-0">
								<div class="rounded-l-lg pa-4">

									<!--Username-->
									<app-text size="normal-bold">{{ item.userData.userName }}</app-text>
									<app-text class="mt-2" color="grey" size="small">
										{{ item.userData.userInternalIdNumber }}
									</app-text>

									<!--Team Name-->
									<app-text class="mt-4" size="small">
										{{ getTeamNameById(item.eventTransferData.eventTransferTeamTo) }}
									</app-text>

									<!--Start & Finish Times-->
									<app-text class="mt-2" size="small">
										{{ item.eventTransferData?.eventTransferStartTime }}
										-
										{{ item.eventTransferData?.eventTransferFinishTime }}
									</app-text>

								</div>
							</v-expansion-panel-header>

							<!--Content-->
							<v-expansion-panel-content>

								<!--User Details-->
								<div>

									<!--Section Title-->
									<div class="d-flex align-center">
										<v-divider/>
										<app-text class="mx-2" color="grey" size="small">User Details</app-text>
										<v-divider/>
									</div>

									<!--Telephone-->
									<div class="d-flex align-start mt-2">
										<app-icon color="primary" icon="phone" size="16"/>
										<app-text class="ml-2">{{ item.userData.userTelephone }}</app-text>
									</div>

									<!--Email-->
									<div class="d-flex align-start mt-2">
										<app-icon color="primary" icon="email" size="16"/>
										<app-text class="ml-2">{{ item.userData.userEmail }}</app-text>
									</div>

								</div>

								<!--Shift Details-->
								<div class="mt-4">

									<!--Section Title-->
									<div class="d-flex align-center">
										<v-divider/>
										<app-text class="mx-2" color="grey" size="small">Extension Details</app-text>
										<v-divider/>
									</div>

									<!--Transfer Start-->
									<div class="d-flex justify-space-between mt-2">
										<app-text>Extension Start</app-text>
										<app-text size="normal-bold">
											{{ item.eventTransferData?.eventTransferStartTime }}
										</app-text>
									</div>

									<!--Extension Finish-->
									<div class="d-flex justify-space-between mt-2">
										<app-text>Extension Finish</app-text>
										<app-text size="normal-bold">
											{{ item.eventTransferData?.eventTransferFinishTime }}
										</app-text>
									</div>

									<!--Shift Length-->
									<div class="d-flex justify-space-between mt-2">
										<app-text>Shift Length</app-text>
										<app-text size="normal-bold">
											{{
												MIX_formatTimeToHHMM(item.eventTransferData?.eventTransferStartTime, item.eventTransferData?.eventTransferFinishTime).displayTime
											}}
										</app-text>
									</div>

								</div>

								<!--Event Details-->
								<div class="mt-4">

									<!--Section Title-->
									<div class="d-flex align-center">
										<v-divider/>
										<app-text class="mx-2" color="grey" size="small">Event Details</app-text>
										<v-divider/>
									</div>

									<!--No Show-->
									<div v-if="item.eventTransferData.eventTransferIsNoShow"
										 class="d-flex justify-space-between mt-2">
										<app-text color="red">No Show</app-text>
									</div>

									<!--Late Arrival-->
									<div v-if="item.eventTransferData.eventTransferLateArrivalTime !== '0'"
										 class="d-flex justify-space-between mt-2">
										<app-text color="red">Late Arrival</app-text>
										<app-text color="red" size="normal-bold">
											{{ item.eventTransferData.eventTransferLateArrivalTime }}
										</app-text>
									</div>

									<!--SWAPPed In-->
									<div class="d-flex justify-space-between mt-2">
										<app-text color="orange">SWAPPed In</app-text>
										<app-text color="orange" size="normal-bold">
											{{ getUserSwappStatusAndTime(item).SWAPP_TIME }}
										</app-text>
									</div>

									<!--Signed In-->
									<div class="d-flex justify-space-between mt-2">
										<app-text color="green">Signed In</app-text>
										<app-text color="green" size="normal-bold">
											{{ item.eventTransferData.eventTransferSignInTime === '0' ? 'No' : item.eventTransferData.eventTransferSignInTime
											}}
										</app-text>
									</div>

									<!--Left Early-->
									<div v-if="item.eventTransferData.eventTransferEarlyFinishTime !== '0'"
										 class="d-flex justify-space-between mt-2">
										<app-text color="red">Left Early</app-text>
										<app-text color="red" size="normal-bold">
											{{ item.eventTransferData.eventTransferEarlyFinishTime }}
										</app-text>
									</div>

								</div>

								<!--Action Buttons-->
								<div class="mt-4">

									<v-divider/>

									<!--SWAPP In-->
									<app-btn @click.native="handleSwappInManually(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="orange"
											 label="SWAPP In (Manually)"
											 label-color="white"/>

									<!--Sign In-->
									<app-btn @click.native="handleSignIn(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="green"
											 label="Sign In"
											 label-color="white"/>

									<!--No Show-->
									<app-btn @click.native="handleNoShow(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="red"
											 label="No Show"
											 label-color="white"/>

									<!--Late Arrival-->
									<app-btn @click.native="handleLateArrival(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="red"
											 label="Late Arrival"
											 label-color="white"/>

									<!--Early Finish-->
									<app-btn @click.native="handleEarlyFinish(item, 'Transfer')"
											 :block="true"
											 class="mt-4"
											 color="red"
											 label="Early Finish"
											 label-color="white"/>

								</div>

							</v-expansion-panel-content>

						</v-expansion-panel>
					</v-expansion-panels>

				</v-tab-item>

			</v-tabs-items>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--SWAPP In Dialog-->
		<app-dialog :is-visible="isSwappDialogVisible" dialog-title="SWAPP In">
			<event-control-panel-swapp-dialog @emitCloseEventControlPanelSwappDialog="closeSwappDialog"
											  @emitEventControlPanelSwapp="emittedEventControlPanelSwapp"
											  :view-type="pageView"
											  :selectedItem="selectedItem"/>
		</app-dialog>

		<!--Sign In Dialog-->
		<app-dialog v-if="isSignDialogVisible" :is-visible="isSignDialogVisible"
					dialog-title="Sign In">
			<event-control-panel-sign-dialog @emitCloseEventControlPanelSignDialog="closeSignDialog"
											 @emitEventControlPanelSign="emittedEventControlPanelSign"
											 :selected-item="selectedItem"
											 :view-type="pageView"/>
		</app-dialog>

		<!--No Show Dialog-->
		<app-dialog v-if="isNoShowDialogVisible" :is-visible="isNoShowDialogVisible"
					dialog-title="No Show">
			<event-control-panel-no-show-dialog @emitCloseEventControlPanelNoShowDialog="closeNoShowDialog"
												@emitEventControlPanelNoShow="emittedEventControlPanelNoShow"
												:selected-item="selectedItem"
												:view-type="pageView"/>
		</app-dialog>

		<!--Late Arrival Dialog-->
		<app-dialog v-if="isLateArrivalDialogVisible" :is-visible="isLateArrivalDialogVisible"
					dialog-title="Late Arrival">
			<event-control-panel-late-arrival-dialog
					@emitCloseEventControlPanelLateArrivalDialog="closeLateArrivalDialog"
					@emitEventControlPanelLateArrival="emittedEventControlPanelLateArrival"
					:selectedItem="selectedItem"
					:view-type="pageView"/>
		</app-dialog>

		<!--Early Finish Dialog-->
		<app-dialog v-if="isEarlyFinishDialogVisible" :is-visible="isEarlyFinishDialogVisible"
					dialog-title="Early Finish">
			<event-control-panel-early-finish @emitCloseEventControlPanelEarlyFinishDialog="closeEarlyFinishDialog"
											  @emitEventControlPanelEarlyFinish="emittedEventControlPanelEarlyFinish"
											  :selectedItem="selectedItem"
											  :view-type="pageView"/>
		</app-dialog>

		<!--Shift Extension Dialog-->
		<app-dialog v-if="isShiftExtensionDialogVisible" :is-visible="isShiftExtensionDialogVisible"
					dialog-title="Shift Extension">
			<event-control-panel-extension-dialog
					@emitCloseEventControlPanelExtensionDialog="closeShiftExtensionDialog"
					@emitEventControlPanelShiftExtension="emittedEventControlPanelShiftExtension"
					:selectedItem="selectedItem"/>
		</app-dialog>

		<!--Shift Transfer Dialog-->
		<app-dialog v-if="isShiftTransferDialogVisible" :is-visible="isShiftTransferDialogVisible"
					dialog-title="Shift Transfer">
			<event-control-panel-transfer-dialog
					@emitCloseEventControlPanelTransferDialog="closeShiftTransferDialog"
					@emitEventControlPanelShiftTransfer="emittedEventControlPanelShiftTransfer"
					:selectedItem="selectedItem"/>
		</app-dialog>

	</div>
</template>

<script>
import DebriefForm from "@/views/reporting/debriefs/debriefForm/DebriefForm.vue";
import EventControlPanelEarlyFinish
	from "@/views/events/steward/eventControlPanel/eventControlPanelEarlyFinish/EventControlPanelEarlyFinish";
import EventControlPanelExtensionDialog
	from "@/views/events/steward/eventControlPanel/eventControlPanelExtensionDialog/EventControlPanelExtensionDialog.vue";
import EventControlPanelLateArrivalDialog
	from "@/views/events/steward/eventControlPanel/eventControlPanelLateArrivalDialog/EventControlPanelLateArrivalDialog";
import EventControlPanelNoShowDialog
	from "@/views/events/steward/eventControlPanel/eventControlPanelNoShowDialog/EventControlPanelNoShowDialog";
import EventControlPanelSignDialog
	from "@/views/events/steward/eventControlPanel/eventControlPanelSignDialog/EventControlPanelSignDialog";
import EventControlPanelSwappDialog
	from "@/views/events/steward/eventControlPanel/eventControlPanelSwappDialog/EventControlPanelSwappDialog";
import EventControlPanelTransferDialog
	from "@/views/events/steward/eventControlPanel/eventControlPanelTransferDialog/EventControlPanelTransferDialog.vue";

export default {

	name: "EventControlPanel",

	props: ['usersFullEventData'],

	components: {
		DebriefForm,
		EventControlPanelEarlyFinish,
		EventControlPanelExtensionDialog,
		EventControlPanelLateArrivalDialog,
		EventControlPanelNoShowDialog,
		EventControlPanelSignDialog,
		EventControlPanelSwappDialog,
		EventControlPanelTransferDialog
	},

	data: () => ({
		filterByStatus: 'In',
		isSwappDialogVisible: false,
		isSignDialogVisible: false,
		isNoShowDialogVisible: false,
		isLateArrivalDialogVisible: false,
		isEarlyFinishDialogVisible: false,
		isShiftExtensionDialogVisible: false,
		isShiftTransferDialogVisible: false,

		isDebriefReportDialogVisible: false,

		moreActionsMenuOption: [
			{name: 'Debrief Report', icon: ''},
			{name: 'Ejection Report', icon: ''},
			{name: 'View Team', icon: ''},
			{name: 'View Extensions', icon: ''},
			{name: 'View Transfers', icon: ''},
		],
		pageView: 'eventUser',
		selectedItem: {},
		shiftType: '',
		transferTabs: '',
	}),

	computed: {

		/**
		 * Users Full ~Event Data
		 *
		 * Return a filtered array of users full event data based on the filterByStatus ('In', 'Arrived', 'Due').
		 *
		 * @returns {*[]} - Array of users full event data.
		 */
		computedUsersFullEventData() {
			const t = this
			const USERS_FULL_EVENT_DATA = t.$props.usersFullEventData
			let filteredData = [...USERS_FULL_EVENT_DATA]

			if (t.filterByStatus === 'In') {
				filteredData = filteredData.filter(fd => fd.eventUserData.eventUserSignInTime !== '0')
			}

			if (t.filterByStatus === 'Arrived') {
				filteredData = filteredData.filter(fd => fd.eventUserData.eventUserSignInTime === '0' && fd.userData.userSwappStatus === 'In')
			}

			if (t.filterByStatus === 'Due') {
				filteredData = filteredData.filter(fd => fd.userData.userSwappStatus === 'Out')
			}

			return filteredData
		},

		/**
		 * Extensions Data
		 *
		 * Return an array of users full event data that have an event extension.
		 *
		 * @returns {*}
		 */
		computedExtensionsData() {
			const t = this
			const USERS_FULL_EVENT_DATA = t.$props.usersFullEventData

			return USERS_FULL_EVENT_DATA.filter(ufed => ufed.eventExtensionData?.entityId)
		},

		/**
		 * Transfers Data
		 *
		 * Return an object with two arrays of users full event data: Transfers In, and Transfers Out.
		 *
		 * @returns {{TRANSFERS_IN: *, TRANSFERS_OUT: *}}
		 */
		computedTransfersData() {
			const t = this
			const USERS_FULL_EVENT_DATA = t.$props.usersFullEventData
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()

			const TRANSFERS_IN = USERS_FULL_EVENT_DATA.filter(ufed =>
				ufed.eventTransferData?.entityId &&
				ufed.eventTransferData.eventTransferTeamTo === CURRENT_USER_DATA.userTeam)

			const TRANSFERS_OUT = USERS_FULL_EVENT_DATA.filter(ufed =>
				ufed.eventTransferData?.entityId &&
				ufed.eventTransferData.eventTransferTeamFrom === CURRENT_USER_DATA.userTeam)

			return {TRANSFERS_IN, TRANSFERS_OUT}
		},

	},

	methods: {

		/**
		 * Close EarlyFinish Dialog
		 *
		 * Close the dialog.
		 */
		closeEarlyFinishDialog() {
			const t = this

			t.isEarlyFinishDialogVisible = false
		},

		/**
		 * Close LateArrival Dialog
		 *
		 * Close the dialog.
		 */
		closeLateArrivalDialog() {
			const t = this

			t.isLateArrivalDialogVisible = false
		},

		/**
		 * Close NoShow Dialog
		 *
		 * Close the dialog.
		 */
		closeNoShowDialog() {
			const t = this

			t.isNoShowDialogVisible = false
		},

		/**
		 * Close ShiftExtension Dialog
		 *
		 * Close the dialog.
		 */
		closeShiftExtensionDialog() {
			const t = this

			t.isShiftExtensionDialogVisible = false
		},

		/**
		 * Close ShiftTransfer Dialog
		 *
		 * Close the dialog.
		 */
		closeShiftTransferDialog() {
			const t = this

			t.isShiftTransferDialogVisible = false
		},

		/**
		 * Close Sign Dialog
		 *
		 * Close the dialog.
		 */
		closeSignDialog() {
			const t = this

			t.isSignDialogVisible = false
		},

		/**
		 * Close SwappInManually Dialog
		 *
		 * Close the dialog.
		 */
		closeSwappDialog() {
			const t = this

			t.isSwappDialogVisible = false
		},

		async emittedEventControlPanelEarlyFinish(payload) {
			const t = this

			switch (t.shiftType) {
				case 'User':
					await t.eventControlPanelEarlyFinish('eventUser', 'eventUserEarlyFinishTime', 'eventUserEarlyFinishNotes', payload)
					break
				case 'Extension':
					await t.eventControlPanelEarlyFinish('eventExtension', 'eventExtensionEarlyFinishTime', 'eventExtensionEarlyFinishNotes', payload)
					break
				case 'Transfer':
					await t.eventControlPanelEarlyFinish('eventTransfer', 'eventTransferEarlyFinishTime', 'eventTransferEarlyFinishNotes', payload)
					break
				default:
					console.error('Invalid shiftType:', t.shiftType)
					break
			}
		},
		async eventControlPanelEarlyFinish(entityType, earlyFinishTimeField, earlyFinishNotesField, payload) {
			const t = this
			const EVENT_DATA = t.selectedItem[`${entityType}Data`]

			EVENT_DATA[earlyFinishTimeField] = payload.earlyFinishTime
			EVENT_DATA[earlyFinishNotesField] = payload.earlyFinishNotes

			const RESPONSE = await t.MIX_redis_update(entityType, EVENT_DATA.entityId, EVENT_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error(`Error updating ${entityType}:`, RESPONSE.error)
				return
			}

			t.closeEarlyFinishDialog()
		},

		async emittedEventControlPanelLateArrival(payload) {
			const t = this

			switch (t.shiftType) {
				case 'User':
					await t.eventControlPanelLateArrival('eventUser', 'eventUserLateArrivalTime', 'eventUserLateArrivalNotes', payload)
					break
				case 'Extension':
					await t.eventControlPanelLateArrival('eventExtension', 'eventExtensionLateArrivalTime', 'eventExtensionLateArrivalNotes', payload)
					break
				case 'Transfer':
					await t.eventControlPanelLateArrival('eventTransfer', 'eventTransferLateArrivalTime', 'eventTransferLateArrivalNotes', payload)
					break
				default:
					console.error('Invalid shiftType:', t.shiftType)
					break
			}
		},
		async eventControlPanelLateArrival(entityType, lateArrivalTimeField, lateArrivalNotesField, payload) {
			const t = this
			const EVENT_DATA = t.selectedItem[`${entityType}Data`]

			EVENT_DATA[lateArrivalTimeField] = payload.lateArrivalTime
			EVENT_DATA[lateArrivalNotesField] = payload.lateArrivalNotes

			const RESPONSE = await t.MIX_redis_update(entityType, EVENT_DATA.entityId, EVENT_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error(`Error updating ${entityType}:`, RESPONSE.error)
				return
			}

			t.closeLateArrivalDialog()
		},

		async emittedEventControlPanelNoShow(payload) {
			const t = this

			switch (t.shiftType) {
				case 'User':
					await t.eventControlPanelNoShow('eventUser', 'eventUserIsNoShow', true, 'eventUserIsNoShowNotes', payload)
					break
				case 'Extension':
					await t.eventControlPanelNoShow('eventExtension', 'eventExtensionIsNoShow', true, 'eventExtensionIsNoShowNotes', payload)
					break
				case 'Transfer':
					await t.eventControlPanelNoShow('eventTransfer', 'eventTransferIsNoShow', true, 'eventTransferIsNoShowNotes', payload)
					break
				default:
					console.error('Invalid shiftType:', t.shiftType)
					break
			}
		},
		async eventControlPanelNoShow(entityType, isNoShowField, isNoShow, noShowNotesField, payload) {
			const t = this
			const EVENT_DATA = t.selectedItem[`${entityType}Data`]

			EVENT_DATA[isNoShowField] = isNoShow
			EVENT_DATA[noShowNotesField] = payload.noShowNotes

			const RESPONSE = await t.MIX_redis_update(entityType, EVENT_DATA.entityId, EVENT_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error(`Error updating ${entityType}:`, RESPONSE.error)
				return
			}

			t.closeNoShowDialog()
		},

		async emittedEventControlPanelSign(newTime) {
			const t = this

			switch (t.shiftType) {
				case 'User':
					await t.eventControlPanelSign('eventUser', 'eventUserSignInTime', newTime)
					break
				case 'Extension':
					await t.eventControlPanelSign('eventExtension', 'eventExtensionSignInTime', newTime)
					break
				case 'Transfer':
					await t.eventControlPanelSign('eventTransfer', 'eventTransferSignInTime', newTime)
					break
				default:
					console.error('Invalid shiftType:', t.shiftType)
					break
			}
		},
		async eventControlPanelSign(entityType, signInTimeField, newSignInTime, swappStatusField) {
			const t = this
			const EVENT_DATA = t.selectedItem[`${entityType}Data`]

			// Set the new time
			EVENT_DATA[signInTimeField] = newSignInTime
			EVENT_DATA[swappStatusField] = 'In'

			const RESPONSE = await t.MIX_redis_update(entityType, EVENT_DATA.entityId, EVENT_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error(`Error updating ${entityType}:`, RESPONSE.error)
				return
			}

			await t.emittedEventControlPanelSwapp()

			t.closeSignDialog()
		},

		async emittedEventControlPanelSwapp() {
			const t = this
			const USER_DATA = t.selectedItem.userData

			await t.MIX_swappUser(USER_DATA, 'siteId', 'locationId', 'Supervisor', 'In')

			t.closeSwappDialog()
		},

		async emittedEventControlPanelShiftExtension(payload) {
			const t = this

			const RESPONSE = await t.MIX_redis_create('eventExtension', payload)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Event Extension: ', RESPONSE.error)
				return
			}

			t.$emit('emitRefreshData', payload.eventExtensionEventId)
			t.closeShiftExtensionDialog()
		},

		async emittedEventControlPanelShiftTransfer(payload) {
			const t = this

			const RESPONSE = await t.MIX_redis_create('eventTransfer', payload)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Event Transfer: ', RESPONSE.error)
				return
			}

			t.$emit('emitRefreshData', payload.eventTransferEventId)
			t.closeShiftTransferDialog()
		},

		/**
		 * Get Team Name By ID
		 *
		 * @description
		 * This method is used to get the team name from the teamId
		 *
		 * @param {string} teamId - The teamId to get the team name for
		 * @returns {string} - The team name
		 */
		getTeamNameById(teamId) {
			const t = this
			const TEAMS_DATA = t.$props.usersFullEventData[0].teamsData

			const TEAM = TEAMS_DATA.find(team => team.entityId === teamId)

			return TEAM?.teamName || ''
		},

		/**
		 * Get User Swapp Status and Time
		 *
		 * @description
		 * This method is used to get the User's Swapp Status and Time (from the user's data).
		 * If the User has not Swapped today, the time will be 'No'
		 * If the User has Swapped today, the time will be the time of the last Swapp (in the format: 12:00)
		 *
		 * @param userEventData - the full User Event data
		 * @returns {{SWAPP_STATUS: *, SWAPP_TIME: string}} - The User's Swapp Status and Time
		 */
		getUserSwappStatusAndTime(userEventData) {
			const t = this

			// If the User is not Swapped in, return the status and time
			const SWAPP_STATUS = userEventData.userData.userSwappStatus
			if (SWAPP_STATUS === 'Out') return {SWAPP_STATUS, SWAPP_TIME: 'No'}

			const LAST_SWAPP_DATE = new Date(userEventData.userData.userLastSwappDateTime)
			const HAS_SWAPPED_TODAY = LAST_SWAPP_DATE.toLocaleDateString() === new Date().toLocaleDateString()
			const SWAPP_TIME = HAS_SWAPPED_TODAY ? t.MIX_formatDateTimeToTime(userEventData.userData.userLastSwappDateTime) : 'No'

			return {SWAPP_STATUS, SWAPP_TIME}
		},

		/**
		 * Get Status Color
		 *
		 * Return the appropriate color for the User's status:
		 *  - Due = Red
		 *  - Swapped in (but not Signed in) = Orange
		 *  - Signed in = Green
		 *
		 * @param userEventData - the full User Event data
		 * @returns {string} the status color as a string
		 */
		getStatusColor(userEventData) {
			const t = this
			const PAGE_VIEW = t.pageView
			let statusColor

			// Set the status color
			if (PAGE_VIEW === 'eventUser') {
				if (t.filterByStatus === 'Due') statusColor = 'red'
				if (t.filterByStatus === 'Arrived') statusColor = 'orange'
				if (t.filterByStatus === 'In') statusColor = 'green'
			}

			// Set the status color
			if (['eventExtension', 'eventTransfer'].includes(PAGE_VIEW)) {
				// If the user is SWAPPed In...
				if (userEventData.userData.userSwappStatus === 'In') {
					// ...and if the user has a sign in time that is NOT 0
					if (userEventData[`${PAGE_VIEW}Data`][`${PAGE_VIEW}SignInTime`] && userEventData[`${PAGE_VIEW}Data`][`${PAGE_VIEW}SignInTime`] !== '0') {
						statusColor = 'green'
					}
					// ...and if the user hasn't signed in
					else {
						statusColor = 'orange'
					}
				}
				// If the user is NOT SWAPPed In
				else {
					statusColor = 'red'
				}
			}

			return statusColor
		},

		/**
		 * Get Status Figures
		 *
		 * Get the figures for the status.
		 * Reduce the usersFullEventData down, and return the number of users for each status.
		 *
		 * @returns {{NUMBER_DUE: number, NUMBER_IN: number, NUMBER_ARRIVED: number}} the number of users with the status
		 */
		getStatusFigures() {
			return this.usersFullEventData.reduce((acc, user) => {

				if (user.userData.userSwappStatus === 'In') {

					if (user.eventUserData.eventUserSignInTime && user.eventUserData.eventUserSignInTime !== '0') {

						acc.NUMBER_IN++

					} else {

						acc.NUMBER_ARRIVED++

					}

				} else if (user.userData.userSwappStatus === 'Out') {

					acc.NUMBER_DUE++

				}

				return acc
			}, {NUMBER_IN: 0, NUMBER_ARRIVED: 0, NUMBER_DUE: 0})
		},

		/**
		 * Handle Early Finish
		 *
		 * Set the selectedItem and shiftType, and open the early finish dialog.
		 *
		 * @param selectedItem
		 * @param shiftType
		 */
		handleEarlyFinish(selectedItem, shiftType) {
			const t = this

			t.selectedItem = selectedItem
			t.shiftType = shiftType

			t.openEarlyFinishDialog()
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Debrief Report') {
				t.MIX_addToLocalStorage('selectedReportType', 'Debrief')
				t.MIX_go('/reporting')
			}

			if (selection === 'Ejection Report') {
				t.MIX_addToLocalStorage('selectedReportType', 'Ejection')
				t.MIX_go('/reporting')
			}

			if (selection === 'View Team') t.pageView = 'eventUser'

			if (selection === 'View Extensions') t.pageView = 'eventExtension'

			if (selection === 'View Transfers') t.pageView = 'eventTransfer'
		},

		/**
		 * Handle Late Arrival
		 *
		 * Set the selectedItem and shiftType, and open the late arrival dialog.
		 *
		 * @param selectedItem
		 * @param shiftType
		 */
		handleLateArrival(selectedItem, shiftType) {
			const t = this

			t.selectedItem = selectedItem
			t.shiftType = shiftType

			t.openLateArrivalDialog()
		},

		/**
		 * Handle No Show
		 *
		 * Set the selectedItem and shiftType, and open the no show dialog.
		 *
		 * @param selectedItem
		 * @param shiftType
		 */
		handleNoShow(selectedItem, shiftType) {
			const t = this

			t.selectedItem = selectedItem
			t.shiftType = shiftType

			t.openNoShowDialog()
		},

		/**
		 * Handle Shift Extension
		 *
		 * Set the selectedItem and shiftType, and open the shift extension dialog.
		 *
		 * @param selectedItem
		 * @param shiftType
		 */
		handleShiftExtension(selectedItem, shiftType) {
			const t = this

			t.selectedItem = selectedItem
			t.shiftType = shiftType

			t.openShiftExtensionDialog()
		},

		/**
		 * Handle Shift Transfer
		 *
		 * Set the selectedItem and shiftType, and open the shift transfer dialog.
		 *
		 * @param selectedItem
		 * @param shiftType
		 */
		handleShiftTransfer(selectedItem, shiftType) {
			const t = this

			t.selectedItem = selectedItem
			t.shiftType = shiftType

			t.openShiftTransferDialog()
		},

		/**
		 * Handle Sign In
		 *
		 * Set the selectedItem and shiftType, and open the sign in dialog.
		 *
		 * @param selectedItem
		 * @param shiftType
		 */
		handleSignIn(selectedItem, shiftType) {
			const t = this

			t.selectedItem = selectedItem
			t.shiftType = shiftType

			t.openSignDialog()
		},

		/**
		 * Handle Swapp In
		 *
		 * Set the selectedItem and shiftType, and open the Swapp In dialog.
		 *
		 * @param selectedItem
		 * @param shiftType
		 */
		handleSwappInManually(selectedItem, shiftType) {
			const t = this

			t.selectedItem = selectedItem
			t.shiftType = shiftType

			t.openSwappInManuallyDialog()
		},

		/**
		 * Open EarlyFinish Dialog
		 *
		 * Open the dialog.
		 */
		openEarlyFinishDialog() {
			const t = this

			t.isEarlyFinishDialogVisible = true
		},

		/**
		 * Open LateArrival Dialog
		 *
		 * Open the dialog.
		 */
		openLateArrivalDialog() {
			const t = this

			t.isLateArrivalDialogVisible = true
		},

		/**
		 * Open NoShow Dialog
		 *
		 * Open the dialog.
		 */
		openNoShowDialog() {
			const t = this

			t.isNoShowDialogVisible = true
		},

		/**
		 * Open ShiftExtension Dialog
		 *
		 * Open the dialog.
		 */
		openShiftExtensionDialog() {
			const t = this

			t.isShiftExtensionDialogVisible = true
		},

		/**
		 * Open ShiftTransfer Dialog
		 *
		 * Open the dialog.
		 */
		openShiftTransferDialog() {
			const t = this

			t.isShiftTransferDialogVisible = true
		},

		/**
		 * Open Sign Dialog
		 *
		 * Open the dialog.
		 */
		openSignDialog() {
			const t = this

			t.isSignDialogVisible = true
		},

		/**
		 * Open SwappInManually Dialog
		 *
		 * Open the dialog.
		 */
		openSwappInManuallyDialog() {
			const t = this

			t.isSwappDialogVisible = true
		},

	},

}
</script>

<style scoped>

</style>
