<template>
	<div class="pa-4" style="height: calc(100% - 75px); display: grid; grid-template-rows: auto auto auto 1fr auto">

		{{ computedInit }}

		<app-text>
			Here, you can configure the event settings.
			An Event must be Live to enable users to apply for it.
		</app-text>

		<!--Event Visibility-->
		<div>
			<form-section-title class="mt-8"
								description="Control the public visibility of this event."
								title="Event Visibility"/>

			<!--Information-->
			<app-text class="">
				This Event is currently
				<span :class="getStatusColor()">{{ eventStatus }}</span>
			</app-text>

			<!--Status Selection-->
			<app-form-field form-type="select"
							class="mt-4"
							:items="['Pending', 'Live']"
							label=""
							v-model="form.accreditationEventStatus"/>

		</div>

		<!--Photo Requirement-->
		<div>
			<form-section-title class="mt-8"
								description="Specify whether users are required to have a profile picture for this event."
								title="Photo Requirement"/>

			<!--Information-->
			<app-text>
				Profile pictures for this event are <span :class="getPhotoColor()">{{ eventPhotoRequirement }}</span>
			</app-text>

			<!--Photo Requirement-->
			<app-form-field form-type="select"
							class="mt-4"
							label=""
							:items="['Required', 'Not Required']"
							v-model="form.accreditationEventPhotoRequirement"/>

		</div>

		<div></div>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn @click.native="handleSave"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>


	</div>
</template>

<script>

export default {

	name: "MediaAccreditationEventSettingsForm",

	props: ['selectedEvent'],

	data: () => ({
		eventPhotoRequirement: false,
		eventStatus: 'Pending',
		form: {
			accreditationEventEventId: '',
			accreditationEventOrganisations: [],
			accreditationEventPhotoRequirement: 'Not Required',
			accreditationEventStatus: 'Pending',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
		},
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this

			t.form = {...t.form, ...t.$props.selectedEvent.accreditationEventData}
			t.eventStatus = t.$props.selectedEvent.accreditationEventData.accreditationEventStatus
			t.eventPhotoRequirement = t.$props.selectedEvent.accreditationEventData.accreditationEventPhotoRequirement

		},

	},

	methods: {

		/**
		 * Get Photo Color
		 *
		 * Returns the color of the photo requirement text.
		 *
		 * @returns {string} - The color of the text
		 */
		getPhotoColor() {
			const t = this
			let color

			if (t.eventPhotoRequirement === 'Not Required') color = 'red--text'
			if (t.eventPhotoRequirement === 'Required') color = 'green--text'

			return color
		},

		/**
		 * Get Status Color
		 *
		 * Returns the color of the status text.
		 *
		 * @returns {string} - The color of the text
		 */
		getStatusColor() {
			const t = this
			let color

			if (t.eventStatus === 'Pending') color = 'red--text'
			if (t.eventStatus === 'Live') color = 'green--text'

			return color
		},

		/**
		 * Handle Save
		 *
		 * Reconstruct the fullEventData, and emit the data back to the parent.
		 */
		handleSave() {
			const t = this

			t.form.accreditationEventEventId = t.$props.selectedEvent?.eventData?.entityId

			const SELECTED_EVENT = {
				eventData: t.$props.selectedEvent.eventData,
				accreditationEventData: t.form,
			}

			t.$emit('saveEventSettings', SELECTED_EVENT)
		}

	},

}
</script>

<style scoped>

</style>
