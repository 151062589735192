<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else>

		<!--Information-->
		<app-text>
			Here, you can bulk create Users for your Organisation by uploading a CSV file of User's data, and a ZIP file
			of images.
			<br>
			<br>
			This will create an account for each User in the CSV file, and assign them to your Organisation.
			<br>
			<br>
			Please note, users will not need to log into their account unless they have been assigned as a Manager after
			their account has been created.
		</app-text>

		<v-divider class="greyD my-4"/>

		<!--CSV ---------------------------------------------------------------------------------------------------- -->

		<!--Instructions-->
		<app-text>
			1) - Create a CSV file with the following columns:
			<ul>
				<li>
					<code>userName</code> - The User's full name
				</li>
				<li>
					<code>userPosition</code> - The User's position in the Organisation
				</li>
				<li>
					<code>userEmail</code> - The User's email address
				</li>
				<li>
					<code>userTelephone</code> - The User's telephone number
				</li>
			</ul>
		</app-text>

		<!--Files-->
		<div class="d-flex mt-4">

			<!--Hidden-->
			<input @change="handleCSV"
				   accept=".csv"
				   ref="csvInput"
				   style="display: none"
				   type="file">

			<!--Visible-->
			<app-btn @click.native="onCsvClick"
					 :color="csvFile ? 'green' : 'primary'"
					 :icon="csvFile ? '' : 'add'"
					 :label="csvFile ? 'CSV File Added' : 'Add a CSV File'"/>

		</div>

		<v-divider class="greyD my-4"/>

		<!--ZIP ---------------------------------------------------------------------------------------------------- -->

		<!--Instructions-->
		<app-text>
			2) - Create a ZIP file of images, with each image's name being the User's email address (this must match the
			userEmail in the CSV file).
		</app-text>

		<!--Files-->
		<div class="d-flex mt-4">

			<!--Hidden-->
			<input @change="handleZIP"
				   accept=".zip"
				   ref="zipInput"
				   style="display: none"
				   type="file">

			<!--Visible-->
			<app-btn @click.native="onZipClick"
					 :color="zipFile ? 'green' : 'primary'"
					 :icon="zipFile ? '' : 'add'"
					 :label="zipFile ? 'ZIP File Added' : 'Add a ZIP File'"/>

		</div>

		<v-divider class="greyD my-4"/>

		<!--Upload ------------------------------------------------------------------------------------------------- -->

		<!--Upload Files-->
		<div class="d-flex justify-end mt-4">
			<app-btn @click.native="uploadFiles"
					 color="green"
					 :disabled="!csvFile || !zipFile"
					 label="Upload Files"/>
		</div>

		<v-dialog max-width="512" v-model="isResultsDialogVisible">

			<div class="appGrey rounded-lg pa-4">

				<!--Successful-->
				<div v-if="uploadResults?.successfulRecords?.length">

					<app-text size="normal-bold">
						{{ uploadResults.successfulRecords.length }} accounts were successfully created:
					</app-text>

					<div v-for="record in uploadResults.successfulRecords" :key="record.userEmail"
						 class="d-flex align-center mt-4">
						<app-icon class="mr-4" color="green" icon="check" size="24"/>
						<div>
							<app-text>{{ record.userName }}</app-text>
							<app-text color="grey9" size="small">{{ record.userEmail }}</app-text>
						</div>
					</div>

				</div>

				<!--Failed-->
				<div v-if="uploadResults?.failedRecords?.length">

					<app-text size="normal-bold">
						{{ uploadResults.failedRecords.length }} accounts were not created:
					</app-text>

					<div v-for="record in uploadResults.failedRecords" :key="record.userEmail"
						 class="d-flex align-center mt-4">
						<app-icon class="mr-4" color="red" icon="close" size="24"/>
						<div>
							<app-text>{{ record.userName }}</app-text>
							<app-text color="grey9" size="small">{{ record.userEmail }}</app-text>
							<app-text color="red" size="small">{{ record.error }}</app-text>
						</div>
					</div>

				</div>

				<v-divider class="mt-4"/>

				<!--Close Button-->
				<div class="mt-4">

					<app-btn @click.native="closeResultsDialog"
							 :block="true"
							 label="Close"/>

				</div>

			</div>

		</v-dialog>

	</div>

</template>

<script>

export default {

	name: "OrganisationBulkUserForm",

	props: ['formData'],

	data: () => ({
		csvFile: null,
		isLoading: false,
		isResultsDialogVisible: false,
		uploadResults: {},
		zipFile: null,
	}),

	methods: {

		/**
		 * Close Results Dialog
		 *
		 * Closes the results dialog and emits back to the parent to reload the page.
		 */
		closeResultsDialog() {
			const t = this

			t.isResultsDialogVisible = false

			t.$emit('emitReloadPage')
		},

		/**
		 * Handle CSV
		 *
		 * Handles the CSV file input.
		 * This is hidden, and is triggered by the onCsvClick method.
		 *
		 * @param event {Event} - The event
		 */
		handleCSV(event) {
			this.csvFile = event.target.files[0]
		},

		/**
		 * Handle ZIP
		 *
		 * Handles the ZIP file input.
		 * This is hidden, and is triggered by the onZipClick method.
		 *
		 * @param event {Event} - The event
		 */
		handleZIP(event) {
			this.zipFile = event.target.files[0]
		},

		/**
		 * On CSV Click
		 *
		 * Handles the CSV file input click.
		 * This is visible and triggers the hidden CSV input.
		 */
		onCsvClick() {
			this.$refs.csvInput.click()
		},

		/**
		 * On ZIP Click
		 *
		 * Handles the ZIP file input click.
		 * This is visible and triggers the hidden ZIP input.
		 */
		onZipClick() {
			this.$refs.zipInput.click()
		},

		/**
		 * Open Results Dialog
		 *
		 * Opens the results dialog.
		 */
		openResultsDialog() {
			this.isResultsDialogVisible = true
		},

		/**
		 * Upload Files
		 *
		 * Uploads the CSV and ZIP files to the server.
		 */
		async uploadFiles() {
			const t = this

			// Only continue if both files have been uploaded
			if (!t.csvFile || !t.zipFile) return

			t.isLoading = true

			// Construct the user config data
			const USER_CONFIG_DATA = {
				userOrganisation: t.$props.formData.organisationData.entityId,
				userType: 'Organisation',
				userRole: 'User',
				userLevel: 'Organisation-User'
			}

			// Construct a FormData object
			const formData = new FormData()
			formData.append('csv', this.csvFile)
			formData.append('zip', this.zipFile)
			formData.append('userConfigData', JSON.stringify(USER_CONFIG_DATA))

			const RESPONSE = await t.MIX_redis_bulkCreateUsers(formData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error bulk creating users: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem creating these users, please try again.'
				return
			}

			t.isLoading = false

			t.uploadResults = RESPONSE.data
			t.openResultsDialog()
		},

	},

}
</script>

<style scoped>

</style>
