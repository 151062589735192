<template>
	<div class="appGrey pa-4">

		<!--Available-->
		<app-text v-if="statusSelection === 'Available'">
			<app-text v-if="eventUserData.eventUserStatus === 'Available'">
				You have already registered as Available for this event
			</app-text>
			<div v-else>
				You are about to update your status for this event to say you are <strong>{{ statusSelection }}</strong>.
				<br>
				<br>
				This will inform your Supervisor so they can allocate you if/when you are required.
				<br>
				<br>
				<strong>Are you sure you want to do this?</strong>
			</div>
		</app-text>

		<!--Unavailable-->
		<app-text v-if="statusSelection === 'Unavailable'">
			You are about to update your status for this event to say you are <strong>{{ statusSelection }}</strong>.
			<br>
			<br>
			<span v-if="['Available', 'Confirmed', 'Reserved', 'Not Required'].includes(eventUserData.eventUserStatus)">
				As you have previously registered yourself as Available,
				you will be removed from this Event and you will need to start again should you change your mind.
			<br>
			<br>
			</span>
			<strong>Are you sure you want to do this?</strong>
		</app-text>


		<v-divider class="mt-4"/>

		<!--Action Buttons-->
		<div class="mt-4">

			<!--Only show if the user is marking themselves as Available when the are already Available-->
			<app-btn v-if="statusSelection === 'Available' && eventUserData.eventUserStatus === 'Available'"
					 @click.native="closeDialog"
					 :block="true"
					 label="Close"
					 style="width: 100%"/>

			<!--Yes | No-->
			<div v-else class="d-flex justify-space-between">

				<!--No-->
				<app-btn @click.native="closeDialog"
						 :block="true"
						 color="grey"
						 icon="cancel"
						 label="No"/>

				<!--Yes-->
				<app-btn @click.native="handleYesSelection"
						 color="green"
						 icon="success"
						 label="Yes"/>

			</div>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventAvailabilityCardStatusSelectionConfirmationDialog",

	props: ['eventUserData', 'statusSelection'],

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emit back to close the dialog.
		 */
		closeDialog() {
			const t = this

			t.$emit('emitCloseEventAvailabilityCardStatusSelectionConfirmationDialog')
		},

		/**
		 * Handle Yes Selection
		 *
		 * Emit back to update the status.
		 *
		 * @returns {Promise<void>}
		 */
		async handleYesSelection() {
			const t = this

			t.$emit('emitEventAvailabilityCardStatusSelectionConfirmation')
		},

	},

}
</script>

<style scoped>

</style>
