<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<!--Notice Details ------------------------------------------------------------------------------------- -->
			<form-section-title title="Notice Details"/>

			<!--Title-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:clearable="true"
								:counter="50"
								:disabled="isReadOnly"
								:error="errors.noticeTitle"
								:error-message="errors.noticeTitleErrorMessage"
								label="Title"
								:max-length="50"
								v-model.trim="form.noticeTitle"/>
			</v-col>

			<!--Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:clearable="true"
								:disabled="isReadOnly"
								:error="errors.noticeDescription"
								:error-message="errors.noticeDescriptionErrorMessage"
								label="Description"
								v-model.trim="form.noticeDescription"/>
			</v-col>

			<!--Priority-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:clearable="false"
								:disabled="isReadOnly"
								:error="errors.noticePriority"
								:error-message="errors.noticePriorityErrorMessage"
								:items="noticeBoardPriorityOptions"
								label="Priority"
								v-model="form.noticePriority"/>
			</v-col>

			<!--Visibility-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="select"
								:clearable="false"
								:disabled="isReadOnly"
								:items="noticeBoardVisibilityOptions"
								label="Visibility"
								v-model="form.noticeVisibility"/>
			</v-col>

			<!--Send To Details ------------------------------------------------------------------------------------ -->

			<form-section-title class="mt-8" title="Who's this for?"
								description="Select from the options below which users you want to be able to view this notice."/>

			<!--Checkboxes-->
			<div class="d-flex justify-space-around" style="width: 100%">
				<div v-for="item in ['Users', 'Levels', 'Types', 'All']" class="d-flex flex-column align-center">

					<!--Title-->
					<app-text>{{ item }}</app-text>

					<!--Checkbox-->
					<div @click="handleSendToTypeSelection(item)"
						 class="appWhite d-flex align-center justify-center rounded-lg mt-2"
						 style="height: 56px; width: 56px">
						<app-icon v-if="form.noticeSendToType === item"
								  color="green" :disabled="isReadOnly" icon="check" size="40"/>
					</div>

				</div>
			</div>
			<!--Checkbox - Error Message-->
			<app-text v-if="errors.noticeSendToType" class="text-center mt-2" color="red" size="small"
					  style="width: 100%">
				{{ errors.noticeSendToTypeErrorMessage }}
			</app-text>

			<!--Users-->
			<v-col v-if="form.noticeSendToType === 'Users'" :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="autoComplete"
								:clearable="true"
								:deletable-chips="true"
								:disabled="isReadOnly"
								:error="errors.noticeSendToListUsers"
								:error-message="errors.noticeSendToListUsersErrorMessage"
								:items="usersData"
								item-text="userName"
								label="Users"
								:multiple="true"
								:return-object="true"
								:small-chips="true"
								v-model="form.noticeSendToListUsers"/>
			</v-col>

			<!--User Levels-->
			<v-col v-if="form.noticeSendToType === 'Levels'" :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="autoComplete"
								:clearable="true"
								:deletable-chips="true"
								:disabled="isReadOnly"
								:error="errors.noticeSendToListLevels"
								:error-message="errors.noticeSendToListLevelsErrorMessage"
								:items="computedUserLevels"
								label="User Levels"
								:multiple="true"
								:small-chips="true"
								v-model="form.noticeSendToListLevels"/>
			</v-col>

			<!--User Types-->
			<v-col v-if="form.noticeSendToType === 'Types'" :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="autoComplete"
								:clearable="true"
								:deletable-chips="true"
								:disabled="isReadOnly"
								:error="errors.noticeSendToListTypes"
								:error-message="errors.noticeSendToListTypesErrorMessage"
								:items="computedUserTypes"
								label="User Types"
								:multiple="true"
								:small-chips="true"
								v-model="form.noticeSendToListTypes"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div v-if="!isReadOnly" class="d-flex justify-end mt-4">
			<app-btn @click.native="handleSaveItem"
					 color="green"
					 icon="save"
					 label="Save"
					 style="width: fit-content"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "NoticeBoardForm",

	props: ['formData', 'isReadOnly', 'usersData'],

	data: () => ({
		errors: {
			noticeDescription: false,
			noticeDescriptionErrorMessage: '',
			noticeSendToListUsers: false,
			noticeSendToListUsersErrorMessage: '',
			noticeSendToListLevels: false,
			noticeSendToListLevelsErrorMessage: '',
			noticeSendToListTypes: false,
			noticeSendToListTypesErrorMessage: '',
			noticeSendToType: false,
			noticeSendToTypeErrorMessage: '',
			noticeTitle: false,
			noticeTitleErrorMessage: '',
			noticePriority: false,
			noticePriorityErrorMessage: '',
		},
		form: {
			noticeDescription: '',
			noticeSendToListLevels: [],
			noticeSendToListTypes: [],
			noticeSendToListUsers: [],
			noticeSendToType: '',
			noticeTitle: '',
			noticePriority: '',
			noticeVisibility: 'Visible',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},

		userGroupOptions: ['Staff-Admin', 'Staff-User', 'Steward-Supervisor', 'Steward-Deputy', 'Steward-User'],
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData
			const USERS_DATA = t.$props.usersData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA

				// Set the Users data from their IDs
				USERS_DATA.forEach(user => {
					if (FORM_DATA.noticeSendToListUsers.includes(user.entityId)) t.form.noticeSendToListUsers.push(user)
				})
			}
		},

		computedUserLevels() {
			const t = this
			const USER_LEVELS = t.usersData.map(user => user.userLevel)
			return USER_LEVELS.sort((a, b) => a > b ? 1 : -1)
		},

		computedUserTypes() {
			const t = this
			const USER_TYPES = t.usersData.map(user => user.userType)
			return USER_TYPES.sort((a, b) => a > b ? 1 : -1)
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create Item
		 *
		 * Create the item in the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('noticeBoard', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Notice Board: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem creating the Notice Board, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Created Notice'

			t.$emit('emitReloadPage')
		},

		/**
		 * Handle Save Item
		 *
		 * Create/Update the item in the DB, after the form has passed validation.
		 */
		handleSaveItem() {
			const t = this

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			// Empty the List that are not selected
			if (t.form.noticeSendToType !== 'Users') t.form.noticeSendToListUsers = []
			if (t.form.noticeSendToType !== 'Levels') t.form.noticeSendToListLevels = []
			if (t.form.noticeSendToType !== 'Types') t.form.noticeSendToListTypes = []

			// If the selected SendTo Type is Users, just save the IDs
			if (t.form.noticeSendToType === 'Users') t.form.noticeSendToListUsers = t.form.noticeSendToListUsers.map(user => user.entityId)

			if (!t.form?.entityId) t.createItem()
			else t.updateItem()
		},

		/**
		 * Handle Send To Type Selection
		 *
		 * When a user selects a SendTo Type, assign it and clear the SendTo List.
		 *
		 * @param item - The selected SendTo Type
		 */
		handleSendToTypeSelection(item) {
			const t = this

			// If the form is read only, don't allow changes
			if (t.$props.isReadOnly) return

			t.form.noticeSendToType = item
		},

		/**
		 * Update Item
		 *
		 * Update the item in the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('noticeBoard', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Notice Board: ', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem updating the Notice Board, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Notice'

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Title
			if (!t.form.noticeTitle) {
				t.errors.noticeTitle = true
				t.errors.noticeTitleErrorMessage = 'A Title is required.'
			}

			// Description
			if (!t.form.noticeDescription) {
				t.errors.noticeDescription = true
				t.errors.noticeDescriptionErrorMessage = 'A Description is required.'
			}

			// Priority
			if (!t.form.noticePriority) {
				t.errors.noticePriority = true
				t.errors.noticePriorityErrorMessage = 'A Priority is required.'
			}

			// Send To Type
			if (!t.form.noticeSendToType) {
				t.errors.noticeSendToType = true
				t.errors.noticeSendToTypeErrorMessage = 'A Send To Type is required.'
			}

			// Send to List Users
			if (t.form.noticeSendToType === 'Users' && !t.form.noticeSendToListUsers.length) {
				t.errors.noticeSendToListUsers = true
				t.errors.noticeSendToListUsersErrorMessage = 'You must select at least one user'
			}

			// Send to List Levels
			if (t.form.noticeSendToType === 'Levels' && !t.form.noticeSendToListLevels.length) {
				t.errors.noticeSendToListLevels = true
				t.errors.noticeSendToListLevelsErrorMessage = 'You must select at least one user level'
			}

			// Send to List Types
			if (t.form.noticeSendToType === 'Types' && !t.form.noticeSendToListTypes.length) {
				t.errors.noticeSendToListTypes = true
				t.errors.noticeSendToListTypesErrorMessage = 'You must select at least one user type'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
