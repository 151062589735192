<template>
	<div class="appGrey">

		{{ computedInit }}

		<!--Form-->
		<v-row no-gutters>

			<!--Car Park Details ----------------------------------------------------------------------------------- -->
			<form-section-title title="Car Park Details"/>

			<!--Car Park Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '12'">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.carParkName"
								:error-message="errors.carParkNameErrorMessage"
								label="Name"
								v-model.trim="form.carParkName"/>
			</v-col>

			<!--Status-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
				<app-form-field form-type="select"
								:disabled="isReadOnly"
								:items="['Open', 'Closed']"
								label="Status"
								v-model.trim="form.carParkStatus"/>
			</v-col>

			<!--Spaces --------------------------------------------------------------------------------------------- -->
			<form-section-title class="mt-8"
								description="Specify the number of regular, accessible, and reserved spaces (un)available. Reserved Spaces can be created here as defaults, but can be overridden on an Event basis when creating Parking Spaces."
								title="Spaces"/>

			<!--Spaces Error-->
			<app-text class="mb-4" color="red" size="small">{{ errors.carParkNoSelectionErrorMessage }}</app-text>

			<!--Regular Spaces-->
			<div class="d-flex align-center" style="width: 100%">
				<app-icon color="primary" icon="car" size="48"/>
				<app-text class="mx-4" style="min-width: 80px; width: 80px">Regular Spaces</app-text>

				<v-row no-gutters>

					<!--Regular Spaces - Available -->
					<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pr-2'"
						   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
						<app-form-field form-type="textInput"
										:disabled="isReadOnly"
										:error="errors.carParkRegularSpacesAvailable"
										:error-message="errors.carParkRegularSpacesAvailableErrorMessage"
										label="Available"
										type="number"
										v-model.number.trim="form.carParkRegularSpacesAvailable"/>
					</v-col>

					<!--Regular Spaces - Out of Action -->
					<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
						   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
						<app-form-field form-type="textInput"
										:disabled="isReadOnly"
										:error="errors.carParkRegularSpacesOutOfAction"
										:error-message="errors.carParkRegularSpacesOutOfActionErrorMessage"
										label="Out of Action"
										type="number"
										v-model.number.trim="form.carParkRegularSpacesOutOfAction"/>
					</v-col>

				</v-row>
			</div>

			<!--Disabled Spaces-->
			<div class="d-flex align-center mt-4" style="width: 100%">
				<app-icon color="primary" icon="disabled" size="48"/>
				<app-text class="mx-4" style="min-width: 80px; width: 80px">Accessible Spaces</app-text>

				<v-row no-gutters>

					<!--Regular Spaces - Available -->
					<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pr-2'"
						   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
						<app-form-field form-type="textInput"
										:disabled="isReadOnly"
										:error="errors.carParkDisabledSpacesAvailable"
										:error-message="errors.carParkDisabledSpacesAvailableErrorMessage"
										label="Available"
										type="number"
										v-model.number.trim="form.carParkDisabledSpacesAvailable"/>
					</v-col>

					<!--Regular Spaces - Out of Action -->
					<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
						   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
						<app-form-field form-type="textInput"
										:disabled="isReadOnly"
										:error="errors.carParkDisabledSpacesOutOfAction"
										:error-message="errors.carParkDisabledSpacesOutOfActionErrorMessage"
										label="Out of Action"
										type="number"
										v-model.number.trim="form.carParkDisabledSpacesOutOfAction"/>
					</v-col>

				</v-row>
			</div>

			<!--Reserved Spaces-->
			<div class="d-flex flex-column" style="width: 100%">

				<!--Icon | Title | Input-->
				<div class="d-flex align-center mt-4">

					<app-icon color="primary" icon="reservedParking" size="48"/>
					<app-text class="mx-4" style="min-width: 80px; width: 80px">Reserved Spaces</app-text>

					<!--Input | Label-->
					<div class="d-flex align-center" style="width: 100%">

						<!--Input-->
						<app-form-field form-type="textInput"
										class="text-center mr-4"
										:disabled="isReadOnly"
										style="min-width: 56px; max-width: 56px"
										type="number"
										v-model.number="form.carParkNumberOfReservedSpaces"/>

						<!--Label | Error Message-->
						<div class="d-flex flex-column">

						<!--Label-->
						<app-text size="small">Reserved Spaces in this Car Park</app-text>

						<!--Error Message-->
						<app-text color="red" size="small">
							{{ errors.carParkNumberOfReservedSpacesErrorMessage }}
						</app-text>

						</div>

					</div>

				</div>

				<!--Reserved Spaces - List-->
				<v-expansion-panels v-if="form.carParkNumberOfReservedSpaces > 0" class="mt-4" flat>
					<v-expansion-panel class="appWhite rounded-lg">

						<!--Header-->
						<v-expansion-panel-header hide-actions>
							<page-break-title title="Manage Spaces"/>
						</v-expansion-panel-header>

						<!--Content-->
						<v-expansion-panel-content>

							<!--No Reserved Spaces message-->
							<app-text v-if="(!form.carParkNumberOfReservedSpaces || form.carParkNumberOfReservedSpaces < 1)
											&& !form.carParkReservedList.length" class="text-center" color="grey9">
								You have no Reserved Spaces to view
							</app-text>

							<!--Error Messages-->
							<app-text class="text-center" color="red" size="small"
									  v-html="errors.reservedSpaceMissingDataErrorMessage"/>
							<app-text class="text-center" color="red" size="small"
									  v-html="errors.reservedSpaceInvalidEmailErrorMessage"/>

							<!--Rows-->
							<div v-for="(item, index) in computedRows" :key="index"
								 class="d-flex align-center mt-4">

								<!--Number-->
								<app-text class="mr-4" size="normal-bold">{{ item.reservedNumber }}</app-text>

								<v-row no-gutters>

									<!--Name-->
									<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
										   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
										<app-form-field form-type="textInput"
														background-color="appGrey"
														:disabled="isReadOnly"
														label="Name"
														v-model.trim="item.reservedName"/>
									</v-col>

									<!--Email Address-->
									<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pl-2'"
										   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
										<app-form-field form-type="textInput"
														background-color="appGrey"
														:disabled="isReadOnly"
														label="Email"
														v-model.trim="item.reservedEmail"/>
									</v-col>

								</v-row>

							</div>

						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>

			</div>

			<!--Description ---------------------------------------------------------------------------------------- -->
			<form-section-title class="mt-8" title="Description (optional)"/>

			<!--Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '12'">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								label="Description"
								v-model.trim="form.carParkDescription"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div v-if="!isReadOnly" class="d-flex justify-end mt-4">
				<app-btn @click.native="handleSaveItem"
						 color="green"
						 icon="save"
						 label="Save"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "ParkingCarParkForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		errors: {
			carParkDisabledSpacesAvailable: false,
			carParkDisabledSpacesAvailableErrorMessage: '',
			carParkDisabledSpacesOutOfAction: false,
			carParkDisabledSpacesOutOfActionErrorMessage: '',
			carParkName: false,
			carParkNameErrorMessage: '',
			carParkNoSelection: false,
			carParkNoSelectionErrorMessage: '',
			carParkNumberOfReservedSpaces: false,
			carParkNumberOfReservedSpacesErrorMessage: '',
			carParkRegularSpacesAvailable: false,
			carParkRegularSpacesAvailableErrorMessage: '',
			carParkRegularSpacesOutOfAction: false,
			carParkRegularSpacesOutOfActionErrorMessage: '',
			carParkStatus: false,
			carParkStatusErrorMessage: '',
			reservedSpaceInvalidEmail: false,
			reservedSpaceInvalidEmailErrorMessage: '',
			reservedSpaceMissingData: false,
			reservedSpaceMissingDataErrorMessage: '',
		},
		form: {
			carParkDescription: '',
			carParkDisabledSpacesAvailable: 0,
			carParkDisabledSpacesOutOfAction: 0,
			carParkName: '',
			carParkNumberOfReservedSpaces: 0,
			carParkRegularSpacesAvailable: 0,
			carParkRegularSpacesOutOfAction: 0,
			carParkReservedList: [],
			carParkStatus: 'Open',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
		},

	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA

				// If the carParkReservedList is a string, convert it to an array. Otherwise, it is already an array.
				if (typeof FORM_DATA.carParkReservedList === 'string') t.form.carParkReservedList = JSON.parse(FORM_DATA.carParkReservedList)
				else t.form.carParkReservedList = FORM_DATA.carParkReservedList
			}

		},

		/**
		 * Computed Rows
		 *
		 * Create an array of rows for the reserved spaces.
		 *
		 * @returns {*[]}
		 */
		computedRows() {
			const t = this
			const rows = []

			const LOOP_LENGTH = t.form.carParkNumberOfReservedSpaces
			for (let i = 0; i < LOOP_LENGTH; i++) {
				const item = t.form.carParkReservedList[i]
				rows.push({
					reservedNumber: i + 1,
					reservedName: item?.reservedName,
					reservedEmail: item?.reservedEmail
				})
			}

			return rows
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create Item
		 *
		 * Create a new Parking document in the DB.
		 */
		async createItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_create('carPark', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Car Park: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Created Car Park'

			t.$emit('emitReloadPage')
		},

		/**
		 * Handle Save Item
		 *
		 * Handle the save button click, and either create or update the document after validation.
		 */
		handleSaveItem() {
			const t = this

			// Validate the form first
			if (!t.validateForm()) return

			// Update the form's carParkReservedList array with the values from computedRows
			t.form.carParkReservedList = t.computedRows.map(item => ({
				reservedNumber: item.reservedNumber,
				reservedName: item.reservedName,
				reservedEmail: item.reservedEmail,
			}))

			// Convert the carParkReservedList array to a string
			t.form.carParkReservedList = JSON.stringify(t.form.carParkReservedList)

			// Create
			if (!t.form.entityId) t.createItem()

			// Update
			if (t.form.entityId) t.updateItem()
		},

		/**
		 * Update Item
		 *
		 * Update the document in the DB.
		 */
		async updateItem() {
			const t = this

			const RESPONSE = await t.MIX_redis_update('carPark', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Car Park: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated Car Park'

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Car Park Name
			if (!t.form.carParkName) {
				t.errors.carParkName = true
				t.errors.carParkNameErrorMessage = 'Car Park Name is required'
			}

			// Regular Spaces - Available
			if (t.form.carParkRegularSpacesAvailable < 0 || !Number.isInteger(t.form.carParkRegularSpacesAvailable)) {
				t.errors.carParkRegularSpacesAvailable = true
				t.errors.carParkRegularSpacesAvailableErrorMessage = 'Must be 0 or a positive whole number'
			}

			// Regular Spaces - Out of Action
			if (t.form.carParkRegularSpacesOutOfAction < 0 || !Number.isInteger(t.form.carParkRegularSpacesOutOfAction)) {
				t.errors.carParkRegularSpacesOutOfAction = true
				t.errors.carParkRegularSpacesOutOfActionErrorMessage = 'Must be 0 or a positive whole number'
			}

			// Disabled Spaces - Available
			if (t.form.carParkDisabledSpacesAvailable < 0 || !Number.isInteger(t.form.carParkDisabledSpacesAvailable)) {
				t.errors.carParkDisabledSpacesAvailable = true
				t.errors.carParkDisabledSpacesAvailableErrorMessage = 'Must be 0 or a positive whole number'
			}

			// Disabled Spaces - Out of Action
			if (t.form.carParkDisabledSpacesOutOfAction < 0 || !Number.isInteger(t.form.carParkDisabledSpacesOutOfAction)) {
				t.errors.carParkDisabledSpacesOutOfAction = true
				t.errors.carParkDisabledSpacesOutOfActionErrorMessage = 'Must be 0 or a positive whole number'
			}

			// Number of Reserved Spaces
			if (t.form.carParkNumberOfReservedSpaces < 0 || !Number.isInteger(t.form.carParkNumberOfReservedSpaces)) {
				t.errors.carParkNumberOfReservedSpaces = true
				t.errors.carParkNumberOfReservedSpacesErrorMessage = 'Must be 0 or a positive whole number'
			}

			// Reserved Spaces
			// If any of the reserved spaces have a name without an email (or vice versa),
			// or if any of the reserved spaces have an invalid email
			let rowsWithMissingData = []
			let rowsWithInvalidEmail = []
			t.computedRows.forEach((row, index) => {

				// If there is a name without an email, or vice versa
				if ((row.reservedName && !row.reservedEmail) || (!row.reservedName && row.reservedEmail)) {
					rowsWithMissingData.push(index + 1)
					t.errors.reservedSpaceMissingData = true
					t.errors.reservedSpaceMissingDataErrorMessage = `Name and Email are both required for a Reserved Space <br>(rows ${rowsWithMissingData.join(', ')})</br>`
				}

				// If there is a name and email, but the email is invalid
				if (row.reservedName && row.reservedEmail) {
					if (!t.MIX_isEmailValid(row.reservedEmail)) {
						rowsWithInvalidEmail.push(index + 1)
						t.errors.reservedSpaceInvalidEmail = true
						t.errors.reservedSpaceInvalidEmailErrorMessage = `A valid Email is required for a Reserved Space <br>(rows ${rowsWithInvalidEmail.join(', ')})</br>`
					}
				}

			})

			// If there are no Available or Out of Action, for both Regular and Disabled
			if (t.form.carParkNumberOfReservedSpaces === 0 &&
				t.form.carParkRegularSpacesAvailable === 0 &&
				t.form.carParkRegularSpacesOutOfAction === 0 &&
				t.form.carParkDisabledSpacesAvailable === 0 &&
				t.form.carParkDisabledSpacesOutOfAction === 0
			) {
				t.errors.carParkNoSelection = true
				t.errors.carParkNoSelectionErrorMessage = 'You must enter at least one space'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
