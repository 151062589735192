<template>
	<div class="appWhite d-flex align-center rounded-lg pa-4">

		<div class="d-flex flex-column justify-space-between mr-4" style="height: 100%">
		<app-text v-if="cardData.passData?.accreditationPassStatus === 'Not Applied'" color="greyD">N/A
		</app-text>
		<app-icon v-else
				  :color="getStatusIconConfig(cardData.passData?.accreditationPassStatus).color"
				  :icon="getStatusIconConfig(cardData.passData?.accreditationPassStatus).icon"
				  size="32"
				  :title="cardData.passData?.accreditationPassStatus"/>

		<app-icon :color="cardData.passData?.accreditationPassPrinted ? 'green' : 'greyD'"
				  class="mt-4"
				  icon="print"
				  size="32"
				  :title="cardData.passData?.accreditationPassPrinted ? 'Printed' : 'Not Printed'"/>
		</div>

		<div style="width: 100%">

			<app-text color="primary" size="normal-bold">{{ cardData.userData.userName }}</app-text>

			<app-text color="grey9" size="small">{{ cardData.userData.userPosition }}</app-text>

			<v-divider class="greyD my-2"/>

			<app-text color="grey9" size="small">{{ cardData.organisationData.organisationName }}</app-text>

		</div>

	</div>
</template>

<script>

export default {

	name: "AccreditationUsersMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {

		/**
		 * Get Status Icon Config
		 *
		 * Return the icon and color for the status.
		 *
		 * @param status {string} the status
		 * @returns {{color: string, icon: string}}
		 */
		getStatusIconConfig(status) {
			let icon = ''
			let color = ''

			if (status === 'Pending') {
				icon = 'pending'
				color = 'orange'
			}

			if (status === 'Approved') {
				icon = 'success'
				color = 'green'
			}

			if (status === 'Rejected') {
				icon = 'cancel'
				color = 'red'
			}

			if (status === 'Reserved') {
				icon = 'reserved'
				color = 'blue'
			}

			return {icon, color}
		},

	},

}
</script>

<style scoped>

</style>
