<template>
	<v-overlay color="white" opacity="0.5" :value="isLoading">

		<v-progress-circular color="primary" indeterminate size="128">

			<v-img :src="require('@/assets/images/company-logo.svg')" width="96" contain/>

		</v-progress-circular>

	</v-overlay>
</template>

<script>

export default {

	name: 'PageLoadingAnimation',

	props: ['isLoading'],

}
</script>

<style>

</style>
