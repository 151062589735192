<template>
	<div class="appGrey rounded-lg pa-4">

		<div class="d-flex align-center">

			<app-icon class="mr-4" color="grey" icon="info" size="32"/>
			<app-text color="primary" size="normal-bold">SWAPP Tasks</app-text>

		</div>

		<v-divider class="greyD my-4"/>

		<!--Instructional Text - User-->
		<app-text
			v-if="['Staff-User'].includes(MIX_getCurrentUser().userLevel)">
			Start a Task List by scanning a SWAPP Task QR Code, and checking off the tasks as you go.
			<br>
			<br>
			Each Task Card will have a description of the task in hand, and some may have helpful information if more
			detail is needed.
			<br>
			<br>
			If you need to, you can leave a comment on a task should you have something to note.
			<br>
			<br>
			If you are unable to complete a task, tap the issues button on the card before marking the list as done.
			<br>
			<br>
			Once you are finished with what you are able to do, tap the done button.
		</app-text>

		<!--Instructional Text - Supervisor-->
		<app-text
			v-if="['Staff-Supervisor'].includes(MIX_getCurrentUser().userLevel)">
			As a Supervisor, you can view the history of this SWAPP Task List, along with any comments or issues that were recorded.
			<br>
			<br>
			You can also update the status of a task by checking the box as required.
			<br>
			<br>
			If you need to, you can report an issue with a task using the issues button on the card.
		</app-text>

		<v-divider class="greyD my-4"/>

		<!--Close Button-->
		<app-btn @click.native="$emit('closeDialog')" :block="true" label="Close"/>

	</div>
</template>

<script>

export default {

	name: "SwappTasksPageInfoDialog",

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
