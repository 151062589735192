<template>
	<v-avatar color="primary" :size="size">

		<!--Image-->
		<v-img v-if="user?.userProfilePicture"
			   :src="MIX_getImagePath(file?.fileFolder, file?.fileName, file?.fileToken)"/>

		<!--User's Initials-->
		<app-text v-if="!user?.userProfilePicture" color="white">{{ computedGetInitials }}</app-text>

	</v-avatar>
</template>

<script>
export default {

	name: "Avatar",

	props: ['file', 'size', 'user'],

	computed: {

		/**
		 * Computed Get Initials
		 *
		 * Return a string comprising the User's initials.
		 *
		 * @returns {string|string|*|string} the User's initials
		 */
		computedGetInitials() {
			const t = this
			const USER_NAME = t.$props.user?.userName

			// If there is no name, return an empty string
			if (!USER_NAME) return ''

			// Split the name
			const NAME_ARRAY = USER_NAME.split(' ')

			// If there's only one name, return one initial
			if (NAME_ARRAY.length < 2) return USER_NAME.charAt(0)

			// If there is more than one initial, return the first and last
			// e.g. John Doe = JD, John F Doe = JD
			const FIRST_INITIAL = NAME_ARRAY[0].charAt(0)
			let LAST_INITIAL = NAME_ARRAY[NAME_ARRAY.length - 1].charAt(0)

			return FIRST_INITIAL + LAST_INITIAL
		}

	},

}
</script>

<style scoped>

</style>
