<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else class="pa-4">

		<!--Latest Updates-->
		<div v-if="['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)" class="mb-8">

			<form-section-title title="Latest Updates" title-color="primary"/>

			<div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(232px, 1fr)); gap: 16px">

				<home-user-accounts-pending @click.native="MIX_go('/users')" :usersData="pendingUsersData"/>

				<!--Latest Updates Cards-->
				<div v-for="item in latestUpdateCardsData">
					<div @click="MIX_go(item.routePath)"
						 class="d-flex flex-column align-center white rounded-lg pa-4">

						<app-text color="primary" size="xlarge-bold">{{ item.result }}</app-text>

						<app-text class="mt-4">{{ item.message }}</app-text>

					</div>
				</div>

			</div>

		</div>

		<!--Notice Board-->
		<div>
			<div class="d-flex align-start">
				<app-icon class="mr-4" color="primary" icon="noticeBoard" size="48"/>
				<form-section-title title="Notice Board" title-color="primary"/>
			</div>
			<notice-board-widget/>
		</div>

	</div>

</template>

<script>
import HomeUserAccountsPending from "@/views/home/homeUserAccountsPending/HomeUserAccountsPending.vue";
import NoticeBoardWidget from "@/views/home/noticeBoardWidget/NoticeBoardWidget.vue";

export default {

	name: 'Home',

	components: {NoticeBoardWidget, HomeUserAccountsPending},

	data: () => ({
		currentUserData: {},
		isLoading: true,
		latestUpdateCardsData: [],

		// Data
		pendingUsersData: [],
	}),

	methods: {

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadCurrentUserData(),
				t.loadUsersData(),
				t.loadLatestUpdatesCardsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Current User Data
		 *
		 * Load User data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadCurrentUserData() {
			const t = this
			const CURRENT_USER_AUTH = t.currentUserAuth

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFullUserData(CURRENT_USER_AUTH.uid)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting User data: ', RESPONSE.error)
				return
			}

			// Assign the data
			t.currentUserData = RESPONSE.data?.userData
			t.MIX_addToLocalStorage('currentUserData', RESPONSE.data?.userData)
		},

		/**
		 * Load Users Data
		 *
		 * Load Users data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this

			// Only load for Admins
			if (t.MIX_getCurrentUser().userLevel !== 'Staff-Admin') return

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getUsersWhere({whereKey: 'userStatus', whereValue: 'Pending'})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Users data: ', RESPONSE.error)
				return
			}

			// Assign the data
			t.pendingUsersData = RESPONSE.data
		},

		/**
		 * Load Latest Updates Cards Data
		 *
		 * Load Latest Updates Cards data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadLatestUpdatesCardsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getLatestUpdatesCardsData()

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Latest Updates Cards data: ', RESPONSE.error)
				return
			}

			// Assign the data
			t.latestUpdateCardsData = RESPONSE.data
		},

	},

	created() {
		const t = this

		t.loadData()
	},

}
</script>
