<template>
	<div class="d-flex flex-column align-center">

		<!-- Icon -->
		<div class="d-flex align-center">
			<app-icon :color="getIconColor()" :icon="getIcon()" size="40" v-if="!disabled"/>
			<app-icon color="blue" icon="disabled" size="24" v-if="disabled"/>
			<app-icon color="blue" :icon="icon" size="40" v-if="disabled"/>
		</div>

		<!-- Size Label -->
		<app-text size="normal-bold">{{ size }}</app-text>

		<!-- Car Park Arrived/Expected -->
		<div class="d-flex align-center justify-center">

			<!-- Arrived -->
			<app-text :class="getFigureColor().hasError ? 'flash' : ''"
					  :color="getFigureColor().color"
					  size="large-bold">
				{{ arrived }}
			</app-text>

			<app-text color="grey9" class="mx-4">/</app-text>

			<!-- Required -->
			<app-text color="grey9" size="large-bold">{{ required }}</app-text>

		</div>

	</div>
</template>

<script>
export default {
	name: "ParkingDashboardCard",

	props: ['size', 'icon', 'disabled', 'arrived', 'required'],

	methods: {

		/**
		 * Get Icon Color
		 *
		 * Get the color for the vehicle icon.
		 *
		 * @returns {string}
		 */
		getIconColor() {
			return this.disabled ? "blue" : "primary";
		},

		/**
		 * Get Icon
		 *
		 * Get the icon for the vehicle.
		 *
		 * @returns {string|*}
		 */
		getIcon() {
			return this.disabled ? "disabled" : this.icon;
		},

		/**
		 * Get Figure Color
		 *
		 * Get the color for the arrived/required figure.
		 *  - If arrived is more than required, flashes red
		 *  - If arrived is equal to required, steady red
		 *  - If arrived is one less than required, orange
		 *  - Otherwise, green
		 *
		 * @returns {{color: string, hasError: boolean}}
		 */
		getFigureColor() {
			const t = this
			const NUMBER_ARRIVED = t.$props.arrived
			const NUMBER_REQUIRED = t.$props.required
			let figureConfig = {color: 'grey', hasError: false}

			if (NUMBER_ARRIVED === 0) figureConfig = {color: 'green', hasError: false}
			if (NUMBER_ARRIVED >= NUMBER_REQUIRED) figureConfig = {color: 'red', hasError: true}
			if (NUMBER_ARRIVED === (NUMBER_REQUIRED - 1)) figureConfig = {color: 'orange', hasError: false}
			if (NUMBER_ARRIVED === 0 || NUMBER_ARRIVED < NUMBER_REQUIRED) figureConfig = {
				color: 'green',
				hasError: false
			}

			return figureConfig
		},
	},
};
</script>
