<template>
	<div class="appGrey rounded-lg pa-4">

		<div class="d-flex align-center">
			<app-icon class="mr-4" color="grey9" icon="info" size="32"/>
			<app-text color="primary" size="normal-bold">Helpful Information</app-text>
		</div>

		<v-divider class="greyD my-4"/>

		<app-text v-if="taskData.swappTasksTaskInformationText">{{ taskData.swappTasksTaskInformationText }}</app-text>
		<app-text v-else class="text-center" color="grey9">There is no information to show</app-text>

		<v-divider class="greyD my-4"/>

		<!--Close Button-->
		<app-btn @click.native="closeDialog" :block="true" color="grey" label="Close"/>

	</div>
</template>

<script>

export default {

	name: "SwappTasksTextInfoDialog",

	props: ['taskData'],

	data: () => ({}),

	methods: {

		closeDialog() {
			this.$emit('closeDialog')
		},

	},

}
</script>

<style scoped>

</style>
