<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<div class="d-flex justify-space-between">

			<!--Title-->
			<page-title icon="eventsDashboardDay"
						info="Keep up to date with figures for each Team per Event."
						title="Event Day Dashboard"/>

			<!--Event Stats-->
			<div class="d-flex">

				<!--Due-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 800" card-label="Due"
									  :card-value="computedEventFigures.numberDue"
									  cardColor="red"/>

				<!--Arrived-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 800" card-label="Arrived"
									  :card-value="computedEventFigures.numberArrived"
									  cardColor="orange"/>

				<!--Signed-->
				<pre-event-total-card v-if="$vuetify.breakpoint.width >= 800" card-label="Signed"
									  :card-value="computedEventFigures.numberSigned"
									  cardColor="green"/>

			</div>

		</div>

		<v-divider class="my-4"/>

		<!--Event | Filters | More Actions-->
		<div class="d-flex align-center mt-4">

			<!--Event Selection-->
			<app-form-field form-type="autoComplete"
							class="mr-4"
							:items="eventsData"
							item-text="eventName"
							label="Select an Event"
							:return-object="true"
							v-model="selectedEvent"/>

			<!--Filters-->
			<app-form-field v-if="selectedEvent.entityId"
							form-type="select"
							class="mr-4"
							:clearable="true"
							:deletable-chips="true"
							:items="computedTeamsData"
							item-text="teamName"
							label="Filter"
							:multiple="true"
							prepend-inner-icon="icons8-slider primary--text"
							:return-object="true"
							:small-chips="true"
							v-model="filterByTeams"/>

			<v-spacer/>

			<!--More Actions Menu-->
			<!--<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>-->

		</div>

		<!--Team Cards-->
		<div class="preEventDashboard-cardGrid mt-4">

			<event-day-team-card v-for="item in computedCardsData"
								 :cardData="item"/>

		</div>

	</div>

</template>

<script>
import EventDayTeamCard from "@/views/eventDayDashboard/eventDayTeamCard/EventDayTeamCard";
import PreEventTotalCard from "@/views/preEventDashboard/preEventTotalCard/PreEventTotalCard";

export default {

	name: "EventDayDashboard",

	components: {PreEventTotalCard, EventDayTeamCard},

	data: () => ({
		refreshInterval: null,
		filterByTeams: [],
		isLoading: true,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		selectedEvent: {},

		// Data
		dashboardFiguresData: [],
		eventsData: [],
		teamsData: [],
	}),

	computed: {

		/**
		 * Computed Cards Data
		 *
		 * Sort and return the data for the cards.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedCardsData() {
			const t = this
			let cardsData = t.dashboardFiguresData

			// Filter by Teams
			if (t.filterByTeams.length) cardsData = cardsData.filter(cd => t.filterByTeams.some(t => t.entityId === cd.teamId))

			return cardsData
		},

		/**
		 * Computed Event Figures
		 *
		 * Calculate and return the total figures for the Event.
		 *
		 * @returns {{numberArrived: (number|string), numberDue: (number|string), numberSigned: (number|string)}} - an object containing the total figures for the Event
		 */
		computedEventFigures() {
			const t = this
			const CARDS_DATA = t.computedCardsData
			let numberDue = t.selectedEvent?.entityId ? 0 : '-'
			let numberArrived = t.selectedEvent?.entityId ? 0 : '-'
			let numberSigned = t.selectedEvent?.entityId ? 0 : '-'

			if (t.selectedEvent?.entityId) {
				CARDS_DATA.forEach(cardData => {
					numberDue += cardData.numberTeamDue
					numberArrived += cardData.numberTeamArrived
					numberSigned += cardData.numberTeamSignedIn
				})
			}

			return {numberDue, numberArrived, numberSigned}
		},

		/**
		 * Computed Teams Data
		 *
		 * Return an array of Teams that have been added to this Event.
		 *
		 * @returns {array} - array of Team object
		 */
		computedTeamsData() {
			const t = this
			const DASHBOARD_FIGURES_DATA = t.dashboardFiguresData
			const TEAMS_DATA = t.teamsData

			return TEAMS_DATA.filter(team => DASHBOARD_FIGURES_DATA.find(df => df.teamId === team.entityId))
		},

	},

	methods: {

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'New') t.isAddLocationVisible = true
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadEventsData(),
				t.loadTeamsData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Events Data
		 *
		 * Load Live Events data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventsWhere({whereKey: 'eventStatus', whereValue: 'Live'})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Events: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.eventsData = RESPONSE.data
		},

		/**
		 * Load Teams Data
		 *
		 * Load Teams data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadTeamsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('team')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Teams: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.teamsData = RESPONSE.data
		},

		/**
		 * Load Event Day Dashboard Figures Data
		 *
		 * Load the required figures data for the cards.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventDayDashboardFiguresData(eventId) {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventDayDashboardDataByEvent(eventId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Dashboard data: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.dashboardFiguresData = RESPONSE.data
		},

	},

	watch: {

		'selectedEvent'(selectedEvent) {
			const t = this

			t.loadEventDayDashboardFiguresData(selectedEvent.entityId)
		}

	},

	async created() {
		const t = this

		await t.loadData()

		// Use a setTimeout to reload the data every minute
		t.refreshInterval = setInterval(async () => {
			await t.loadEventDayDashboardFiguresData(t.selectedEvent.entityId)
		}, 1*60*1000)
	},

	beforeDestroy() {
		const t = this

		// Clear the interval
		clearInterval(t.refreshInterval)
	}

}
</script>

<style scoped>
.preEventDashboard-cardGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 16px;
}
</style>
