<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--Supervisors -------------------------------------------------------------------------------------------- -->
		<div>

			<!--Label-->
			<app-text size="medium-bold">Supervisors</app-text>

			<!--Form-->
			<div class="d-flex align-center justify-space-between mt-4">

				<!--Supervisors Selection-->
				<app-form-field form-type="autoComplete"
								:disabled="!isSupervisorsEditable"
								:items="computedSupervisorsDropDownData"
								item-text="userName"
								label="Available Supervisors"
								:multiple="true"
								:return-object="true"
								:small-chips="true"
								style="width: 100%"
								v-model="tempTeamSupervisors"/>

				<!--Save Button-->
				<app-btn v-if="isSupervisorsEditable"
						 @click.native="saveSupervisors(tempTeamSupervisors)"
						 class="ml-4"
						 color="green"
						 :disabled="!tempTeamSupervisors.length"
						 icon="save"
						 label="Save"/>

				<!--Edit Button-->
				<edit-icon @click.native="editSupervisor" :isActive="isSupervisorsEditable"/>

			</div>

			<!--Table-->
			<v-data-table
				class="appWhite rounded-lg mt-4"
				:items="teamSupervisors"
				:headers="tableHeaders">

				<!--Supervisor-->
				<template v-slot:item.userName="{item}">
					<app-text size="small">{{ item.userName }}</app-text>
				</template>

				<!--Delete-->
				<template v-slot:item.delete="{item}">
					<app-icon @click.native="removeSupervisor(item)" class="cursorPointer pa-2" color="red"
							  icon="delete"/>
				</template>

			</v-data-table>

		</div>

		<!--Deputies ----------------------------------------------------------------------------------------------- -->
		<div class="mt-4">

			<!--Label-->
			<app-text size="medium-bold">Deputies</app-text>

			<!--Form-->
			<div class="d-flex align-center justify-space-between mt-4">

				<!--Deputies-->
				<app-form-field form-type="autoComplete"
								:disabled="!isDeputiesEditable"
								:items="computedDeputiesDropDownData"
								item-text="userName"
								label="Available Deputies"
								:multiple="true"
								:return-object="true"
								:small-chips="true"
								style="width: 100%"
								v-model="tempTeamDeputies"/>

				<!--Save Button-->
				<app-btn v-if="isDeputiesEditable"
						 @click.native="saveDeputies(tempTeamDeputies)"
						 class="ml-4"
						 color="green"
						 :disabled="!tempTeamDeputies.length"
						 icon="save"
						 label="Save"/>

				<!--Edit Button-->
				<edit-icon @click.native="editDeputies" :isActive="isDeputiesEditable"/>

			</div>

			<!--Table-->
			<v-data-table
				class="appWhite rounded-lg mt-4"
				:items="teamDeputies"
				:headers="tableHeaders">

				<!--Deputy-->
				<template v-slot:item.userName="{item}">
					<app-text size="small">{{ item.userName }}</app-text>
				</template>

				<!--Delete-->
				<template v-slot:item.delete="{item}">
					<app-icon @click.native="removeDeputy(item)" class="cursorPointer pa-2" color="red" icon="delete"/>
				</template>

			</v-data-table>

		</div>

	</div>
</template>

<script>
export default {

	name: "TeamSupervisorForm",

	props: ['formData', 'isReadOnly', 'usersData'],

	data: () => ({
		tableHeaders: [
			{text: 'Name', value: 'userName', sortable: false, align: 'start'},
			{text: 'Actions', value: 'delete', sortable: false, align: 'center', width: '10px'},
		],

		isSupervisorsEditable: false,
		tempTeamSupervisors: [],
		teamSupervisors: [],

		isDeputiesEditable: false,
		tempTeamDeputies: [],
		teamDeputies: [],
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props?.formData?.teamData

			if (FORM_DATA?.entityId) {

				// Assign all the users for this team
				t.teamSupervisors = t.$props.usersData.filter(u => u.userLevel === 'Steward-Supervisor' && u.userTeam === FORM_DATA.entityId)
				t.teamDeputies = t.$props.usersData.filter(u => u.userLevel === 'Steward-Deputy' && u.userTeam === FORM_DATA.entityId)
			}

		},

		/**
		 * Supervisors Drop Down Data
		 *
		 * Return an array of available Supervisors (haven't been assigned to a team).
		 *
		 * @returns {array} array of Supervisors
		 */
		computedSupervisorsDropDownData() {
			const t = this
			const USERS_DATA = t.$props.usersData

			return USERS_DATA.filter(u => u.userLevel === 'Steward-Supervisor' && !u.userTeam)
		},

		/**
		 * Deputies Drop Down Data
		 *
		 * Return an array of available Deputies (haven't been assigned to a team).
		 *
		 * @returns {array} array of Deputies
		 */
		computedDeputiesDropDownData() {
			const t = this
			const USERS_DATA = t.$props.usersData

			return USERS_DATA.filter(u => u.userLevel === 'Steward-Deputy' && !u.userTeam)
		},

	},

	methods: {

		/**
		 * Edit Supervisor
		 *
		 * Toggle the editability.
		 */
		editSupervisor() {
			const t = this

			t.isSupervisorsEditable = !t.isSupervisorsEditable
		},

		/**
		 * Edit Deputy
		 *
		 * Toggle the editability.
		 */
		editDeputies() {
			const t = this

			t.isDeputiesEditable = !t.isDeputiesEditable
		},

		/**
		 * Reload Data
		 *
		 * Emit back to the parent (Teams.vue) to reload the data to update the page.
		 */
		reloadData() {
			const t = this

			t.$emit('emitReloadData')
		},

		/**
		 * Remove Deputy
		 *
		 * Remove the Deputy from the current Team by resetting their userTeam field.
		 *
		 * @param userData the user object to remove
		 * @returns {Promise<void>}
		 */
		async removeDeputy(userData) {
			const t = this

			// Reset the userTeam (disassociate them from the Team)
			userData.userTeam = ''

			const RESPONSE = await t.MIX_redis_update('user', userData.entityId, userData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating User: ', RESPONSE.error)
				return
			}

			// Remove the user from the tempTeamDeputies array to stop them showing up in the Available Deputies
			// selection field
			t.tempTeamDeputies = t.tempTeamDeputies.filter(ts => ts.entityId !== userData.entityId)

			t.$sharedState.successMessage = 'Removed Deputy'
		},

		/**
		 * Remove Supervisor
		 *
		 * Remove the Supervisor from the current Team by resetting their userTeam field.
		 *
		 * @param userData the user object to remove
		 * @returns {Promise<void>}
		 */
		async removeSupervisor(userData) {
			const t = this

			// Reset the userTeam (disassociate them from the Team)
			userData.userTeam = ''

			const RESPONSE = await t.MIX_redis_update('user', userData.entityId, userData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating User: ', RESPONSE.error)
				return
			}

			// Remove the user from the tempTeamSupervisors array to stop them showing up in the Available Supervisors
			// selection field
			t.tempTeamSupervisors = t.tempTeamSupervisors.filter(ts => ts.entityId !== userData.entityId)

			t.$sharedState.successMessage = 'Removed Supervisor'
		},

		/**
		 * Save Deputies
		 *
		 * Send the new teamDeputies array off to the server to update the list of Deputies on this team.
		 */
		async saveDeputies(teamDeputies) {
			const t = this
			const FORM_DATA = t.$props?.formData?.teamData

			// If there are no deputies, return
			if (!t.tempTeamDeputies.length) return

			// Update Users
			const RESPONSE = await t.MIX_redis_updateUsersTeam(teamDeputies, FORM_DATA.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating User: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated Deputies'

			t.isDeputiesEditable = false

			t.reloadData()
		},

		/**
		 * Save Supervisors
		 *
		 * Send the new teamSupervisors array off to the server to update the list of Supervisors on this team.
		 */
		async saveSupervisors(teamSupervisors) {
			const t = this
			const FORM_DATA = t.$props?.formData?.teamData

			// If there are no supervisors, return
			if (!t.tempTeamSupervisors.length) return

			// Update Users
			const RESPONSE = await t.MIX_redis_updateUsersTeam(teamSupervisors, FORM_DATA.entityId)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating User: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated Supervisors'

			t.isSupervisorsEditable = false

			t.reloadData()
		},

	}

}
</script>

<style scoped>

</style>
