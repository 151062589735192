<template>
	<v-footer :class="$vuetify.breakpoint.width < 600
			  ? 'd-flex flex-column align-center'
			  : 'd-flex justify-space-between pl-12'"
			  color="appGrey"
			  elevation="2">

		<v-img :src="require('@/assets/images/vindico-logo-purple.png')" contain height="16" max-width="128"/>

		<app-text color="grey9" size="small">
			Copyright
			<span class="primary--text">{{ appConfig.appBranding.companyName }}</span>
			2023
		</app-text>

	</v-footer>
</template>

<script>
import appConfig from '../../../appConfig.json'

export default {
	name: "AppFooter",

	data: () => ({
		appConfig
	})
}
</script>

<style scoped>
</style>
