<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Search | More Actions-->
		<div class="d-flex align-center justify-space-between">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							label="Location"
							v-model.trim="searchLocationName"/>

			<!--Add || Menu-->
			<div class="d-flex">

				<!--Add Button-->
				<app-btn @click.native="openLocationFormDialog" class="mr-4" icon="add" label="Add"/>

				<!--More Actions Menu-->
				<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

			</div>

		</div>

		<v-data-table class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--Location Name-->
			<template v-slot:item.locationName="{item}">
				<app-text size="small">{{ item.locationName }}</app-text>
			</template>

			<!--QR Code-->
			<template v-slot:item.qrCode="{item}">
				<div class="d-flex cursorPointer justify-center py-4">
					<div class="d-flex align-center justify-center rounded-lg"
						 style="height: 40px; width: 40px">
						<app-icon @click.native="openQrCodePreviewDialog(item)" color="green" icon="qrCode" size="32"/>
					</div>
				</div>
			</template>

			<!--Edit-->
			<template v-slot:item.edit="{item}">
				<div class="d-flex justify-center ml-n4 py-4">
					<edit-icon @click.native="editItem(item)"/>
				</div>
			</template>

			<!--Delete-->
			<template v-slot:item.delete="{item}">
				<div class="d-flex justify-center ml-n4 py-4">
					<delete-icon @emitDeleteItem="deleteItem(item)"
								 itemName="locationName"
								 :selectedItem="item"/>
				</div>
			</template>

		</v-data-table>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--Location Form Dialog-->
		<v-dialog max-width="512" v-model="isLocationFormDialogVisible">

			<div class="appGrey rounded-lg pa-4">

				<app-text>Please enter the name for this Location</app-text>

				<app-form-field form-type="textInput"
								class="mt-4"
								:error="errors.locationName"
								:error-message="errors.locationNameErrorMessage"
								placeholder="Location Name"
								v-model.trim="form.locationName"/>

				<div class="d-flex justify-space-between mt-4">

					<!--Cancel-->
					<app-btn @click.native="handleCancelButton"
							 color="grey"
							 icon="cancel"
							 label="Cancel"/>

					<!--Save-->
					<app-btn @click.native="handleSaveLocation(form)"
							 color="green"
							 icon="save"
							 label="Save"/>

				</div>

			</div>

		</v-dialog>

		<!--QR Code Preview-->
		<v-overlay :value="isQrCodePreviewDialogVisible">

			<div class="appGrey rounded-lg pa-4">

				<div class="appGrey d-flex align-center justify-space-between rounded-lg">

					<!--Location-->
					<app-text color="primary" size="medium-bold">{{ selectedItem.locationName }}</app-text>

					<!--Close Button-->
					<close-icon @click.native="closeQrCodePreviewDialog"/>

				</div>

				<v-divider class="greyD mt-4" style="width: 100%"/>

				<!--QR Code-->
				<div class="pa-8">
					<qrcode-vue background="#ffffff"
								foreground="black"
								level="H"
								size="343"
								:value="selectedItem?.entityId"/>
				</div>

			</div>

		</v-overlay>

	</div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {

	name: "SiteLocationsPanel",

	props: ['formData'],

	components: {QrcodeVue},

	data: () => ({
		errors: {
			locationName: false,
			locationNameErrorMessage: '',
		},
		form: {
			entityId: '',
			locationName: '',
			locationSite: '',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		},
		isLocationFormDialogVisible: false,
		isQrCodePreviewDialogVisible: false,
		moreActionsMenuOption: [
			{name: 'Export', icon: 'export'}
		],
		searchLocationName: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'Location', value: 'locationName', align: 'start', sortable: false, hidden: false},
			{text: 'Qr Code', value: 'qrCode', align: 'center', sortable: false, hidden: false, width: '96px'},
			{text: 'Edit', value: 'edit', align: 'center', sortable: false, hidden: false, width: '96px'},
			{text: 'Delete', value: 'delete', align: 'center', sortable: false, width: '96px'},
		],

		// Data
		locationsData: [],
		siteData: {},
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter(h => !h.hidden)

			// Only those with access can view the actions column
			if (!['Staff-Admin'].includes(CURRENT_USER_DATA.userLevel)) headers = headers.filter(h => h.value !== 'delete')

			return headers
		},

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const SITE_DATA = t.$props.formData.siteData
			const LOCATIONS_DATA = t.$props.formData.locationsData

			if (SITE_DATA?.entityId) {
				t.siteData = SITE_DATA
				t.locationsData = LOCATIONS_DATA
			}
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.$props.formData.locationsData

			// Search by Location Name
			if (t.searchLocationName) tableData = tableData.filter(location =>
				location.locationName.toUpperCase().includes(t.searchLocationName.toUpperCase()))

			// Sort into alphabetical order
			tableData = tableData.sort((a, b) => a.locationName > b.locationName ? 1 : -1)

			return tableData
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Location Dialog
		 */
		closeLocationDialog() {
			const t = this

			t.clearErrors()
			t.locationName = ''

			t.isLocationFormDialogVisible = false
		},

		/**
		 * Close QR Code Preview Dialog
		 *
		 * Close the QR Code Preview Dialog.
		 */
		closeQrCodePreviewDialog() {
			const t = this

			t.isQrCodePreviewDialogVisible = false
		},

		/**
		 * Delete Item
		 *
		 * Delete the selected location (only marks as deleted).
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('location', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Location: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted Location'

			t.reloadPage()
		},

		/**
		 * Edit Item
		 *
		 * Open the location form dialog and populate the form with the selected location.
		 *
		 * @param item {object} the location to edit
		 */
		async editItem(item) {
			const t = this

			t.openLocationFormDialog(item)
		},

		/**
		 * Handle Cancel Button
		 *
		 * Call to close the dialog.
		 */
		handleCancelButton() {
			const t = this

			t.closeLocationDialog()
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			// if (selection === 'New') t.openLocationFormDialog()
		},

		/**
		 * Handle Save Location
		 *
		 * Set the site and call to either save or update the location.
		 */
		handleSaveLocation(item) {
			const t = this

			t.form.locationSite = t.$props.formData.siteData.entityId

			if (item?.entityId) t.updateLocation(item)
			else t.saveLocation(item)
		},

		/**
		 * Open Location Form Dialog
		 *
		 * Open the location form dialog.
		 * If there is an item, it is an edit, so populate the form with the item data.
		 * If there is no item, it is a new location, so clear the form.
		 * @param item {object} the location to edit
		 */
		openLocationFormDialog(item) {
			const t = this

			if (item?.entityId) t.form = {...item}
			else t.form.locationName = ''

			t.isLocationFormDialogVisible = true
		},

		/**
		 * Open QR Code Preview Dialog
		 *
		 * Open the QR Code Preview Dialog.
		 */
		openQrCodePreviewDialog(item) {
			const t = this

			t.selectedItem = item

			t.isQrCodePreviewDialogVisible = true
		},

		/**
		 * Reload Data
		 *
		 * Emit back to the parent (Teams.vue) to reload the data.
		 */
		reloadData() {
			const t = this

			t.closeLocationDialog()
			t.$emit('emitReloadData')
		},

		/**
		 * Reload Page
		 *
		 * Emit back to the parent (Teams.vue) to reload the data and close the right panel.
		 */
		reloadPage() {
			const t = this

			t.$emit('emitReloadPage')
		},

		/**
		 * Save Location
		 *
		 * Save the location.
		 *
		 * @returns {Promise<void>}
		 */
		async saveLocation(locationData) {
			const t = this

			// Only save if the form is valid
			if (!t.validateForm()) return

			const RESPONSE = await t.MIX_redis_create('location', locationData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating Location: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Created Location'

			t.reloadPage()
		},

		/**
		 * Update Location
		 *
		 * Update the location.
		 *
		 * @returns {Promise<void>}
		 */
		async updateLocation(locationData) {
			const t = this
			const FORM_DATA = locationData

			const RESPONSE = await t.MIX_redis_update('location', FORM_DATA.entityId, FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Location: ', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Updated Location'

			t.reloadPage()
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Location Name
			if (!t.form.locationName) {
				t.errors.locationName = true
				t.errors.locationNameErrorMessage = 'Location Name is required'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
