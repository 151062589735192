<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else>

		<!--Header-->
		<div class="d-flex align-center justify-space-between">

			<page-title icon="user"
						info="View and administer your account."
						title="My Profile"/>

			<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>

		</div>

		<v-divider class="greyD my-4"/>

		<!--Form-->
		<user-form @emitReloadPage="reloadPage"
				   :fullUserData="fullCurrentUserData"
				   :isReadOnly="isReadOnly"/>

	</div>
</template>

<script>
import UserForm from "@/views/users/userForm/UserForm";

export default {

	name: "MyProfile",

	components: {UserForm},

	data: () => ({
		isLoading: true,
		isReadOnly: true,

		// Data
		fullCurrentUserData: {},
	}),

	methods: {

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFullCurrentUserData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Current User Data
		 *
		 * Load User data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullCurrentUserData() {
			const t = this
			const CURRENT_USER_AUTH = t.currentUserAuth

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getFullUserData(CURRENT_USER_AUTH.uid)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting full User data: ', RESPONSE.error)
				return
			}

			// Assign the data
			t.fullCurrentUserData = RESPONSE.data
		},

		reloadPage() {
			const t = this

			t.loadData()
			t.editItem()
		}

	},

	created() {
		const t = this

		t.loadData()
	}

}
</script>

<style scoped>

</style>
