import { render, staticRenderFns } from "./EventControlPanelSignDialog.vue?vue&type=template&id=45adae64&scoped=true&"
import script from "./EventControlPanelSignDialog.vue?vue&type=script&lang=js&"
export * from "./EventControlPanelSignDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45adae64",
  null
  
)

export default component.exports