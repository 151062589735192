<template>
	<v-dialog max-width="512"
			  v-model="dialog">

		<!--Activator-->
		<template v-slot:activator="{on, attrs}">

			<div class="cursorPointer" v-on="on">

				<v-hover v-slot="{hover}">

					<app-icon class="deleteIcon-container"
							  :class="hover ? 'red' : 'appWhite'"
							  :color="hover ? 'appWhite' : 'red'"
							  icon="delete"
							  size="32"
							  title="Delete"/>

				</v-hover>

			</div>

		</template>

		<!--Content-->
		<v-card>

			<!--Body-->
			<div class="appGrey pa-4">

				<!--Information-->
				<app-text>
					You are about to delete <strong>{{ itemName ? selectedItem && selectedItem[itemName] : 'this item'
					}}</strong>.
					<br>
					<br>
					<strong>Are you sure you want to do this?</strong>
				</app-text>

				<v-divider class="mt-4"/>

				<!--Action Buttons-->
				<div class="d-flex justify-space-between mt-4">

					<!--No-->
					<app-btn @click.native="dialog = false"
							 color="grey"
							 icon="cancel"
							 icon-color="appGrey"
							 label="No"/>

					<!--Yes-->
					<app-btn @click.native="handleYes"
							 color="red"
							 icon="delete"
							 icon-color="white"
							 label="Delete"
							 label-color="white"/>

				</div>

			</div>

		</v-card>

	</v-dialog>
</template>

<script>
export default {

	name: "DeleteIcon",

	props: ['itemName', 'selectedItem'],

	data: () => ({
		dialog: false,
	}),

	methods: {

		handleYes() {
			const t = this

			t.$emit('emitDeleteItem', t.$props.selectedItem)
		},

	}

}
</script>

<style scoped>
.deleteIcon-container {
	border-radius: 8px;
	margin-left: 16px;
	padding: 12px;
	transition: 0.3s;
}
</style>
