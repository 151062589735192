<template>
	<div class="appGrey" style="min-height: 100%">

		{{ computedInit }}

		<!--Employment-->
		<v-row no-gutters>

			<!--Team Name-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.teamName"
								:error-message="errors.teamNameErrorMessage"
								label="Team Name"
								v-model.trim="form.teamName"/>
			</v-col>

			<!--Quota-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.teamQuota"
								:error-message="errors.teamQuotaErrorMessage"
								label="Quota"
								type="number"
								v-model.number="form.teamQuota"/>
			</v-col>

			<!--Area-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.teamArea"
								:error-message="errors.teamAreaErrorMessage"
								label="Area"
								v-model.trim="form.teamArea"/>
			</v-col>

			<!--Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								:error="errors.teamDescription"
								:error-message="errors.teamDescriptionErrorMessage"
								label="Description"
								v-model.trim="form.teamDescription"/>
			</v-col>

		</v-row>

		<!--Save Button-->
		<div class="d-flex justify-end mt-4">
			<app-btn v-if="!isReadOnly"
					 @click.native="saveItem"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>
export default {

	name: "TeamForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		errors: {
			teamArea: false,
			teamAreaErrorMessage: '',
			teamDescription: false,
			teamDescriptionErrorMessage: '',
			teamQuota: false,
			teamQuotaErrorMessage: '',
			teamName: false,
			teamNameErrorMessage: '',
		},
		form: {
			entityId: '',
			teamArea: '',
			teamDescription: '',
			teamQuota: 0,
			teamName: '',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		}
	}),

	computed: {

		/**
		 * Computed Init
		 *
		 * If there is any form data, assign it to the form.
		 * If there is no form data, it will be blank (new).
		 */
		computedInit() {
			const t = this
			const formData = t.$props.formData

			if (formData?.entityId) t.form = formData
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Save Item
		 *
		 * Save/Update the form to the DB, after it has passed validation.
		 */
		async saveItem() {
			const t = this
			let response

			if (!t.validateForm()) return

			// Create
			if (!t.form.entityId) {

				response = await t.MIX_redis_create('team', t.form)

				// Handle any errors
				if (response.hasErrors) {
					console.error('Error creating Team: ', response.error)
					return
				}

				t.$sharedState.successMessage = 'Created Team'
			}

			// Update
			if (t.form.entityId) {

				response = await t.MIX_redis_update('team', t.form.entityId, t.form)

				// Handle any errors
				if (response.hasErrors) {
					console.error('Error updating Team: ', response.error)
					return
				}

				t.$sharedState.successMessage = 'Updated Team'
			}

			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form as required.
		 *
		 * @returns {boolean} if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Team Name
			const TEAM_NAME = t.form.teamName
			if (!TEAM_NAME) {
				t.errors.teamName = true
				t.errors.teamNameErrorMessage = 'Team Name is required'
			}

			// Team Quota
			if (t.form.teamQuota === 0) {
				t.errors.teamQuota = true
				t.errors.teamQuotaErrorMessage = 'Team Quota is required'
			} else if (t.form.teamQuota < 0 || !Number.isInteger(t.form.teamQuota)) {
				t.errors.teamQuota = true
				t.errors.teamQuotaErrorMessage = 'Team Quota must be a positive whole number'
			}

			// Team Area
			const TEAM_AREA = t.form.teamArea
			if (!TEAM_AREA) {
				t.errors.teamArea = true
				t.errors.teamAreaErrorMessage = 'Team Area is required'
			}

			return !Object.values(t.errors).includes(true)
		}

	}

}
</script>

<style scoped>

</style>
