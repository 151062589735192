<template>
	<div class="rounded-lg eventDayCard-container">

		<!--Team Name-->
		<app-text class="pa-2" size="small">{{ cardData.teamName }}</app-text>

		<!--Signed In-->
		<pre-event-progress-bar color="green"
								label="Signed In"
								:numerical-value="cardData.numberTeamSignedIn"
								:percent-value="calculatePercentage(cardData.numberTeamSignedIn)"/>

		<!--Arrived-->
		<pre-event-progress-bar color="orange"
								label="Arrived"
								:numerical-value="cardData.numberTeamArrived"
								:percent-value="calculatePercentage(cardData.numberTeamArrived)"/>

		<!--Due-->
		<pre-event-progress-bar color="red"
								label="Due"
								:numerical-value="cardData.numberTeamDue"
								:percent-value="calculatePercentage(cardData.numberTeamDue)"/>

	</div>
</template>

<script>
import PreEventProgressBar from "@/views/preEventDashboard/preEventTeamCard/preEventProgressBar/PreEventProgressBar";

export default {

	name: "EventDayTeamCard",

	props: ['cardData'],

	components: {PreEventProgressBar},

	methods: {

		/**
		 * Calculate Percentage
		 *
		 * Calculate the percentage of how many of the Confirmed team members have the given status.
		 *
		 * @param statusNumber - the number of team members with the given status
		 * @returns {number} - the percentage value
		 */
		calculatePercentage(statusNumber) {
			const t = this
			const NUMBER_TEAM_CONFIRMED = t.$props.cardData.numberTeamConfirmed

			return statusNumber / NUMBER_TEAM_CONFIRMED * 100
		}

	}

}
</script>

<style scoped>
.eventDayCard-container {
	background-color: var(--v-appWhite-base);
	border: 1px solid lightgrey;
	/*height: 200px;*/
	/*width: 200px;*/
}
</style>

