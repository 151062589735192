<template>
	<div>

		<!--Header-->
		<page-title icon="events"
					info="Please use the cards below to update the statuses for your Team Members."
					title="Events"/>

		<v-divider class="mt-4"/>

		<div class="d-flex justify-space-between mt-4">

			<!--Back Button-->
			<app-btn @click.native="$emit('emitEventView', 'Events')"
					 class="mr-4" color="appWhite" icon="arrowBack" icon-color="primary"/>

			<!--Control Panel-->
			<app-btn @click.native="$emit('emitShowEventControlPanel', {eventsView: 'Control Panel'})"
					 label="Control Panel"/>

		</div>

		<!--Status Figures-->
		<div class="appWhite rounded-lg mt-4 pa-4"
			 :class="$vuetify.breakpoint.width < 768 ? 'eventStatusSelection-statusFigures-column' : 'eventStatusSelection-statusFigures-row'">

			<div v-for="item in statusData" :key="item.statusName"
				 @click="selectedStatus = item.statusName" class="text-center">

				<!--Status Number-->
				<app-text v-if="item.statusName === 'Confirmed'" :color="item.statusColor" size="medium-bold">
					{{ getStatusNumber(item.statusName) }}/{{ usersFullEventData[0]?.eventTeamData.eventTeamQuota }}
				</app-text>
				<app-text v-else :color="item.statusColor" size="medium-bold">{{ getStatusNumber(item.statusName) }}
				</app-text>

				<!--Status Name-->
				<app-text :color="item.statusColor" size="small">{{ item.statusName }}</app-text>

			</div>

		</div>

		<!--Status Cards-->
		<app-text v-if="!computedCardData.length" class="text-center mt-4">There are no {{ selectedStatus }} cards to
			show
		</app-text>
		<div v-for="item in computedCardData" :key="item.eventUserData.entityId"
			 class="appWhite rounded-lg mt-4 pa-4">

			<!--Name | Number | Telephone-->
			<div>

				<!--Name-->
				<app-text size="normal-bold">{{ item.userData.userName }}</app-text>

				<!--Number-->
				<app-text class="my-1" color="grey" size="small">{{ item.userData.userInternalIdNumber }}</app-text>

				<!--Telephone-->
				<div class="d-flex align-center">
					<app-icon class="mt-n1" color="primary" icon="phone" size="16"/>
					<app-text class="ml-2" size="small">{{ item.userData.userTelephone }}</app-text>
				</div>

			</div>

			<!--Action Status Buttons-->
			<div class="mt-4">
				<v-menu close-on-click>

					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on">
							<app-btn :block="true"
									 :color="getStatusData(item.eventUserData.eventUserStatus).statusColor"
									 :label="getStatusData(item.eventUserData.eventUserStatus).statusName"
									 label-color="white"/>
						</div>
					</template>

					<v-list>
						<v-list-item v-for="statusItem in statusData" :key="statusItem.statusName">
							<app-btn @click.native="updateEventUserStatus(item, statusItem.statusName)"
									 :block="true"
									 class="my-2"
									 :color="statusItem.statusColor"
									 :disabled="statusItem.statusName === 'Confirmed' && getStatusNumber('Confirmed') >= item.eventTeamData.eventTeamQuota"
									 :label="statusItem.statusName"
									 label-color="white"
									 style="width: 100%"/>
						</v-list-item>
					</v-list>

				</v-menu>
			</div>

		</div>

	</div>
</template>

<script>
export default {

	name: "EventStatusSelection",

	props: ['usersFullEventData'],

	data: () => ({
		selectedStatus: 'Available',
		statusData: {
			Available: {statusName: 'Available', statusColor: 'orange'},
			Confirmed: {statusName: 'Confirmed', statusColor: 'green'},
			Reserved: {statusName: 'Reserved', statusColor: 'blue'},
			'Not Required': {statusName: 'Not Required', statusColor: 'red'},
			Unavailable: {statusName: 'Unavailable', statusColor: 'grey3'},
			'No Response': {statusName: 'No Response', statusColor: 'grey9'},
		},
	}),

	computed: {

		/**
		 * Computed Card Data
		 *
		 * Filter and return the data based on the selected status.
		 *
		 * @returns {array} - an array of full event objects
		 */
		computedCardData() {
			const t = this
			const USERS_FULL_EVENT_DATA = t.$props.usersFullEventData
			const SELECTED_STATUS = t.selectedStatus
			let eventUsersAndUsersData = []

			eventUsersAndUsersData = USERS_FULL_EVENT_DATA.filter(eu => eu.eventUserData.eventUserStatus === SELECTED_STATUS)

			return eventUsersAndUsersData
		},

	},

	methods: {

		/**
		 * Get Status Data
		 *
		 * Get and return the given status data.
		 *
		 * @param statusName - the name of the status to return the data for
		 * @returns {object} - the selected status data
		 */
		getStatusData(statusName) {
			const t = this

			return t.statusData[statusName]
		},

		/**
		 * Get Status Number
		 *
		 * Get and return the number of EventUser documents with the given status.
		 *
		 * @param statusName - the status name to get the figures for
		 * @returns {number} - the number EventUser documents with the given status
		 */
		getStatusNumber(statusName) {
			const t = this
			const USERS_FULL_EVENT_DATA = t.$props.usersFullEventData

			return USERS_FULL_EVENT_DATA.filter(eu => eu.eventUserData.eventUserStatus === statusName)?.length
		},

		/**
		 * Update EventUser Status
		 *
		 * Update the EventUser's status for the selected User.
		 *
		 * @param userFullEventData - the full event data for the user
		 * @param newStatus - the new status to update to
		 * @returns {Promise<void>}
		 */
		async updateEventUserStatus(userFullEventData, newStatus) {
			const t = this
			const EVENT_USER_DATA = userFullEventData.eventUserData
			const EVENT_TEAM_DATA = userFullEventData.eventTeamData

			// Return and do nothing if trying to Confirm a user when the team quota has been reached
			if (newStatus === 'Confirmed' && t.getStatusNumber('Confirmed') >= EVENT_TEAM_DATA.eventTeamQuota) return

			// Set the new status
			EVENT_USER_DATA.eventUserStatus = newStatus

			// Update the EventUser document
			const RESPONSE = await t.MIX_redis_update('eventUser', EVENT_USER_DATA.entityId, EVENT_USER_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating EventUser: ', RESPONSE.error)
				return
			}
		}

	},

}
</script>

<style scoped>
.eventStatusSelection-statusFigures-column {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 16px;
}

.eventStatusSelection-statusFigures-column > div {
	border-right: 1px solid var(--v-greyD-base);
}

.eventStatusSelection-statusFigures-column > div:nth-child(3n+3) {
	border-right: none;
}

.eventStatusSelection-statusFigures-row {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}

.eventStatusSelection-statusFigures-row > div {
	border-right: 1px solid var(--v-greyD-base);
}

.eventStatusSelection-statusFigures-row > div:last-child {
	border-right: none;
}
</style>
