<template>
	<v-overlay opacity="0.4">

		<div class="white rounded-lg ma-4 pa-4" style="min-width: 320px; max-width: 512px">

			<!--Title | Icon-->
			<div class="d-flex align-center">
				<app-icon color="red" icon="cancel" size="48"/>
				<app-text class="ml-4" size="medium-bold">Oops!</app-text>
			</div>

			<!--Message-->
			<app-text class="text-center  mt-4">{{ getErrorMessage(errorMessage) }}</app-text>

			<v-divider class="greyD mt-4"/>

			<!--Close Button-->
			<app-btn @click.native="sharedState.errorMessage = ''"
					 :block="true"
					 class="mt-4"
					 label="Close"/>

		</div>

	</v-overlay>
</template>

<script>
import sharedState from "@/sharedState";
export default {

	name: "ErrorMessage",

	props: ['errorMessage'],

	data: () => ({
		sharedState,
	}),

	methods: {

		/**
		 * Get Error Message
		 *
		 * Return the correct message for the code.
		 *
		 * @param errorCode - the error code to provide a message for
		 * @returns {string} - the error message
		 */
		getErrorMessage(errorCode) {
			let errorMessage = {
				'auth/email-already-in-use': 'This email address is already in use.',
				'auth/email-already-exists': 'This email address is already in use.',
				'auth/missing-email': 'You must provide an email address.',
				'auth/invalid-email': 'This email address is invalid.',
				'auth/internal-error': 'Sorry, something unexpected has happened.',
				'auth/weak-password': 'Your password is too weak, it must be at least 6 characters.',
				'auth/user-not-found': 'No account was found for this email address.',
				'auth/wrong-password': 'Either the email address or password is incorrect.',
				'auth/invalid-password': 'Either the email address or password is incorrect.',
			}

			return errorMessage[errorCode] || errorCode
		}

	},

}
</script>

<style scoped>

</style>
