<template>
	<div>

		<!--Header-->
		<page-title :divider="true"
					icon="tasks"
					info="Create and administer SWAPP Tasks for your venue."
					title="SWAPP Tasks"/>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Back Button-->
			<app-btn @click.native="$emit('backToTableView')"
					 class="mr-4"
					 color="greyD"
					 icon="arrowBack"
					 icon-color="grey9"
					 label="Table View"
					 label-color="grey9"/>

			<!--Sites-->
			<div style="width: 100%">
				<app-form-field form-type="autoComplete"
								class="mr-4"
								:clearable="true"
								:items="sitesData"
								item-text="siteName"
								label="Sites"
								:return-object="true"
								v-model.trim="filterBySite"/>
			</div>

			<!--Area-->
			<div style="width: 100%">
				<app-form-field form-type="textInput"
								append-icon="icons8-search"
								class="mr-4"
								:clearable="true"
								label="Area"
								v-model.trim="searchByArea"/>
			</div>

			<!--Location-->
			<div style="width: 100%">
				<app-form-field form-type="textInput"
								append-icon="icons8-search"
								class="mr-4"
								:clearable="true"
								label="Location"
								v-model.trim="searchByLocation"/>
			</div>

		</div>

		<!--Cards -------------------------------------------------------------------------------------------------- -->

		<!--No Cards Message-->
		<div v-if="!computedCardData.length" class="d-flex flex-column align-center justify-center mt-8">
			<app-icon class="pulseBig" color="orange" icon="cancel" size="96"/>
			<app-text class="text-center mt-4" color="grey9">There are no SWAPP Tasks to show</app-text>
		</div>

		<!--Area Container-->
		<div v-for="item in computedCardData" class="rounded-lg mt-4 mr-4 pa-4" style="background-color: #ebebeb">

			<!--Area | Site-->
			<app-text color="grey9" size="medium-bold">{{ item[0].listData.swappTasksListArea }}</app-text>
			<app-text color="grey9" size="small">
				{{ sitesData.find(site => site.entityId === item[0].listData.swappTasksListSite)?.siteName }}
			</app-text>

			<v-divider class="greyD mt-2 mb-4"/>

			<!--Location Cards-->
			<div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)); grid-gap: 16px">
				<div v-for="taskList in item" class="appWhite rounded-lg pa-4">

					<!--Location-->
					<app-text color="primary" size="medium-bold">
						{{ taskList.listData.swappTasksListLocation }}
					</app-text>

					<!--List Name | Role-->
					<app-text class="mt-2" color="grey9" size="small">
						{{ taskList.listData.swappTasksListName }} ({{ taskList.listData.swappTasksListRole }})
					</app-text>

					<v-divider class="greyD my-2"/>

					<!--Icon | User Name-->
					<div class="d-flex align-center">

						<!--Icon-->
						<app-icon class="mb-1" color="greyD" icon="user" size="16"/>

						<!--User Name-->
						<app-text class="ml-2" size="small">{{ taskList.listData.createdUserName }}</app-text>

					</div>

					<!--Icon | Date-->
					<div class="d-flex align-center">

						<!--Icon-->
						<app-icon class="mb-1" color="greyD" icon="events" size="16"/>

						<!--User Name-->
						<app-text class="ml-2" size="small">
							{{ MIX_formatDate(taskList.listData.createdDateTime, 'short') }}
							@
							{{ MIX_formatDateTimeToTime(taskList.listData.createdDateTime) }}
						</app-text>

					</div>

					<v-divider class="greyD my-2"/>

					<!--Number of Tasks Completed-->
					<div class="d-flex align-center">
						<div class="d-flex align-center justify-end" style="width: 80px">
							<app-text
								:color="getTaskListFigures(taskList).numberOfTasksCompleted === getTaskListFigures(taskList).numberOfTasks ? 'green' : 'red'"
								size="normal-bold">
								{{ getTaskListFigures(taskList).numberOfTasksCompleted }}
							</app-text>
							<app-text class="mx-2" color="grey9">/</app-text>
							<app-text
								:color="getTaskListFigures(taskList).numberOfTasksCompleted === getTaskListFigures(taskList).numberOfTasks ? 'green' : 'red'"
								size="normal-bold">
								{{ getTaskListFigures(taskList).numberOfTasks }}
							</app-text>
						</div>
						<app-text class="ml-4" color="grey9">Complete</app-text>
					</div>

					<!--Number of Comments-->
					<div class="d-flex align-center">
						<div class="d-flex align-center justify-end" style="width: 80px">
							<app-text
								:color="getTaskListFigures(taskList).numberOfTasksWithUserComments === 0 ? 'green' : 'orange'"
								size="normal-bold">
								{{ getTaskListFigures(taskList).numberOfTasksWithUserComments }}
							</app-text>
						</div>
						<app-text class="ml-4" color="grey9">Comments</app-text>
					</div>

					<!--Number of Issues-->
					<div class="d-flex align-center">
						<div class="d-flex align-center justify-end" style="width: 80px">
							<app-text :color="getTaskListFigures(taskList).numberOfUserIssues === 0 ? 'green' : 'red'"
									  size="normal-bold">
								{{ getTaskListFigures(taskList).numberOfUserIssues }}
							</app-text>
						</div>
						<app-text class="ml-4" color="grey9">Issue</app-text>
					</div>

				</div>
			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "SwappTasksDashboard",

	data: () => ({
		filterBySite: '',
		isLoading: true,
		searchByArea: '',
		searchByLocation: '',

		// Data
		fullSwappTasksData: [],
		sitesData: [],
	}),

	computed: {

		/**
		 * Computed Card Data
		 *
		 * Get the fullSwappTasksData and return a new array grouped it by the List Area.
		 *
		 * @returns {[]} - Array of grouped data ( [[{listData: {}, tasksData: []}]] )
		 */
		computedCardData() {
			const t = this
			let fullSwappTasksData = t.fullSwappTasksData

			// Filter by Site
			if (t.filterBySite) fullSwappTasksData = fullSwappTasksData.filter(item => item.listData.swappTasksListSite === t.filterBySite.entityId)

			// Search by Area
			if (t.searchByArea) {
				const SEARCH_CRITERIA = t.searchByArea.toUpperCase()
				fullSwappTasksData = fullSwappTasksData.filter(item => item.listData.swappTasksListArea.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Search by Location
			if (t.searchByLocation) {
				const SEARCH_CRITERIA = t.searchByLocation.toUpperCase()
				fullSwappTasksData = fullSwappTasksData.filter(item => item.listData.swappTasksListLocation.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Using Map to group items by swappTasksListArea
			const GROUPED_TASK_LIST = new Map()

			// Loop over the fullSwappTasksData array
			fullSwappTasksData.forEach(item => {

				// Extract the swappTasksListArea value
				const LIST_AREA = item.listData.swappTasksListArea

				// If the area is not yet a key in the map, add it with an empty array
				if (!GROUPED_TASK_LIST.has(LIST_AREA)) GROUPED_TASK_LIST.set(LIST_AREA, [])

				// Group the task data under the correct list area
				GROUPED_TASK_LIST.get(LIST_AREA).push({listData: item.listData, tasksData: item.tasksData})
			})

			// Convert the map into an array of groupings
			return Array.from(GROUPED_TASK_LIST.values())
		},

	},

	methods: {

		/**
		 * Get Task List Figures
		 *
		 * Get the number of tasks completed, the number of tasks, the number of comments and the number of user issues.
		 *
		 * @param taskListData {object} - The full task list data {listData:{}, tasksData: []}
		 * @returns {{numberOfTasksCompleted: number, numberOfTasks: number, numberOfTasksWithUserComments: number, numberOfUserIssues: number}}
		 */
		getTaskListFigures(taskListData) {

			const NUMBER_OF_TASKS_COMPLETE = taskListData.tasksData.filter(task => task.swappTasksTaskStatus === 'Complete')?.length
			const NUMBER_OF_TASKS = taskListData.tasksData?.length
			const NUMBER_OF_TASKS_WITH_USER_COMMENTS = taskListData.tasksData.filter(task => task.swappTasksTaskUserComment)?.length
			const NUMBER_OF_USER_ISSUES = taskListData.tasksData.filter(task => task.swappTasksTaskUserIssue)?.length

			return {
				numberOfTasksCompleted: NUMBER_OF_TASKS_COMPLETE,
				numberOfTasks: NUMBER_OF_TASKS,
				numberOfTasksWithUserComments: NUMBER_OF_TASKS_WITH_USER_COMMENTS,
				numberOfUserIssues: NUMBER_OF_USER_ISSUES,
			}
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadFullSwappTasksData(),
				t.loadSitesData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Full Swapp Tasks Data
		 *
		 * Load the full data required for the page.
		 * [{ List Data: {}, Tasks Data: [] }]
		 *
		 * @returns {Promise<void>}
		 */
		async loadFullSwappTasksData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getFullWorkingSwappTasksData()

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Full SWAPP Tasks Data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Full SWAPP Tasks Data. Please try again later.'
				return
			}

			t.fullSwappTasksData = RESPONSE.data
		},

		/**
		 * Load Sites Data
		 *
		 * Load the sites data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSitesData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('site')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Sites Data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Sites Data. Please try again later.'
				return
			}

			t.sitesData = RESPONSE.data
		},

	},

	mounted() {
		const t = this

		t.loadData()

	},

}
</script>

<style scoped>

</style>
