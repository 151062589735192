<template>
	<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
				leave-active-class="animate__animated animate__fadeOutRight animate__faster"
				mode="out-in">

		<div v-if="isFiltersPanelVisible"
			 class="appGrey formShadow"
			 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
			 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 600px'"
			 v-model="isFiltersPanelVisible">

			<!--Header-->
			<div class="d-flex align-center justify-space-between primary pa-4">

				<!--Icon | Title-->
				<div class="d-flex align-center">
					<app-icon class="mr-4" color="appWhite" icon="filter" size="32"/>
					<app-text color="appWhite" size="medium">Filters</app-text>
				</div>

				<!--Close Button-->
				<app-icon @click.native="toggleFiltersVisibility" color="appWhite" icon="close" size="32"/>

			</div>

			<slot></slot>

		</div>
	</transition>
</template>

<script>
export default {

	name: 'FilterPanel',

	props: ['isFiltersPanelVisible'],

	methods: {

		toggleFiltersVisibility() {
			this.$emit('toggle')
		}

	}

}
</script>
