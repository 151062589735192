import {
	createUserWithEmailAndPassword,
	getAuth,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut
} from 'firebase/auth'

/**
 * Mixin for handling Firebase authentication related functionality
 */
const mixin = {

	methods: {

		/**
		 * Register User
		 *
		 * Register a new user with Firebase using an email and password.
		 *
		 * @param {string} email - Email of the new user
		 * @param {string} password - Password of the new user
		 * @returns {Object} - An object containing data, hasErrors and error properties
		 */
		MIX_auth_registerUser(email, password) {
			const AUTH = getAuth()

			return createUserWithEmailAndPassword(AUTH, email, password)
				.then(response => {
					return {
						data: response,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					this.$sharedState.errorMessage = error.code
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Sign In User
		 *
		 * Sign in an existing user to Firebase using an email and password.
		 *
		 * @param {string} email - Email of the user
		 * @param {string} password - Password of the user
		 * @returns {Object} - An object containing data, hasErrors and error properties
		 */
		MIX_auth_signInUser(email, password) {
			const AUTH = getAuth()

			return signInWithEmailAndPassword(AUTH, email, password)
				.then(response => {
					return {
						data: response.data,
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					this.$sharedState.errorMessage = error.code
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Sign Out User
		 *
		 * Sign out the currently signed-in user from Firebase.
		 *
		 * @returns {Object} - An object containing data, hasErrors and error properties
		 */
		MIX_auth_signOutUser() {
			const AUTH = getAuth()

			return signOut(AUTH)
				.then(() => {
					return {
						data: {},
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					this.$sharedState.errorMessage = error.code
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		},

		/**
		 * Reset Password
		 *
		 * Send a password reset email to the given email address.
		 *
		 * @param {string} email - Email of the user
		 * @returns {Object} - An object containing data, hasErrors and error properties
		 */
		MIX_auth_resetPassword(email) {
			const AUTH = getAuth()

			return sendPasswordResetEmail(AUTH, email)
				.then(() => {
					return {
						data: [],
						hasErrors: false,
						error: null
					}
				})
				.catch(error => {
					this.$sharedState.errorMessage = error.code
					return {
						data: null,
						hasErrors: true,
						error: error
					}
				})
		}

	}

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
