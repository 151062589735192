<template>
	<div class="d-flex justify-center" style="width: 100%">
		<div class="d-flex flex-column ma-4" style="min-width: 256px; width: 100%; max-width: 512px">

			<!--Logo-->
			<div class="d-flex justify-center">
				<v-img :src="require('@/assets/images/company-logo.svg')"
					   style="min-width: 256px; width: 100%; max-width: 256px"/>
			</div>

			<app-text class="text-center mt-8" color="grey9" size="medium" style="letter-spacing: 8px">
				Stadium SWAPP
			</app-text>

			<v-divider class="mt-8" style="width: 100%"/>

			<!--Form Title-->
			<app-text class="text-left mt-4" color="primary" size="medium">Register</app-text>

			<!--Registration Form-->
			<div v-if="currentView === 'Register'">

				<!--User Type-->
				<app-form-field form-type="select"
								class="mt-4"
								:error="errors.userType"
								:error-message="errors.userTypeErrorMessage"
								:items="['Staff', 'Steward']"
								label="User Type"
								v-model="form.userType"/>

				<!--Full Name-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:error="errors.userName"
								:error-message="errors.userNameErrorMessage"
								label="Full Name"
								placeholder="E.g... Joe Blogs"
								v-model.trim="form.userName"/>

				<!--Steward Number-->
				<app-form-field form-type="textInput"
								class="mt-4"
								label="Internal ID Number (optional)"
								v-model.trim="form.userInternalIdNumber"/>

				<!--Position-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:error="errors.userPosition"
								:error-message="errors.userPositionErrorMessage"
								label="Position"
								placeholder="E.g... Admin, Events Manager, etc"
								v-model.trim="form.userPosition"/>

				<!--Email-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:error="errors.userEmail"
								:error-message="errors.userEmailErrorMessage"
								label="Email"
								placeholder="E.g... joeblogs@email.com"
								type="email"
								v-model.trim="form.userEmail"/>

				<!--Telephone-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:error="errors.userTelephone"
								:error-message="errors.userTelephoneErrorMessage"
								label="Phone Number"
								placeholder="E.g... 07890123456"
								type="tel"
								v-model.trim="form.userTelephone"/>

				<!--Terms and Conditions-->
				<div class="d-flex align-center mt-4">
					<v-checkbox id="termsAndConditions"
								:error="errors.userAcceptedTermsAndConditions"
								v-model="form.userAcceptedTermsAndConditions"/>
					<label for="termsAndConditions">
						I have completed this form as accurately as possible, and accept the
						<span class="primary--text">Terms and Conditions</span>
					</label>
				</div>
				<app-text v-if="errors.userAcceptedTermsAndConditionsErrorMessage" color="'#FF5252'" size="small">
					{{ errors.userAcceptedTermsAndConditionsErrorMessage }}
				</app-text>

				<!--Action Buttons-->
				<div class="d-flex justify-space-between mt-4">

					<!--Back to Sign In-->
					<app-btn @click.native="MIX_go('/signIn')"
							 class="pr-2"
							 color="grey"
							 icon="arrowBack"/>

					<!--Next Button-->
					<app-btn @click.native="handleRegisterNextButton"
							 :block="true"
							 class="pl-2"
							 color="primary"
							 label="Next"
							 style="width: 100%"/>

				</div>

			</div>

			<!--Passwords Form-->
			<div v-if="currentView === 'Passwords'">

				<!--Password Criteria-->
				<v-expansion-panels flat>
					<v-expansion-panel class="transparent">

						<!--Header-->
						<v-expansion-panel-header class="px-0">
							<app-text>Passwords must meet the following criteria:</app-text>
						</v-expansion-panel-header>

						<!--Content-->
						<v-expansion-panel-content>
							<ul>
								<li>
									<app-text>Be at least 8 characters long</app-text>
								</li>
								<li>
									<app-text>Contain at least 1 upper case character</app-text>
								</li>
								<li>
									<app-text>Contain at least 1 lower case character</app-text>
								</li>
								<li>
									<app-text>Contain at least 1 number</app-text>
								</li>
								<li>
									<app-text>
										Contain at least 1 special character from
										<span class="font-weight-bold">? # @ ! £ $ % &</span>
									</app-text>
								</li>
							</ul>
						</v-expansion-panel-content>

					</v-expansion-panel>
				</v-expansion-panels>

				<!--Password-->
				<v-text-field @click:append="isPasswordVisible = !isPasswordVisible"
							  :append-icon="isPasswordVisible ? 'icons8-eye' : 'icons8-closed-eye'"
							  background-color="appWhite"
							  class="rounded-lg labelColor mt-4"
							  :error="errors.userPassword"
							  :error-messages="errors.userPasswordErrorMessage"
							  flat
							  hide-details="auto"
							  label="Password"
							  outlined
							  placeholder="Password"
							  :type="isPasswordVisible ? 'text' : 'password'"
							  v-model.trim="userPassword"/>

				<!--Confirm Password-->
				<v-text-field @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
							  :append-icon="isPasswordConfirmationVisible ? 'icons8-eye' : 'icons8-closed-eye'"
							  background-color="appWhite"
							  class="rounded-lg labelColor mt-4"
							  :error="errors.userPasswordConfirmation"
							  :error-messages="errors.userPasswordConfirmationErrorMessage"
							  flat
							  hide-details="auto"
							  label="Password"
							  outlined
							  placeholder="Password"
							  :type="isPasswordConfirmationVisible ? 'text' : 'password'"
							  v-model.trim="userPasswordConfirmation"/>

				<!--Action Buttons-->
				<div class="d-flex justify-space-between mt-4">

					<!--Back to Register-->
					<app-btn @click.native="currentView = 'Register'"
							 color="grey"
							 class="pr-2"
							 icon="arrowBack"
							 label="Register"/>

					<!--Next Button-->
					<app-btn @click.native="handleRegisterButton"
							 :block="true"
							 class="pl-2"
							 color="primary"
							 label="Next"
							 style="width: 100%"/>

				</div>

			</div>

		</div>
	</div>
</template>

<script>
export default {

	name: "Register",

	data: () => ({
		currentView: 'Register',
		errors: {
			userAcceptedTermsAndConditions: false,
			userAcceptedTermsAndConditionsErrorMessage: '',
			userEmail: false,
			userEmailErrorMessage: '',
			userName: false,
			userNameErrorMessage: '',
			userPosition: false,
			userPositionErrorMessage: '',
			userTelephone: false,
			userTelephoneErrorMessage: '',
			userType: false,
			userTypeErrorMessage: '',

			userPassword: false,
			userPasswordErrorMessage: '',
			userPasswordConfirmation: false,
			userPasswordConfirmationErrorMessage: '',
		},
		form: {
			userAcceptedTermsAndConditions: false,
			userAccessAndAbilities: [],
			userDefaultZones: '',
			userEmail: '',
			userFbId: '',
			userInternalIdNumber: '',
			userLevel: '',
			userName: '',
			userObservationResponsibilities: [],
			userOrganisation: '',
			userPayGrade: '',
			userPosition: '',
			userProfilePicture: '',
			userRole: '',
			userStatus: 'Pending',
			userLastSwappLocationId: '',
			userLastSwappSiteId: '',
			userLastSwappMethod: '',
			userLastSwappDateTime: 0,
			userSwappStatus: 'Out',
			userTeam: '',
			userTelephone: '',
			userType: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
		},
		isPasswordVisible: false,
		isPasswordConfirmationVisible: false,
		userPassword: '',
		userPasswordConfirmation: '',
	}),

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Create User Data Document
		 *
		 * If the Firebase Auth was successfully created, create the User Data in Redis.
		 *
		 * @param userFbId - the User's Firebase ID
		 * @returns {Promise<void>}
		 */
		async createUserDataDocument(userFbId) {
			const t = this

			// Set data from form selection
			if (t.form.userType === 'Staff') {
				t.form.userFbId = userFbId
				t.form.userLevel = 'Staff-User'
				t.form.userRole = 'User'
			}

			// Set data from form selection
			if (t.form.userType === 'Steward') {
				t.form.userFbId = userFbId
				t.form.userLevel = 'Steward-User'
				t.form.userRole = 'User'
			}

			const RESPONSE = await t.MIX_redis_create('user', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating User: ', RESPONSE.error)
				return
			}

			t.MIX_addToLocalStorage('currentUserData', RESPONSE.data)
		},

		/**
		 * Handle Register Button
		 *
		 * If the form has passed validation, call to register the User.
		 */
		async handleRegisterButton() {
			const t = this

			if (!t.validatePasswords()) return

			const RESPONSE = await t.MIX_auth_registerUser(t.form.userEmail, t.userPassword)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error registering: ', RESPONSE.error)
				return
			}

			// If the Auth was successfully created, configure and create the User Data account
			await t.createUserDataDocument(RESPONSE.data.user.uid)

			t.MIX_go('/')
		},

		/**
		 * Handle Register Next Button
		 *
		 * If the form has passed validation,
		 * format certain fields of the form for consistency,
		 * and switch to password view.
		 */
		handleRegisterNextButton() {
			const t = this

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			// Capitalise the first letter of each word in the strings for consistency
			t.form.userName = t.MIX_formatCapitaliseFirstLetters(t.form.userName)
			t.form.userPosition = t.MIX_formatCapitaliseFirstLetters(t.form.userPosition)
			if (t.form.userType === 'Steward') t.form.userInternalIdNumber = t.MIX_formatUserInternalIdNumber(t.form.userInternalIdNumber)

			// Switch view
			t.currentView = 'Passwords'
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// User Type
			if (!t.form.userType) {
				t.errors.userType = true
				t.errors.userTypeErrorMessage = 'User Type is required'
			}

			// Full Name
			if (!t.form.userName) {
				t.errors.userName = true
				t.errors.userNameErrorMessage = 'Full Name is required'
			}

			// Position
			if (!t.form.userPosition) {
				t.errors.userPosition = true
				t.errors.userPositionErrorMessage = 'Position is required'
			}

			// Email
			const USER_EMAIL = t.form.userEmail
			if (!USER_EMAIL) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email is required'
			}
			// Must be a (simple) valid email address
			else if (!t.MIX_isEmailValid(USER_EMAIL)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email is not valid'
			}

			// Telephone
			const USER_TELEPHONE_NUMBER = t.form.userTelephone
			if (!USER_TELEPHONE_NUMBER) {
				t.errors.userTelephone = true
				t.errors.userTelephoneErrorMessage = 'Telephone Number is required'
			} else {
				const VALIDATED_NUMBER = t.MIX_isTelephoneNumberValid(USER_TELEPHONE_NUMBER)
				if (!VALIDATED_NUMBER) {
					t.errors.userTelephone = true
					t.errors.userTelephoneErrorMessage = 'Invalid Telephone Number'
				} else {
					t.form.userTelephone = VALIDATED_NUMBER
				}
			}

			// Terms and Conditions
			if (!t.form.userAcceptedTermsAndConditions) {
				t.errors.userAcceptedTermsAndConditions = true
				t.errors.userAcceptedTermsAndConditionsErrorMessage = 'You must acknowledge and accept'
			}

			return !Object.values(t.errors).includes(true)
		},

		/**
		 * Validate Passwords
		 *
		 * Validate the password fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validatePasswords() {
			const t = this

			t.clearErrors()

			// Password
			const USER_PASSWORD = t.userPassword
			if (!USER_PASSWORD) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password is required'
			} else if (!t.MIX_isPasswordValid(USER_PASSWORD)) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Password not in the expected format'
			}

			// Password Confirmation
			const USER_PASSWORD_CONFIRMATION = t.userPasswordConfirmation
			if (!USER_PASSWORD_CONFIRMATION) {
				t.errors.userPasswordConfirmation = true
				t.errors.userPasswordConfirmationErrorMessage = 'Password Confirmation is required'
			} else if (!t.MIX_isPasswordValid(USER_PASSWORD_CONFIRMATION)) {
				t.errors.userPasswordConfirmation = true
				t.errors.userPasswordConfirmationErrorMessage = 'Password Confirmation not in the expected format'
			}

			// Do Passwords Match
			if ((USER_PASSWORD && t.MIX_isPasswordValid(USER_PASSWORD)) &&
				(USER_PASSWORD_CONFIRMATION && t.MIX_isPasswordValid(USER_PASSWORD_CONFIRMATION)) &&
				USER_PASSWORD !== USER_PASSWORD_CONFIRMATION) {
				t.errors.userPassword = true
				t.errors.userPasswordErrorMessage = 'Passwords don\'t match'
				t.errors.userPasswordConfirmation = true
				t.errors.userPasswordConfirmationErrorMessage = 'Passwords don\'t match'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: transparent;
}

.labelColor >>> .v-label {
	color: var(--v-primary-base) !important;
	opacity: 0.5;
}
</style>
